import { connect } from 'react-redux';
import SuperAdminCompany from '../../components/SuperAdmin/Company/Companies';
import {
  getAllCompanies,
  updateCompany,
  resetRequestSuccess,
  resetPage,
  addCompany,
  updateCompanyMembers,
} from '../../actions/SuperAdminCompanyActions';

const mapStateToProps = (state) => ({
  companyList: state.superAdminCompanies.companyList,
  currentPage: state.superAdminCompanies.currentPage,
  totalPage: state.superAdminCompanies.totalPage,
  total: state.superAdminCompanies.total,
  requestSuccess: state.superAdminCompanies.requestSuccess,
  validationError: state.superAdminCompanies.validationError,
  companyMembers: state.superAdminCompanies.companyMembers,
  companyMembersCount: state.superAdminCompanies.companyMembersCount,
  allUser: state.superAdminCompanies.allUser,
  companyListLoader: state.superAdminCompanies.companyListLoader,
  modalLoader: state.superAdminCompanies.modalLoader,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCompanies: (value) => dispatch(getAllCompanies(value)),
  addCompany: (value) => dispatch(addCompany(value)),
  updateCompany: (value) => dispatch(updateCompany(value)),
  updateCompanyMembers: (value) => dispatch(updateCompanyMembers(value)),
  resetRequestSuccess: () => dispatch(resetRequestSuccess()),
  resetPage: () => dispatch(resetPage()),
});

const UserTeamMembersCompanyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperAdminCompany);

export default UserTeamMembersCompanyContainer;
