import React, { useState, useEffect } from 'react';
import Pagination from 'rc-pagination';
import Spinner from 'react-bootstrap/Spinner';

import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import MemberList from './MemberList';
import TeamMemberModal from './TeamMemberModal';
import nodataImage from '../../assets/img/no_data_full.svg';
/* eslint-disable */
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
/* eslint-enable */

const TeamMembers = ({
  history,
  memberlist,
  roles,
  productList,
  allTeamMember,
  addTeamMember,
  requestSuccess,
  total,
  updateTeamMember,
  resetRequestSuccess,
  deleteTeamMember,
  resetPage,
  currentPage,
  loading,
  accountType,
  activeProfile,
  teamMemberListLoader,
  modalLoader,
  resetAccessRemoved,
  accessRemoved,
  resetCompanyNotFound,
  companyNotFound,
  rolePermissionInfo,
}) => {
  const [show, setShow] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [teamMemberData, setTeamMemberData] = useState([]);

  const [email, setEmail] = useState('');
  const [empId, setEmpId] = useState('');
  const [role, setRole] = useState({ label: 'select', value: '' });
  const [products, setProducts] = useState([{ label: '', value: '' }]);

  const [status, setStatus] = useState({ label: 'select', value: '' });

  const [editTeamMember, setEditTeamMember] = useState(false);
  const [search, setSearch] = useState(null);

  const [confirmBox, setConfirmBox] = useState(false);
  const [confirmBoxTitle, setConfirmBoxTitle] = useState('');
  const handelConfirmBoxClose = () => setConfirmBox(false);

  const [userRole, setUserRole] = useState('');
  const [userPermission, setUserPermission] = useState([]);

  const [err, setErr] = useState({
    email: '',
    role: '',
    status: '',
    products: '',
  });
  /* eslint-disable */

  const checkCompany = () => {
    return Object.keys(activeProfile).length > 0;
  };

  useEffect(() => {
    requestSuccess && setShow(false);
    requestSuccess && setConfirmBox(false);
    if (accountType !== 'Partner') {
      requestSuccess &&
        allTeamMember({
          page: currentPage,
          per_page: perPage,
          account_type: accountType,
          company_id: activeProfile.id,
        });
    } else {
      requestSuccess &&
        allTeamMember({
          page: currentPage,
          per_page: perPage,
          account_type: accountType,
          company_id: activeProfile.id,
        });
    }
    requestSuccess && resetRequestSuccess();
  }, [requestSuccess]);

  useEffect(() => {
    !show && setEditTeamMember(false);
    !show && resetRequestSuccess();
    !show && setEmail('');
    !show && setEmpId('');
    !show && setRole({ label: 'select', value: '' });
    !show && setProducts([{ label: '', value: '' }]);
    !show && setStatus({ label: 'select', value: '' });
    !show &&
      setErr({
        email: '',
        role: '',
        status: '',
        products: '',
      });
  }, [show]);

  useEffect(() => {
    !confirmBox && setEmpId('');
  }, [confirmBox]);

  useEffect(() => {
    if (memberlist.length > 0) {
      var dataArray = memberlist.map((Member) => {
        var newArray = {};
        newArray.name = Member.name;
        newArray.email = Member.email;
        newArray.emp_status = Member.emp_status;
        newArray.role = Member.role_id;
        newArray.status = Member.user.status;
        newArray.is_verified = Member.user.is_verified;
        newArray.image = Member.user.image ? Member.user.image : undefined;
        newArray.id = Member.id;
        newArray.user_id = Member.user_id;
        newArray.companyOwner = Member.company_owner
          ? Member.company_owner
          : false;
        return newArray;
      });
      setTeamMemberData(dataArray);
    } else {
      setTeamMemberData([]);
    }
  }, [memberlist]);

  useEffect(() => {
    const check = checkCompany();
    if (!check) {
      toast.error('Company not found, Please provide a Company first');
      return;
    }
    if (accountType !== 'Partner') {
      allTeamMember({
        per_page: perPage,
        account_type: accountType,
        company_id: activeProfile.id,
      });
    } else {
      allTeamMember({
        per_page: perPage,
        account_type: accountType,
        company_id: activeProfile.id,
      });
    }
  }, [perPage]);

  useEffect(() => {
    if (accessRemoved) {
      resetAccessRemoved();
      history.push('/user/dashboard');
    }
  }, [accessRemoved]);

  useEffect(() => {
    if (companyNotFound) {
      resetCompanyNotFound();
      history.push('/auth/create-company-form');
    }
  }, [companyNotFound]);

  useEffect(() => {
    if (rolePermissionInfo && rolePermissionInfo.length > 0) {
      setUserRole(rolePermissionInfo[0].name);
      let permissions = rolePermissionInfo[0].permissions;
      if (permissions && permissions.length > 0) {
        let role_permission = rolePermissionInfo[0].permissions[0].permission;
        let newArray = [];
        role_permission.map((p) => {
          if (p[0] === 'Team Member') {
            p[1].map((r) => {
              newArray.push(r);
            });
          }
        });
        setUserPermission(newArray);
      }
    }
  }, []);

  /* eslint-enable */

  const handleEditModal = (id) => {
    setEditTeamMember(true);
    setShow(true);
    setErr({
      email: '',
      role: '',
      status: '',
      products: '',
    });
    memberlist
      .filter((data) => data.id === id)
      .forEach((p) => {
        setEmail(p.email);
        setRole({ label: 'select', value: p.role_id });
        if (p.products && p.products.length > 0) {
          const product = p.products.map((f) => {
            const newArray = {};
            newArray.label = f;
            newArray.value = f;

            return newArray;
          });
          setProducts(product);
        } else {
          setProducts([{ label: '', value: '' }]);
        }
        setStatus({ label: 'select', value: p.emp_status });
        setEmpId(id);
      });
  };

  const validate = () => {
    const errors = [];
    // role check
    if (userRole === 'Demo_User') {
      toast.warn('This operation not possible in demo account');
      errors.push('This operation not possible in demo account');
    }

    let emailError = '';
    let statusError = '';
    let productsError = '';
    let roleError = '';
    if (email.length === 0) {
      emailError = "Email can't be empty";
      errors.push("Email can't be empty");
    }

    if (email.length > 0 && !validEmailRegex.test(email)) {
      errors.push('Email is not valid');
      emailError = 'Email is not valid';
    }

    if (status && status.value === '') {
      errors.push('Please select a Status');
      statusError = 'Please select a Status';
    }

    if (!status) {
      errors.push('Please select a Status');
      statusError = 'Please select a Status';
    }

    if (products && products.length === 1 && products[0].value === '') {
      errors.push('Please select a Product');
      productsError = 'Please select a Product';
    }

    if (!products) {
      errors.push('Please select a Product');
      productsError = 'Please select a Product';
    }

    if (role && role.value === '') {
      errors.push('Please select a Role');
      roleError = 'Please select a Role';
    }

    if (!role) {
      errors.push('Please select a Role');
      roleError = 'Please select a Role';
    }
    if (errors.length > 0) {
      setErr({
        email: emailError,
        role: roleError,
        status: statusError,
        products: productsError,
      });
    }
    return errors;
  };

  const submitTeamMember = () => {
    const errors = validate();
    if (errors.length > 0) {
    } else if (editTeamMember) {
      if (accountType !== 'Partner') {
        updateTeamMember({
          email,
          status: status.value,
          role_id: role.value,
          products: products.map((p) => p.value),
          teammemberId: empId,
          account_type: accountType,
          company_id: activeProfile.id,
        });
      } else {
        updateTeamMember({
          email,
          status: status.value,
          role_id: role.value,
          products: products.map((p) => p.value),
          teammemberId: empId,
          account_type: accountType,
          company_id: activeProfile.id,
        });
      }
    } else if (accountType !== 'Partner') {
      addTeamMember({
        email,
        status: status.value,
        role_id: role.value,
        products: products.map((p) => p.value),
        account_type: accountType,
        company_id: activeProfile.id,
      });
    } else {
      addTeamMember({
        email,
        status: status.value,
        role_id: role.value,
        products: products.map((p) => p.value),
        account_type: accountType,
        company_id: activeProfile.id,
      });
    }
  };

  const handelPageChange = (e) => {
    if (accountType !== 'Partner') {
      allTeamMember({
        page: e,
        per_page: perPage,
        account_type: accountType,
        company_id: activeProfile.id,
        search: search && search.length > 0 ? search : null,
      });
    } else {
      allTeamMember({
        page: e,
        per_page: perPage,
        search: search && search.length > 0 ? search : null,
        account_type: accountType,
        company_id: activeProfile.id,
      });
    }
  };

  const handelSearch = (searchValue) => {
    setSearch(searchValue);
    if (searchValue === '') {
      const check = checkCompany();
      if (!check) {
        return;
      }
      if (accountType !== 'Partner') {
        allTeamMember({
          per_page: perPage,
          account_type: accountType,
          company_id: activeProfile.id,
        });
      } else {
        allTeamMember({
          per_page: perPage,
          account_type: accountType,
          company_id: activeProfile.id,
        });
      }
    }
  };

  const handelSearchEnter = (e) => {
    if (e.key === 'Enter') {
      const check = checkCompany();
      if (!check) {
        return;
      }
      if (accountType !== 'Partner') {
        allTeamMember({
          per_page: perPage,
          account_type: accountType,
          company_id: activeProfile.id,
          search: e.target.value,
        });
      } else {
        allTeamMember({
          per_page: perPage,
          search: e.target.value,
          account_type: accountType,
          company_id: activeProfile.id,
        });
      }
    }
  };

  const handelDeleteTeamMember = (id) => {
    setConfirmBox(true);
    setConfirmBoxTitle('Are you sure to remove this member ?');
    setEmpId(id);
  };

  const handelTeamMemberDelete = (e) => {
    // role check
    if (userRole === 'Demo_User') {
      toast.warn('This operation not possible in demo account');
      return;
    }
    if (accountType !== 'Partner') {
      deleteTeamMember({
        id: empId,
        account_type: accountType,
        company_id: activeProfile.id,
      });
    } else {
      deleteTeamMember({
        id: empId,
        account_type: accountType,
        company_id: activeProfile.id,
      });
    }
  };

  const handelProductsSelect = (e) => {
    let new_products;
    if (e && e.length > 0) {
      const is_all_added = e.some((item) => item.value === 'all');
      const is_all_previous = products.some((item) => item.value === 'all');

      if (is_all_previous) {
        new_products = !is_all_added
          ? e
          : e.filter((item) => item.value !== 'all');
      } else {
        new_products = !is_all_added
          ? e
          : e.filter((item) => item.value === 'all');
      }
    } else {
      new_products = [{ value: '', label: '' }];
    }
    setProducts(new_products);
  };

  const handelCreateTeammemberModal = (e) => {
    e.preventDefault();
    if (Object.keys(activeProfile).length <= 0) {
      toast.error('Company not found, Please provide a Company first');
      return;
    }
    handleShow();
  };

  return (
    <>
      <section className="DataViewSection SectionGap">
        <div className="DataViewHeading">
          <div className="DataViewHeadingLeftContent">
            <div className="SectionTitle">Team Members</div>
          </div>
          <div className="DataViewHeadingRightContent">
            <div className="SearchForm">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={(e) => handelSearch(e.target.value)}
                onKeyPress={(e) => handelSearchEnter(e)}
              />
            </div>
            {accountType === 'Partner' ? (
              <button
                className="ml-3 Button PrimaryButton"
                data-toggle="modal"
                data-target="#AddMemberModal"
                onClick={(e) => handelCreateTeammemberModal(e)}
              >
                <span>Add Member</span>
              </button>
            ) : (
              userPermission
                .filter((p) => p.name === 'add-team_member' && p.checked)
                .map((b) => (
                  <button
                    className="ml-3 Button PrimaryButton"
                    data-toggle="modal"
                    data-target="#AddMemberModal"
                    onClick={(e) => handelCreateTeammemberModal(e)}
                  >
                    <span>Add Member</span>
                  </button>
                ))
            )}
          </div>
        </div>
        <div className="TabContent">
          {teamMemberListLoader ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '400px',
              }}
            >
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <div className="DataViewTable">
              <div className="TableWrapper">
                <table className="DataTable">
                  <thead>
                    <tr>
                      <th />
                      <th>Name</th>
                      <th>User Email</th>
                      <th>Role & Status</th>
                      <th>Account Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!teamMemberListLoader &&
                    teamMemberData &&
                    teamMemberData.length > 0 ? (
                      teamMemberData.map((p, index) => (
                        <MemberList
                          userPermission={userPermission}
                          handleEditModal={handleEditModal}
                          key={index}
                          memberData={p}
                          roles={roles}
                          handelDeleteTeamMember={handelDeleteTeamMember}
                          activeProfile={activeProfile}
                          accountType={accountType}
                        />
                      ))
                    ) : (
                      <tr
                        style={{ textAlign: 'center' }}
                        className="Deactivated"
                      >
                        <td colSpan="6">
                          <img
                            src={nodataImage}
                            style={{ height: '600px' }}
                            alt=""
                          />
                        </td>
                      </tr>
                    )}
                    {/* {!teamMemberListLoader &&
                      teamMemberData &&
                      teamMemberData.length === 0 && (
                        <>
                          <tr
                            style={{ textAlign: 'center' }}
                            className="Deactivated"
                          >
                            <td colspan="6">
                              <img
                                src={nodataImage}
                                style={{ height: '600px' }}
                                alt=""
                              ></img>
                            </td>
                          </tr>
                        </>
                      )} */}
                  </tbody>
                </table>
              </div>

              <div className="TableFooter">
                <div className="TableFooterContent">
                  {total > 10 && (
                    <>
                      <form action="">
                        <div className="d-flex align-items-center">
                          <label htmlFor="ChooseNumber">Show</label>
                          <div className="CustomSelectNumber">
                            <select
                              onChange={(e) => setPerPage(e.target.value)}
                              className="custom-select"
                              id="ChooseNumber"
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </div>
                          <label htmlFor="ChooseNumber">Entires</label>
                        </div>
                      </form>
                      <Pagination
                        className="PaginationList ml-auto"
                        total={total}
                        current={currentPage}
                        onChange={(e) => handelPageChange(e)}
                        showTitle={false}
                        pageSize={perPage}
                        showPrevNextJumpers={false}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          size="xl"
          centered
          backdrop="static"
        >
          <Modal.Header bsPrefix="ModalHeader">
            <Modal.Title bsPrefix="modal-title">
              Add new team member
            </Modal.Title>
            <button
              type="button"
              className="close ml-auto"
              data-dismiss="modal"
              onClick={handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="insight wpi-close" />
              </span>
            </button>
          </Modal.Header>
          <Modal.Body bsPrefix="ModalBody">
            <TeamMemberModal
              email={email}
              setEmail={setEmail}
              role={role}
              setRole={setRole}
              products={products}
              setProducts={setProducts}
              status={status}
              setStatus={setStatus}
              roles={roles}
              productList={productList}
              submitTeamMember={submitTeamMember}
              handleEditModal={handleEditModal}
              handelProductsSelect={handelProductsSelect}
              err={err}
              editTeamMember={editTeamMember}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={confirmBox}
          onHide={handelConfirmBoxClose}
          size="lg"
          centered
          backdrop="static"
        >
          <Modal.Header bsPrefix="ModalHeader">
            <Modal.Title bsPrefix="modal-title">
              Delete Confirmation
            </Modal.Title>
            <button
              type="button"
              className="close ml-auto"
              data-dismiss="modal"
              onClick={handelConfirmBoxClose}
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="insight wpi-close" />
              </span>
            </button>
          </Modal.Header>
          <Modal.Body bsPrefix="ModalBody">
            <>
              <h4
                style={{
                  textAlign: 'center',
                  marginBottom: '20px',
                }}
              >
                {confirmBoxTitle}
              </h4>

              <p
                className="ActionButtonGroup"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 'auto',
                  alignItems: 'center',
                }}
              >
                <button
                  onClick={(e) => handelTeamMemberDelete()}
                  className="Button SuccessButton"
                  title="Yes"
                >
                  <span>
                    {modalLoader ? (
                      <Spinner animation="border" variant="light" />
                    ) : (
                      'Yes'
                    )}
                  </span>
                </button>
                <button
                  className="Button DangerButton"
                  title="No"
                  disabled={modalLoader}
                  onClick={(e) => {
                    !modalLoader && setConfirmBox(false);
                    !modalLoader && setConfirmBoxTitle('');
                    !modalLoader && setEmpId('');
                  }}
                >
                  No
                </button>
              </p>
            </>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default TeamMembers;
