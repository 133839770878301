import React from 'react';

const Profile = (props) => (
  <>
    <section className="FormViewSection">
      <div className="d-flex mb-5">
        <button
          onClick={props.handleShow}
          className="Button PrimaryButton ml-auto"
        >
          <div className="Icon">
            <i className="insight wpi-edit" />
          </div>
          <span>Update Information</span>
        </button>
      </div>
      <div className="FormViewDataWrapper">
        <div className="FormViewDataGroupLeft">
          <div className="FormViewGroup">
            <p className="FormViewTitle">Full Name :</p>
            <p className="FormViewData">{props.name}</p>
          </div>
          <div className="FormViewGroup">
            <p className="FormViewTitle">User Name :</p>
            <p className="FormViewData">{props.userName}</p>
          </div>
          <div className="FormViewGroup">
            <p className="FormViewTitle">Email Address :</p>
            <p className="FormViewData">{props.email}</p>
          </div>
        </div>
        <div className="UpdateOption">
          <div className="ImageSelector">
            <div className="ImgBox">
              <img
                src={props.profile_image}
                alt="profile"
                style={{ height: '100%' }}
                className="w-100"
              />
              {props.profile_image_delete && (
                <button
                  className="RemoveButton"
                  onClick={(e) => props.handelImageDelete()}
                >
                  <i className="iprops.nsight wpi-close" />
                </button>
              )}
            </div>
            {/* <label className="FileBrowseButton mt-2">
                <span className="text-break">Change Profile Picture</span>
                <input type="file" accept=".png, .jpg, .jpeg" />
              </label> */}
          </div>
        </div>
      </div>
    </section>
  </>
);

export default Profile;
