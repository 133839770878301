import React from 'react';
import Chart from 'react-apexcharts';
import Spinner from 'react-bootstrap/Spinner';

import { colorVariation } from '../../utils/helper';

let pluginVerionChartColor = [];
let phpVersionChartColor = [];
let laguagesChartColor = [];
let wpVersionChartColor = [];
let statusChartColor = [];
let deactivatedReasonChartColor = [];

let pluginVerionName = [];
let pluginVersionCount = [];
let phpVersionName = [];
let phpVersionCount = [];
let laguagesName = [];
let laguagesCount = [];
let wpVersionName = [];
let wpVersionCount = [];
let statusName = [];
let statusCount = [];

let deactivatedReasonName = [];
let deactivatedReasonCount = [];

let analyticsDates = [];
let analyticsCounts = [];
const opacity = 1.0;
// const innerHeight = window.innerHeight - 200;
const innerHeight = 600;
let mailChimpPluginCount = [];
let mailChimpPluginSuccessDataCount = [];
let mailChimpPluginUnSuccessDataCount = [];

const ChartOptions = {
  dataLabels: {
    enabled: false,
  },
  responsive: [
    {
      breakpoint: 1600,
      options: {
        chart: {
          width: '100%',
        },
        legend: {
          show: true,
        },
      },
    },
    {
      breakpoint: 650,
      options: {
        chart: {
          width: '100%',
        },
        legend: {
          show: false,
        },
      },
    },
    {
      breakpoint: 500,
      options: {
        chart: {
          width: '100%',
        },
        legend: {
          show: false,
        },
      },
    },
    {
      breakpoint: 480,
      options: {
        chart: {
          width: '100%',
        },
        legend: {
          show: false,
        },
      },
    },
  ],
  legend: {
    position: 'right',
    horizontalAlign: 'left',
    formatter(seriesName, opts) {
      let total = 0;
      for (const x of opts.w.globals.series) {
        total += x;
      }
      const selected = opts.w.globals.series[opts.seriesIndex];
      return `${seriesName} : ${selected} ( ${(
        (selected / total) *
        100
      ).toFixed(2)} % )`;
      // return [seriesName, ' - ', opts.w.globals.series[opts.seriesIndex]];
    },
  },
  tooltip: {
    enabled: true,
    custom({ series, seriesIndex, dataPointIndex, w }) {
      let total = 0;
      for (const x of series) {
        total += x;
      }
      const selected = series[seriesIndex];
      return `${w.config.labels[seriesIndex]} : ${selected} ( ${(
        (selected / total) *
        100
      ).toFixed(2)} % )`;
    },
  },
};

const ProductReport = (props) => {
  mailChimpPluginCount = props.mailchimpAnalytics.map((data) => data.plugin);

  mailChimpPluginSuccessDataCount = props.mailchimpAnalytics.map(
    (data) => data.subcribed
  );
  mailChimpPluginUnSuccessDataCount = props.mailchimpAnalytics.map(
    (data) => data.unsubscribed
  );
  const barChartDataSeries = [
    {
      name: 'Success',
      data: mailChimpPluginSuccessDataCount,
    },
    {
      name: 'Failed',
      data: mailChimpPluginUnSuccessDataCount,
    },
  ];

  const barChartDataOptions = {
    chart: {
      type: 'bar',
    },
    colors: ['#0000FF', '#dc3545'],
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },

        barHeight: '50%',
      },
    },
    dataLabels: {
      enabled: false,
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff'],
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff'],
    },
    xaxis: {
      categories: mailChimpPluginCount,
    },
    yaxis: {
      categories: mailChimpPluginCount,
      labels: {
        formatter(val, opt) {
          const name = val;
          const success = opt && opt.w.globals.initialSeries[0].name;
          const successData =
            opt && opt.w.globals.initialSeries[0].data[opt.dataPointIndex];
          const unSuccess = opt && opt.w.globals.initialSeries[1].name;
          const unSuccessData =
            opt && opt.w.globals.initialSeries[1].data[opt.dataPointIndex];
          return opt
            ? [
                name,
                `${success}:${successData}`,
                `${unSuccess}:${unSuccessData}`,
              ]
            : undefined;
        },
      },
    },
    tooltip: {
      x: {
        show: true,
        formatter(value, { series, seriesIndex, dataPointIndex, w }) {
          return value;
        },
        title: {
          formatter(val, opt) {
            const name = val;
            return name;
          },
        },
      },
      y: {
        show: true,
        formatter(value, { series, seriesIndex, dataPointIndex, w }) {
          return value;
        },
      },
    },
  };

  analyticsCounts = props.analytics.map((data) => data.count);

  analyticsDates = props.analytics.map((v) => v.date);

  const AreaChartSeries = [
    {
      name: 'Update log',
      data: analyticsCounts,
    },
  ];

  const AreaChartOption = {
    chart: {
      height: 350,
      type: 'area',
      id: 'Update log',
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      width: 3,
      categories: analyticsDates,
      labels: {
        rotate: -45,
        rotateAlways: true,
      },
    },
    tooltip: {
      x: {
        format: 'yyyy-mm-dd',
      },
    },
  };

  pluginVersionCount = props.userPerPuginVersion.map((data) => data.user);

  pluginVerionName = props.userPerPuginVersion.map((v) => v.version);
  let pluginVerionColorOpacity = opacity;
  const pluginVerionColorOpacityChange =
    pluginVersionCount && pluginVersionCount.length > 0
      ? 1 / (pluginVersionCount.length + 4)
      : 0;
  pluginVerionChartColor = pluginVerionName.map((v) => {
    const changedColor = colorVariation(255, 125, 0, pluginVerionColorOpacity);
    pluginVerionColorOpacity = Math.max(
      0.0,
      pluginVerionColorOpacity - pluginVerionColorOpacityChange
    );
    return changedColor;
  });

  /* plugin version chart */

  const pluginVersionChartSeries = pluginVersionCount;
  const pluginVersionChartOptions = {
    chart: {
      type: 'donut',
    },
    labels: pluginVerionName,
    colors: pluginVerionChartColor,
    ...ChartOptions,
  };

  phpVersionCount = props.userPerPhpVersion.map((d) => d.user);
  phpVersionName = props.userPerPhpVersion.map((d) => {
    const version = d.version.split('-');
    return version[0];
  });
  let phpVersionColorOpacity = opacity;
  const phpVersionColorOpacityChange =
    phpVersionCount && phpVersionCount.length > 0
      ? 1 / (phpVersionCount.length + 4)
      : 0;

  phpVersionChartColor = phpVersionName.map((v) => {
    const changedColor = colorVariation(98, 60, 234, phpVersionColorOpacity);
    phpVersionColorOpacity = Math.max(
      0.0,
      phpVersionColorOpacity - phpVersionColorOpacityChange
    );
    return changedColor;
  });

  const phpVersionChartOptions = {
    chart: {
      type: 'pie',
    },
    labels: phpVersionName,
    colors: phpVersionChartColor,
    ...ChartOptions,
  };

  laguagesCount = props.userPerLaguages.map((d) => d.user);
  laguagesName = props.userPerLaguages.map((d) => d.version);
  let laguagesColorOpacity = opacity;
  const laguagesColorOpacityChange =
    laguagesCount && laguagesCount.length > 0
      ? 1 / (laguagesCount.length + 4)
      : 0;
  laguagesChartColor = laguagesName.map((l) => {
    const changedColor = colorVariation(0, 208, 255, laguagesColorOpacity);
    laguagesColorOpacity = Math.max(
      0.0,
      laguagesColorOpacity - laguagesColorOpacityChange
    );
    return changedColor;
  });

  const languageChartOptions = {
    chart: {
      type: 'pie',
    },
    labels: laguagesName,
    colors: laguagesChartColor,
    ...ChartOptions,
  };

  wpVersionCount = props.userPerWordpressVersion.map((d) => d.user);
  wpVersionName = props.userPerWordpressVersion.map((d) => d.version);
  let wpVersionColorOpacity = opacity;
  const wpVersionColorOpacityChange =
    wpVersionCount && wpVersionCount.length > 0
      ? 1 / (wpVersionCount.length + 4)
      : 0;
  wpVersionChartColor = wpVersionName.map((v) => {
    const changedColor = colorVariation(229, 20, 46, wpVersionColorOpacity);
    wpVersionColorOpacity = Math.max(
      0.0,
      wpVersionColorOpacity - wpVersionColorOpacityChange
    );

    return changedColor;
  });

  const wordpressVersionChartOptios = {
    chart: {
      type: 'donut',
    },
    labels: wpVersionName,
    colors: wpVersionChartColor,
    ...ChartOptions,
  };

  statusCount = props.userPerStatus.map((d) => d.user);
  statusName = props.userPerStatus.map((d) => d.version);
  let statusColorOpacity = opacity;
  const statusColorOpacityChange =
    statusCount && statusCount.length > 0 ? 1 / (statusCount.length + 4) : 0;
  statusChartColor = statusName.map((v) => {
    const changedColor = colorVariation(67, 188, 156, statusColorOpacity);
    statusColorOpacity = Math.max(
      0.0,
      statusColorOpacity - statusColorOpacityChange
    );
    return changedColor;
  });
  const statusChartOptions = {
    chart: {
      type: 'donut',
    },
    labels: statusName,
    colors: statusChartColor,
    ...ChartOptions,
  };

  deactivatedReasonCount = props.userDeactivedReasons.map((d) => d.user);
  deactivatedReasonName = props.userDeactivedReasons.map((d) => d.version);
  let deactivatedReasonColorOpacity = opacity;
  const deactivatedReasonOpacityChange =
    deactivatedReasonCount && deactivatedReasonCount.length > 0
      ? 1 / (deactivatedReasonCount.length + 4)
      : 0;
  deactivatedReasonChartColor = deactivatedReasonName.map((v) => {
    const changedColor = colorVariation(
      215,
      131,
      235,
      deactivatedReasonColorOpacity
    );
    deactivatedReasonColorOpacity = Math.max(
      0.0,
      deactivatedReasonColorOpacity - deactivatedReasonOpacityChange
    );

    return changedColor;
  });

  const deactivatedResonsChartOptions = {
    chart: {
      type: 'pie',
    },
    labels: deactivatedReasonName,
    colors: deactivatedReasonChartColor,
    ...ChartOptions,
  };

  return (
    <>
      {/* counter info */}
      <section className="CounterSection SectionGap">
        <div className="row row-cols-md-4 HasColGap">
          <div className="col">
            <div className="CounterCard">
              <div className="CounterCardContent">
                <div className="CounterCardIcon PrimaryCount">
                  <i className="insight wpi-user" />
                </div>
                <div className="CounterCardText">
                  {props.reportType.length === 0 && props.reportLoader ? (
                    <div
                      className=""
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Spinner animation="border" variant="dark" />
                    </div>
                  ) : (
                    <h4>{props.totalUserCount}</h4>
                  )}
                  <p>Total User</p>
                </div>
              </div>
              <div className="WpiTooltip">
                <button className="WpiTooltipButton">
                  <i className="insight wpi-info" />
                </button>
                <div className="WpiTooltipText">
                  Data updates in every 30 min
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="CounterCard">
              <div className="CounterCardContent">
                <div className="CounterCardIcon SuccessCount">
                  <i className="insight wpi-user" />
                </div>
                <div className="CounterCardText">
                  {props.reportType.length === 0 && props.reportLoader ? (
                    <div
                      className=""
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Spinner animation="border" variant="dark" />
                    </div>
                  ) : (
                    <h4>{props.activeUser}</h4>
                  )}
                  <p>Active User</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="CounterCard">
              <div className="CounterCardContent">
                <div className="CounterCardIcon DangerCount">
                  <i className="insight wpi-user" />
                </div>
                <div className="CounterCardText">
                  {props.reportType.length === 0 && props.reportLoader ? (
                    <div
                      className=""
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Spinner animation="border" variant="dark" />
                    </div>
                  ) : (
                    <h4>{props.inActiveUser}</h4>
                  )}
                  <p>Inactive User</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="CounterCard">
              <div className="CounterCardContent">
                <div className="CounterCardIcon YellowCount">
                  <i className="insight wpi-user" />
                </div>
                <div className="CounterCardText">
                  {props.reportType.length === 0 && props.reportLoader ? (
                    <div
                      className=""
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Spinner animation="border" variant="dark" />
                    </div>
                  ) : (
                    <h4>{props.newUserCount}</h4>
                  )}

                  <p>New User</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <>
        {/* analytic section  */}
        <section className="AnalyticSection SectionGap">
          <div className="SectionPanel">
            <div className="PanelTitle">
              <div className="SectionTitle">Analytics</div>
            </div>
            <div className="PanelItem">
              {props.reportType.length === 0 && props.reportLoader ? (
                <div
                  className=""
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: innerHeight <= 750 ? 600 : `${innerHeight}px`,
                  }}
                >
                  <Spinner animation="border" variant="dark" />
                </div>
              ) : (
                <Chart
                  options={AreaChartOption}
                  series={AreaChartSeries}
                  type="area"
                  height={innerHeight <= 750 ? 600 : innerHeight}
                />
              )}
            </div>
          </div>
        </section>

        {/* graph section  */}
        <section className="GraphSection SectionGap">
          <div className="row HasColGap">
            {props.selectedProduct && props.selectedProduct.value !== 'all' && (
              <div className="col-md-6">
                <div className="GraphCard">
                  <div className="SectionTitle">Plugin Version</div>
                  <div className="GraphBody">
                    {props.reportType.length === 0 && props.reportLoader ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          margin: 'auto',
                          alignItems: 'center',
                          height: '350px',
                        }}
                      >
                        <Spinner animation="border" variant="dark" />
                      </div>
                    ) : (
                      <>
                        {pluginVersionCount && pluginVersionCount.length > 0 ? (
                          <Chart
                            options={pluginVersionChartOptions}
                            series={pluginVersionChartSeries}
                            type="donut"
                            height={350}
                          />
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              margin: 'auto',
                              height: '350px',
                              alignItems: 'center',
                            }}
                          >
                            No Data Found
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-6">
              <div className="GraphCard">
                <div className="SectionTitle">PHP Version</div>
                <div className="GraphBody">
                  {props.reportType.length === 0 && props.reportLoader ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: 'auto',
                        alignItems: 'center',
                        height: '350px',
                      }}
                    >
                      <Spinner animation="border" variant="dark" />
                    </div>
                  ) : (
                    <>
                      {phpVersionCount && phpVersionCount.length > 0 ? (
                        <Chart
                          options={phpVersionChartOptions}
                          series={phpVersionCount}
                          type="pie"
                          height={350}
                        />
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: 'auto',
                            height: '350px',
                            alignItems: 'center',
                          }}
                        >
                          No Data Found
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="GraphCard">
                <div className="SectionTitle">WordPress Version</div>
                <div className="GraphBody">
                  {props.reportType.length === 0 && props.reportLoader ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: 'auto',
                        alignItems: 'center',
                        height: '350px',
                      }}
                    >
                      <Spinner animation="border" variant="dark" />
                    </div>
                  ) : (
                    <>
                      {wpVersionCount && wpVersionCount.length > 0 ? (
                        <Chart
                          options={wordpressVersionChartOptios}
                          series={wpVersionCount}
                          type="donut"
                          height={350}
                        />
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: 'auto',
                            height: '350px',
                            alignItems: 'center',
                          }}
                        >
                          No Data Found
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="GraphCard">
                <div className="SectionTitle">Languages</div>
                <div className="GraphBody">
                  {props.reportType.length === 0 && props.reportLoader ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: 'auto',
                        alignItems: 'center',
                        height: '350px',
                      }}
                    >
                      <Spinner animation="border" variant="dark" />
                    </div>
                  ) : (
                    <>
                      {laguagesCount && laguagesCount.length > 0 ? (
                        <Chart
                          options={languageChartOptions}
                          series={laguagesCount}
                          type="pie"
                          height={350}
                        />
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: 'auto',
                            height: '350px',
                            alignItems: 'center',
                          }}
                        >
                          No Data Found
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            {props.selectedProduct && props.selectedProduct.value !== 'all' && (
              <>
                <div className="col-md-6">
                  <div className="GraphCard">
                    <div className="SectionTitle">Status</div>
                    <div className="GraphBody">
                      {props.reportType.length === 0 && props.reportLoader ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: 'auto',
                            alignItems: 'center',
                            height: '350px',
                          }}
                        >
                          <Spinner animation="border" variant="dark" />
                        </div>
                      ) : (
                        <>
                          {statusCount && statusCount.length > 0 ? (
                            <Chart
                              options={statusChartOptions}
                              series={statusCount}
                              type="donut"
                              height={350}
                            />
                          ) : (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin: 'auto',
                                height: '350px',
                                alignItems: 'center',
                              }}
                            >
                              No Data Found
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="GraphCard">
                    <div className="SectionTitle">Deactivated Reason</div>
                    <div className="GraphBody">
                      {props.reportType.length === 0 && props.reportLoader ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: 'auto',
                            alignItems: 'center',
                            height: '350px',
                          }}
                        >
                          <Spinner animation="border" variant="dark" />
                        </div>
                      ) : (
                        <>
                          {deactivatedReasonCount &&
                          deactivatedReasonName.length === 0 ? (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin: 'auto',
                                height: '350px',
                                alignItems: 'center',
                              }}
                            >
                              No Data Found
                            </div>
                          ) : (
                            <Chart
                              options={deactivatedResonsChartOptions}
                              series={deactivatedReasonCount}
                              type="pie"
                              height={350}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="col-md-6" />
            <div className="col-md-9">
              <div className="GraphCard">
                <div className="SectionTitle">MailChimp Analytics</div>
                <div className="GraphBody">
                  {props.reportType.length === 0 && props.reportLoader ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: 'auto',
                        alignItems: 'center',
                        height: '400px',
                      }}
                    >
                      <Spinner animation="border" variant="dark" />
                    </div>
                  ) : (
                    <>
                      {mailChimpPluginCount &&
                      mailChimpPluginCount.length > 0 ? (
                        <Chart
                          options={barChartDataOptions}
                          series={barChartDataSeries}
                          type="bar"
                          height={innerHeight <= 500 ? 400 : innerHeight}
                        />
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: 'auto',
                            height: '350px',
                            alignItems: 'center',
                          }}
                        >
                          No Data Found
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default ProductReport;
