import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import WpiLogo from '../../assets/SVG/logo-white.svg';

import wpiLogin from '../../assets/img/login.svg';

/* eslint-disable */
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const strongPassRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
);
/* eslint-enable */

const SignInForm = ({
  userSignIn,
  signin,
  history,
  signInLoader,
  validationError,
}) => {
  let email;
  let password;

  const [err, setErr] = useState({
    email: [],
    password: [],
  });
  /* eslint-disable */
  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token && signin && signin.redirect) {
      email.value = '';
      password.value = '';
      signin.accountType === 'SUPER_ADMIN' &&
        history.push('/user/admin_user/dashboard');
      signin.accountType !== 'SUPER_ADMIN' && history.push('/user/dashboard');
    }

    if (signin && !signin.redirect) {
      email.value = '';
      password.value = '';
      signin.accountType !== 'SUPER_ADMIN' &&
        signin.redirect_to === 'company-create-form' &&
        history.push('/auth/create-company-form');
    }
  }, [signin, history]);

  useEffect(() => {
    if (validationError.length > 0) {
      let emailErr = [];
      let passwordErr = [];
      validationError[0].map((err) => {
        if (err.param === 'email') {
          emailErr.push(err.msg);
        }
        if (err.param === 'password') {
          passwordErr.push(err.msg);
        }
      });

      setErr({
        ...err,
        email: emailErr,
        password: passwordErr,
      });
    } else {
      setErr({
        email: [],
        password: [],
      });
    }
  }, [validationError]);

  /* eslint-enable */

  const validateForm = () => {
    const errors = [];

    const emailErr = [];
    const passwordErr = [];

    if (email.value === '') {
      emailErr.push("Email can't be empty");
      errors.push("Email can't be empty");
    }

    if (email.length > 0 && !validEmailRegex.test(email.value)) {
      errors.push('Email is not valid');
      emailErr.push('Email is not valid');
    }

    if (password.value === '') {
      passwordErr.push("Password can't be empty");
      errors.push("Password can't be empty");
    }

    // if (password.value.length > 0 && !strongPassRegex.test(password.value)) {
    //   errors.push(
    //     'Password must contains these: at least 1 lowercase alphabetical character, at least 1 uppercase alphabetical character,' +
    //       'at least 1 numeric character, at least one special character, must be eight characters or longer'
    //   );
    //   passwordErr.push('Password must contains all of these:');
    //   passwordErr.push('At least 1 lowercase alphabetical character');
    //   passwordErr.push('At least 1 uppercase alphabetical character');
    //   passwordErr.push('At least 1 numeric character');
    //   passwordErr.push('At least one special character');
    //   passwordErr.push('Must be eight characters or longer');
    // }

    if (errors.length > 0) {
      setErr({
        email: emailErr,
        password: passwordErr,
      });
    }
    return errors;
  };

  const submitSignInForm = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (errors.length > 0) {
      return;
    }
    userSignIn({
      email: email.value,
      password: password.value,
    });
  };

  return (
    <div className="WpPageBody LoginBody">
      <div className="LoginHeader">
        <Link to="/auth/sign-in" className="SiteLogo">
          <img src={WpiLogo} alt="" />
        </Link>
        {/* <a href="sign-in" className="SiteLogo">
          <img src={WpiLogo} alt="" />
        </a> */}
      </div>
      <div className="LoginWrapper">
        <div className="LoginContent">
          <div className="LoginForm">
            <Form onSubmit={submitSignInForm}>
              <h2>Login</h2>
              <div className="FormGroup mb-4">
                <label>Email Address</label>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  placeholder="Example@example.com"
                  autoFocus
                  ref={(node) => {
                    email = node;
                  }}
                />
                {err &&
                  err.email &&
                  err.email.map((err, index) => (
                    <p key={index} style={{ color: 'red' }}>
                      {err}
                    </p>
                  ))}
              </div>
              <div className="FormGroup mb-4">
                <label>Password</label>
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  placeholder="********"
                  ref={(node) => {
                    password = node;
                  }}
                />
                {err &&
                  err.password &&
                  err.password.map((err, index) => (
                    <p key={index} style={{ color: 'red' }}>
                      {err}
                    </p>
                  ))}
              </div>
              {/* <label className="Checkbox mb-4">
                <input type="checkbox" />
                <span>Remember Me</span>
              </label> */}
              <button type="submit" className="Button PrimaryButton w-100 mb-4">
                <span>
                  {signInLoader ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    'Login'
                  )}
                </span>
              </button>
              <div className="d-flex">
                <Link to="/auth/forget-pass">Forgot Password?</Link>
                <Link to="/auth/resent-verification" className="ml-auto">
                  Resend Verification Link
                </Link>
              </div>
              <p className="mt-5 ColorSecondary">
                Don't Have an Account?{' '}
                <Link className="ColorPrimary" to="/auth/registration">
                  Sign Up
                </Link>
              </p>
            </Form>
          </div>
        </div>
        <div className="LoginImage">
          <img src={wpiLogin} alt="" className="w-100 img-fluid" />
        </div>
      </div>
    </div>
  );
};

SignInForm.prototypes = {
  signin: PropTypes.shape({
    redirect: PropTypes.bool,
    token: PropTypes.string,
  }),
};

export default SignInForm;
