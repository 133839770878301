import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';

import WpiLogo from '../../assets/SVG/logo-white.svg';

import wpiLogin from '../../assets/img/login.svg';

/* eslint-disable */
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const strongPassRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'
);
/* eslint-enable */

const RegisterForm = ({
  register,
  history,
  userRegister,
  validationError,
  location,
  resetValidationError,
}) => {
  let email;
  let password;
  let confirmPassword;
  let userName;
  let name;
  let accessCode;
  let companyName;

  const [err, setErr] = useState({
    email: [],
    password: [],
    confirmPassword: [],
    name: [],
    userName: [],
    accessCode: [],
  });

  /* eslint-disable */
  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token && register && register.redirect) {
      email.value = '';
      password.value = '';
      userName.value = '';
      confirmPassword.value = '';
      name.value = '';
      accessCode.value = '';
      companyName.value = '';
      history.push('/user/dashboard');
    }
    email.focus();
  }, [register, location.pathname]);

  useEffect(() => {
    if (validationError.length > 0) {
      let emailErr = [];
      let passwordErr = [];
      let confirmPasswordErr = [];
      let nameErr = [];
      let userNameErr = [];
      let accessCodeErr = [];
      let companyNameErr = [];

      validationError[0].map((err) => {
        if (err.param === 'email') {
          emailErr.push(err.msg);
        }
        if (err.param === 'password') {
          passwordErr.push(err.msg);
        }
        if (err.param === 'confirmPasswordErr') {
          confirmPasswordErr.push(err.msg);
        }
        if (err.param === 'name') {
          nameErr.push(err.msg);
        }
        if (err.param === 'username') {
          userNameErr.push(err.msg);
        }
        if (err.param === 'company_name') {
          companyNameErr.push(err.msg);
        }
        if (err.param === 'accessCode') {
          accessCodeErr.push(err.msg);
        }
      });

      setErr({
        ...err,
        email: emailErr,
        password: passwordErr,
        confirmPassword: confirmPasswordErr,
        name: nameErr,
        userName: userNameErr,
        accessCode: accessCodeErr,
        companyName: companyNameErr,
      });
    } else {
      setErr({
        email: [],
        password: [],
        confirmPassword: [],
        name: [],
        userName: [],
        accessCode: [],
        companyName: [],
      });
    }
  }, [validationError]);

  /* eslint-enable */

  const validateForm = () => {
    const errors = [];

    const emailErr = [];
    const passwordErr = [];
    const confirmPasswordErr = [];
    const nameErr = [];
    const userNameErr = [];
    const accessCodeErr = [];
    const companyNameErr = [];

    if (email.value === '') {
      emailErr.push("Email can't be empty");
      errors.push("Email can't be empty");
    }

    if (email.length > 0 && !validEmailRegex.test(email.value)) {
      errors.push('Email is not valid');
      emailErr.push('Email is not valid');
    }

    if (name.value === '') {
      nameErr.push("Name can't be empty");
      errors.push("Name can't be empty");
    }

    if (name.length > 255) {
      nameErr.push("Name can't be over 255 charecter");
      errors.push("Name can't be over 255 charecter");
    }

    if (userName.value === '') {
      userNameErr.push("User name can't be empty");
      errors.push("User name can't be empty");
    }

    if (userName.length > 255) {
      userNameErr.push("User name can't be over 255 charecter");
      errors.push("User name can't be over 255 charecter");
    }
    if (companyName.value === '') {
      companyNameErr.push("Company name can't be empty");
      errors.push("Company name can't be empty");
    }

    if (companyName.length > 255) {
      companyNameErr.push("Company name can't be over 255 charecter");
      errors.push("Company name can't be over 255 charecter");
    }

    if (password.value === '') {
      passwordErr.push("Password can't be empty");
      errors.push("Version number can't be empty");
    }
    if (password.value.length > 0 && !strongPassRegex.test(password.value)) {
      errors.push(
        'Password must contains these: at least 1 lowercase alphabetical character, at least 1 uppercase alphabetical character,' +
          'at least 1 numeric character, at least one special character, must be eight characters or longer'
      );
      // passwordErr.push('Password must contains all of these:');
      // passwordErr.push('At least 1 lowercase alphabetical character');
      // passwordErr.push('At least 1 uppercase alphabetical character');
      // passwordErr.push('At least 1 numeric character');
      // // passwordErr.push('At least one special character');
      // passwordErr.push('Must be eight characters or longer');

      passwordErr.push(
        'Password must contain 1 lowercase, 1 uppercase,1 number and it must be 8 characters or longer'
      );
    }

    if (confirmPassword.value === '') {
      confirmPasswordErr.push("Confirm password can't be empty");
      errors.push("Confirm password can't be empty");
    }

    if (password.value !== confirmPassword.value) {
      confirmPasswordErr.push(
        'Confirm password does not match with the password'
      );
      errors.push('Confirm password does not match with the password');
    }

    if (!accessCode || accessCode.value === '') {
      accessCodeErr.push("Access code can't be empty");
      errors.push("Access code can't be empty");
    }

    if (
      accessCode &&
      accessCode.value.length > 0 &&
      accessCode.value !== 'wpd-wpi'
    ) {
      accessCodeErr.push(
        'Wrong access code, please collect the correct access code'
      );
      errors.push('Wrong access code, please collect the correct access code');
    }

    if (errors.length > 0) {
      setErr({
        email: emailErr,
        password: passwordErr,
        confirmPassword: confirmPasswordErr,
        name: nameErr,
        userName: userNameErr,
        accessCode: accessCodeErr,
        companyName: companyNameErr,
      });
    }
    return errors;
  };

  const submitRegitrationForm = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (errors.length > 0) {
    } else {
      userRegister({
        email: email.value,
        password: password.value,
        username: userName.value,
        name: name.value,
        password_confirmation: confirmPassword.value,
        access_code: accessCode.value,
        company_name: companyName.value,
      });
    }
  };

  const forgotPass = () => {
    resetValidationError();
    history.push('/auth/forget-pass');
  };
  const resendVerification = () => {
    resetValidationError();
    history.push('/auth/resent-verification');
  };
  const signIn = () => {
    resetValidationError();
    history.push('/auth/sign-in');
  };

  return (
    <div className="WpPageBody LoginBody">
      <div className="LoginHeader">
        <Link to="/auth/sign-in" className="SiteLogo">
          <img src={WpiLogo} alt="" />
        </Link>
      </div>
      <div className="LoginWrapper">
        <div className="LoginContent">
          <div className="LoginForm">
            <Form onSubmit={submitRegitrationForm}>
              <h2>Registration</h2>
              <div className="FormGroup mb-4">
                <label>Email Address</label>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  placeholder="Example@example.com"
                  ref={(node) => {
                    email = node;
                  }}
                />
                {err &&
                  err.email &&
                  err.email.map((err, index) => (
                    <p key={index} style={{ color: 'red' }}>
                      {err}
                    </p>
                  ))}
              </div>

              <div className="FormGroup mb-4">
                <label>Name</label>
                <input
                  type="text"
                  id="userName"
                  className="form-control"
                  placeholder="Name"
                  ref={(node) => {
                    name = node;
                  }}
                />
                {err &&
                  err.name &&
                  err.name.map((err, index) => (
                    <p key={index} style={{ color: 'red' }}>
                      {err}
                    </p>
                  ))}
              </div>

              <div className="FormGroup mb-4">
                <label>User Name</label>
                <input
                  type="text"
                  id="userName"
                  className="form-control"
                  placeholder="User name"
                  ref={(node) => {
                    userName = node;
                  }}
                />
                {err &&
                  err.userName &&
                  err.userName.map((err, index) => (
                    <p key={index} style={{ color: 'red' }}>
                      {err}
                    </p>
                  ))}
              </div>
              <div className="FormGroup mb-4">
                <label>Company Name</label>
                <input
                  type="text"
                  id="companyName"
                  className="form-control"
                  placeholder="Company name"
                  ref={(node) => {
                    companyName = node;
                  }}
                />
                {err &&
                  err.companyName &&
                  err.companyName.map((err, index) => (
                    <p key={index} style={{ color: 'red' }}>
                      {err}
                    </p>
                  ))}
              </div>

              <div className="FormGroup mb-4">
                <label>Password</label>
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  placeholder="********"
                  ref={(node) => {
                    password = node;
                  }}
                />
                {err &&
                  err.password &&
                  err.password.map((err, index) => (
                    <p key={index} style={{ color: 'red' }}>
                      {err}
                    </p>
                  ))}
              </div>

              <div className="FormGroup mb-4">
                <label>Confirm Password</label>
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  placeholder="********"
                  ref={(node) => {
                    confirmPassword = node;
                  }}
                />
                {err &&
                  err.confirmPassword &&
                  err.confirmPassword.map((err, index) => (
                    <p key={index} style={{ color: 'red' }}>
                      {err}
                    </p>
                  ))}
              </div>

              <div className="FormGroup mb-4">
                <label>Access Code</label>
                <input
                  type="text"
                  id="access_code"
                  className="form-control"
                  placeholder="Your access code"
                  ref={(node) => {
                    accessCode = node;
                  }}
                />
                {err &&
                  err.accessCode &&
                  err.accessCode.map((err, index) => (
                    <p key={index} style={{ color: 'red' }}>
                      {err}
                    </p>
                  ))}
              </div>

              {/* <label className="Checkbox mb-4">
                <input type="checkbox" />
                <span>Remember Me</span>
              </label> */}
              <button type="submit" className="Button PrimaryButton w-100 mb-4">
                Register
              </button>
              <div className="d-flex">
                <button onClick={forgotPass}>Forgot Password?</button>
                <button
                  onClick={resendVerification}
                  className="ml-auto ColorSecondary"
                >
                  Resend Verification Link
                </button>
              </div>
              <p className="mt-5 ColorSecondary">
                Already Have an Account?{' '}
                <button className="ColorPrimary" onClick={signIn}>
                  Sign In
                </button>
              </p>
            </Form>
          </div>
        </div>
        <div className="LoginImage">
          <img src={wpiLogin} alt="" className="w-100 img-fluid" />
        </div>
      </div>
    </div>
  );
};

RegisterForm.prototypes = {
  register: PropTypes.shape({
    redirect: PropTypes.bool,
    token: PropTypes.string,
  }),
};

export default RegisterForm;
