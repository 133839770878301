import { all, put, takeLatest } from 'redux-saga/effects';
import {
  LOAD_SUPER_ADMIN_COMPANY_LIST,
  RENDER_SUPER_ADMIN_COMPANY_LIST,
  LOAD_SUPER_ADMIN_COMPANY_FAILED,
  UPDATE_SUPER_ADMIN_COMPANY,
  UPDATE_SUPER_ADMIN_COMPANY_SUCCESS,
  UPDATE_SUPER_ADMIN_COMPANY_FAILED,
  CREATE_SUPER_ADMIN_COMPANY,
  CREATE_SUPER_ADMIN_COMPANY_SUCCESS,
  CREATE_SUPER_ADMIN_COMPANY_FAILED,
  UPDATE_SUPER_ADMIN_COMPANY_MEMBERS,
  UPDATE_SUPER_ADMIN_COMPANY_MEMBERS_SUCCESS,
  UPDATE_SUPER_ADMIN_COMPANY_MEMBERS_FAILED,
} from '../actions/SuperAdminCompanyActions';

import { TOKEN_ERROR } from '../actions/index';

import { defaultApi } from '../utils/axiosApi';

export function* fetchCompanyList(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/company/all`;

  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: RENDER_SUPER_ADMIN_COMPANY_LIST,
      currentPage: data.data && data.data.current_page,
      totalPage: data.data && data.data.total_page,
      total: data.data && data.data.total,
      companyList: data.data && data.data.companyList,
      companyMembers: data.data && data.data.companyMembers,
      companyMembersCount: data.data && data.data.companyMembersCount,
      allUser: data.data && data.data.allUser,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: LOAD_SUPER_ADMIN_COMPANY_FAILED,
        message: data.message,
      });
    }
  }
}

export function* updateCompanyAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/company/update`;

  const credentials2 = credentials.credentials;

  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: UPDATE_SUPER_ADMIN_COMPANY_SUCCESS,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: UPDATE_SUPER_ADMIN_COMPANY_FAILED,
        message: data.message,
      });
    }
  }
}
export function* createCompanyAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/company/add`;
  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: CREATE_SUPER_ADMIN_COMPANY_SUCCESS,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: CREATE_SUPER_ADMIN_COMPANY_FAILED,
        message: data.message,
      });
    }
  }
}

export function* updateCompanyMembersAttempt(credentials) {
  const endpoint = `${process.env.REACT_APP_API_BASE_URL}/super-admin/company/members/update`;

  const credentials2 = credentials.credentials;
  const { response, error } = yield defaultApi(
    endpoint,
    'POST',
    credentials2,
    true
  );
  if (response) {
    const { data } = response;
    yield put({
      type: UPDATE_SUPER_ADMIN_COMPANY_MEMBERS_SUCCESS,
      message: data.message,
    });
  }
  if (error) {
    const { data } = error;
    if (data.status === 'Token_error') {
      yield put({
        type: TOKEN_ERROR,
        message: data.message,
      });
    } else {
      yield put({
        type: UPDATE_SUPER_ADMIN_COMPANY_MEMBERS_FAILED,
        message: data.message,
      });
    }
  }
}

export function* loadCompanyList() {
  yield takeLatest(LOAD_SUPER_ADMIN_COMPANY_LIST, fetchCompanyList);
}

export function* updateCompany() {
  yield takeLatest(UPDATE_SUPER_ADMIN_COMPANY, updateCompanyAttempt);
}
export function* createCompany() {
  yield takeLatest(CREATE_SUPER_ADMIN_COMPANY, createCompanyAttempt);
}

export function* updateCompanyMembers() {
  yield takeLatest(
    UPDATE_SUPER_ADMIN_COMPANY_MEMBERS,
    updateCompanyMembersAttempt
  );
}

export default function* rootSaga() {
  yield all([
    loadCompanyList(),
    updateCompany(),
    createCompany(),
    updateCompanyMembers(),
  ]);
}
