import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';

import WpiLogo from '../../assets/SVG/logo-white.svg';

import wpiLogin from '../../assets/img/login.svg';

/* eslint-disable */
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
/* eslint-enable */

const ResentVerificationLink = ({
  userVerification,
  resendVerification,
  history,
  validationError,
}) => {
  let email;
  const [err, setErr] = useState({
    email: [],
  });
  /* eslint-disable */
  useEffect(() => {
    if (validationError.length > 0) {
      let emailErr = [];
      validationError.map((err) => {
        if (err.param === 'email') {
          emailErr.push(err.msg);
        }
        return err;
      });

      setErr({
        ...err,
        email: emailErr,
      });
    } else {
      setErr({
        email: [],
      });
    }
  }, [validationError]);
  /* eslint-enable */

  const validateForm = () => {
    const errors = [];
    const emailErr = [];
    if (email.value === '') {
      emailErr.push("Email can't be empty");
      errors.push("Email can't be empty");
    }

    if (email.length > 0 && !validEmailRegex.test(email.value)) {
      errors.push('Email is not valid');
      emailErr.push('Email is not valid');
    }

    if (errors.length > 0) {
      setErr({
        email: emailErr,
      });
    }
    return errors;
  };

  const submitResendVerificationLinkForm = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (errors.length > 0) {
      return;
    }

    userVerification({
      email: email.value,
    });

    email.value = '';
  };

  return (
    <div className="WpPageBody LoginBody">
      <div className="LoginHeader">
        <Link to="/auth/sign-in" className="SiteLogo">
          <img src={WpiLogo} alt="" />
        </Link>
      </div>
      <div className="LoginWrapper">
        <div className="LoginContent">
          <div className="LoginForm">
            <Form onSubmit={submitResendVerificationLinkForm}>
              <Link className="BackButton mb-4" to="/auth/sign-in">
                <div className="BackButtonIcon">
                  <i className="insight wpi-sort-left" />
                </div>
                <div className="BackButtonText">Back to Login</div>
              </Link>
              <h2>Resend Verification</h2>
              <div className="FormGroup mb-4">
                <label>Email Address</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Example@example.com"
                  id="email"
                  autoFocus
                  ref={(node) => {
                    email = node;
                  }}
                />
                {err &&
                  err.email &&
                  err.email.map((err, index) => (
                    <p key={index} style={{ color: 'red' }}>
                      {err}
                    </p>
                  ))}
              </div>
              <button className="Button PrimaryButton w-100">Submit</button>
            </Form>
          </div>
        </div>
        <div className="LoginImage">
          <img src={wpiLogin} alt="" className="w-100 img-fluid" />
        </div>
      </div>
    </div>
  );
};

ResentVerificationLink.prototypes = {
  resendVerification: PropTypes.shape({
    redirect: PropTypes.bool,
  }),
};

export default ResentVerificationLink;
