import { connect } from 'react-redux';
import Companies from '../components/Company/companies';
import {
  getAllCompany,
  resetRequestSuccess,
  addNewCompany,
  updateCompany,
  deleteCompany,
} from '../actions/CompanyActions';

import { resetAccessRemoved, resetCompanyNotFound } from '../actions/index';

const mapStateToProps = (state) => ({
  companyList: state.company.companyList,
  currentPage: state.company.currentPage,
  totalPage: state.company.totalPage,
  total: state.company.total,
  roles: state.company.roles,
  requestSuccess: state.company.requestSuccess,
  loading: state.util.loading,
  accountType: state.auth.signin && state.auth.signin.accountType,
  activeProfile: state.auth.signin && state.auth.signin.activeProfile,
  firstTime: state.company.firstTime,
  companyListLoader: state.company.companyListLoader,
  modalLoader: state.company.modalLoader,
  accessRemoved: state.auth && state.auth.accessRemoved,
  companyNotFound: state.auth && state.auth.companyNotFound,
  rolePermissionInfo:
    state.auth && state.auth.signin && state.auth.signin.role_permission_info,
});

const mapDispatchToProps = (dispatch) => ({
  allCompany: (value) => dispatch(getAllCompany(value)),
  resetRequestSuccess: () => dispatch(resetRequestSuccess()),
  addCompanay: (value) => dispatch(addNewCompany(value)),
  updateCompany: (value) => dispatch(updateCompany(value)),
  deleteCompany: (value) => dispatch(deleteCompany(value)),
  resetAccessRemoved: () => dispatch(resetAccessRemoved()),
  resetCompanyNotFound: () => dispatch(resetCompanyNotFound()),
});

const CompanyContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Companies);

export default CompanyContainer;
