import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Profile from './Profile';
import PasswordChange from './PasswordChange';
import PreferredConfig from './PreferredConfig';
import ProfileModal from './ProfileModal';
import PlaceholderFileChange from './PlaceholderFileChange';
import profileImg from '../../../assets/img/dummy-profile.png';

const tableColumnNames = [
  { isChecked: true, name: 'url', lable: 'URL' },
  { isChecked: true, name: 'since', lable: 'Since' },
  { isChecked: true, name: 'deactivated', lable: 'Deactivated' },
  { isChecked: true, name: 'pluginVersion', lable: 'Version' },
  { isChecked: true, name: 'language', lable: 'Language' },
  { isChecked: true, name: 'alexaRank', lable: 'Alexa Rank' },
  { isChecked: true, name: 'status', lable: 'Status' },
  // 'plugin_slug',
  { isChecked: true, name: 'plugin_Name', lable: 'Plugin' },
  { isChecked: true, name: 'updatedTime', lable: 'Updated Time' },
  { isChecked: true, name: 'phpVersion', lable: 'PHP Version' },
  { isChecked: true, name: 'wpInsVersion', lable: 'WPIns' },
  { isChecked: true, name: 'siteVersion', lable: 'Site Version' },
];

/* eslint-disable */
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const strongPassRegex = new RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'
);
/* eslint-enable */

const SuperAdminSettings = (props) => {
  const [siteTableConfig, setSiteTableConfig] = useState(tableColumnNames);
  const [orderByColumn, setOrderByColumn] = useState('updatedTime');
  const [orderByColumnSelectoptions, setOrderByColumnSelectoptions] =
    useState('DESC');

  const [secondarySidebarCollaspe, setSecondarySidebarCollaspe] =
    useState(false);

  const [selectedOption, setSelectedOption] = useState(1);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [hasErr, setHasErr] = useState(false);
  const [err, setErr] = useState({
    oldPassword: [],
    newPassword: [],
    confirmNewPassword: [],
  });

  /* eslint-disable */
  useEffect(() => {
    if (props.validationError.length > 0) {
      let newPasswordErr = [];
      let confirmNewPasswordErr = [];

      if (props.validationError[0].password) {
        newPasswordErr = props.validationError[0].password;
      }
      if (props.validationError[0].password_confirmation) {
        confirmNewPasswordErr = props.validationError[0].password_confirmation;
      }

      setErr({
        ...err,
        newPassword: newPasswordErr,
        confirmNewPassword: confirmNewPasswordErr,
      });
    }
  }, [props.validationError]);

  useEffect(() => {
    if (
      props.userConfig &&
      props.userConfig.siteListTable &&
      props.userConfig.siteListTable.show &&
      props.userConfig.siteListTable.show.length > 0
    ) {
      setSiteTableConfig(props.userConfig.siteListTable.show);
    } else {
      setSiteTableConfig(tableColumnNames);
    }

    if (
      props.userConfig &&
      props.userConfig.siteListTable &&
      props.userConfig.siteListTable.sort
    ) {
      setOrderByColumn(props.userConfig.siteListTable.sort[0]);
      setOrderByColumnSelectoptions(props.userConfig.siteListTable.sort[1]);
    }
  }, [props.userConfig]);

  /* eslint-enable */

  const validatePasswordChange = () => {
    const errors = [];

    const oldPasswordErr = [];
    const newPasswordErr = [];
    const confirmNewPasswordErr = [];

    if (oldPassword === '') {
      oldPasswordErr.push("Old Password can't be empty");
      errors.push("Old Password can't be empty");
    }

    if (oldPassword.length > 255) {
      oldPasswordErr.push("Old Password can't be over 255 charecter");
      errors.push("Old Password can't be over 255 charecter");
    }

    if (newPassword === '') {
      newPasswordErr.push("New Password can't be empty");
      errors.push("New Password can't be empty");
    }

    if (newPassword.length > 255) {
      newPasswordErr.push("New Password can't be over 255 charecter");
      errors.push("New Password can't be over 255 charecter");
    }

    if (newPassword.length > 0 && !strongPassRegex.test(newPassword)) {
      errors.push(
        'New Password must contains these: at least 1 lowercase alphabetical character, at least 1 uppercase alphabetical character,' +
          'at least 1 numeric character, at least one special character, must be eight characters or longer'
      );
      newPasswordErr.push(
        'Password must contain 1 lowercase, 1 uppercase,1 number and it must be 8 characters or longer'
      );
    }

    if (confirmNewPassword === '') {
      confirmNewPasswordErr.push("Confirm Password url can't be empty");
      errors.push("Confirm Password url can't be empty");
    }

    if (confirmNewPassword.length > 255) {
      confirmNewPasswordErr.push(
        "Confirm Password url can't be over 255 charecter"
      );
      errors.push("Confirm Password url can't be over 255 charecter");
    }

    if (confirmNewPassword !== newPassword) {
      confirmNewPasswordErr.push(
        'Confirm Password not matched with New Password'
      );
      errors.push('Confirm Password not matched with New Password');
    }

    if (errors.length > 0) {
      setErr({
        oldPassword: oldPasswordErr,
        newPassword: newPasswordErr,
        confirmNewPassword: confirmNewPasswordErr,
      });
      setHasErr(true);
    }

    return errors;
  };

  const updatePass = (e) => {
    e.preventDefault();
    const errors = validatePasswordChange();
    if (errors.length > 0) {
    } else {
      props.updatePass({
        password: newPassword,
        password_confirmation: confirmNewPassword,
        old_password: oldPassword,
      });
    }
  };

  /* profile */

  const [name, setName] = useState('');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [show, setShow] = useState(false);
  const [profile_image, setProfile_image] = useState(profileImg);
  const [profile_image_file, setProfile_image_file] = useState(undefined);
  const [profile_image_delete, setProfile_image_delete] = useState(false);

  const [profileErr, setProfileErr] = useState({
    email: [],
    name: [],
    username: [],
  });

  const handelImageUpdate = (file) => {
    setProfile_image(URL.createObjectURL(file));
    const reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Make a fileInfo Object
      const baseURL = reader.result;
      setProfile_image_file(baseURL);
    };
  };

  const submitProfileUpdate = (e) => {
    e.preventDefault();
    const errors = validateProfile();
    if (errors.length > 0) {
    } else {
      props.updateProfile({
        email,
        name,
        user_name: userName,
        image: profile_image_file,
      });
    }
  };

  const validateProfile = () => {
    const errors = [];
    const UserNameErr = [];
    const NameErr = [];
    const EmailErr = [];

    if (email.length === 0) {
      errors.push("Email can't be empty");
      EmailErr.push("Email can't be empty");
    }

    if (email.length > 0 && !validEmailRegex.test(email)) {
      errors.push('Email is not valid');
      EmailErr.push('Email is not valid');
    }

    if (userName && userName === '') {
      UserNameErr.push("User name can't be empty");
      errors.push("User name can't be empty");
    }

    if (name && name === '') {
      NameErr.push("Name can't be empty");
      errors.push("Name can't be empty");
    }

    if (errors.length > 0) {
      setProfileErr({
        email: EmailErr,
        name: NameErr,
        username: UserNameErr,
      });
    }

    return errors;
  };

  /* eslint-disable */
  useEffect(() => {
    const userData = props.user;
    setName(userData.name);
    setUserName(userData.user_name);
    setEmail(userData.email);
    userData.image && setProfile_image(userData.image);
    userData.image && setProfile_image_delete(true);

    const desiredSettings = queryString.parse(props.location.search);
    if (desiredSettings.subMenu) {
      setSelectedOption(3);
    }
  }, []);

  useEffect(() => {
    props.requestSuccess && setShow(false);
    props.requestSuccess && setOldPassword('');
    props.requestSuccess && setNewPassword('');
    props.requestSuccess && setConfirmNewPassword('');

    props.requestSuccess && props.resetRequestSuccess();

    if (props.requestSuccess) {
      const user = props.user;
      setName(user.name);
      setUserName(user.user_name);
      setEmail(user.email);
      user.image && setProfile_image(user.image);
      user.image && setProfile_image_delete(true);
      !user.image && setProfile_image_delete(false);
      !user.image && setProfile_image(profileImg);
      !user.image && setProfile_image_file(undefined);
    }
  }, [props.requestSuccess]);

  useEffect(() => {
    !show &&
      setProfileErr({
        email: [],
        name: [],
        username: [],
      });
    !show && props.resetRequestSuccess();
  }, [show]);

  useEffect(() => {
    selectedOption === 5 && props.getSuperAdminUserConfig();
  }, [selectedOption]);

  useEffect(() => {
    if (
      props.profileUpdateValidationError &&
      props.profileUpdateValidationError.length > 0
    ) {
      let NameErr = [];
      let EmailErr = [];
      let UserNameErr = [];

      if (props.profileUpdateValidationError[0].name) {
        NameErr = props.profileUpdateValidationError[0].name;
      }

      if (props.profileUpdateValidationError[0].email) {
        EmailErr = props.profileUpdateValidationError[0].email;
      }

      if (props.profileUpdateValidationError[0].username) {
        UserNameErr = props.profileUpdateValidationError[0].username;
      }

      setProfileErr({
        ...err,
        name: NameErr,
        username: UserNameErr,
        email: EmailErr,
      });
    } else {
      setProfileErr({
        email: [],
        name: [],
        username: [],
      });
    }
  }, [props.profileUpdateValidationError]);
  /* eslint-enable */

  const handleClose = (e) => setShow(false);
  const handleShow = () => setShow(true);

  const togolSecondarySideBar = (toggoleSecondarySidebar) => {
    toggoleSecondarySidebar && setSecondarySidebarCollaspe(false);
    !toggoleSecondarySidebar && setSecondarySidebarCollaspe(true);
  };

  /* placeholder */
  const [placeholder_file, setPlaceholder_file] = useState('Placeholder.txt');

  const submitPlaceholderUpdate = (e) => {
    e.preventDefault();
    // let errors = validateProfile();
    // if (errors.length > 0) {
    //   return;
    // } else {

    // }

    const form = new FormData();
    form.append('file', placeholder_file);
    props.updatePlaceHolderFile(form);
  };
  const handelDwnloadPlaceholder = (e) => {
    e.preventDefault();
    props.getPlaceHolderFile();
  };

  const onDragEnd = ({ destination, source, draggableId }) => {
    const names = [...siteTableConfig];
    const draggedTagItem = names.find(
      (item, index) => index === parseInt(draggableId)
    );
    if (!destination) return;
    if (!draggedTagItem) return;
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    names.splice(source.index, 1);
    names.splice(destination.index, 0, draggedTagItem);
    setSiteTableConfig(names);
  };

  const handelOrderByColumn = (e) => {
    setOrderByColumn(e.target.value);
  };

  const handelOrderByColumnSelectoptions = (e) => {
    setOrderByColumnSelectoptions(e.value);
  };

  const handelCheckBox = (e) => {
    const name = e.target.id;
    const { checked } = e.target;

    const list = siteTableConfig.map((item) =>
      item.name === name ? { ...item, isChecked: checked } : item
    );

    setSiteTableConfig(list);
  };

  const onSubmitSettings = () => {
    props.storeSuperAdminUserConfigs({
      siteListTable: siteTableConfig,
      sortConfig: [orderByColumn, orderByColumnSelectoptions],
    });
  };

  const handelImageDelete = (e) => {
    props.deleteProfileImage();
  };

  return (
    <>
      <div className="SectionTitleWrap SectionGap">
        <button
          onClick={(e) => togolSecondarySideBar(secondarySidebarCollaspe)}
          className={`HamburgerToggler ${
            secondarySidebarCollaspe ? 'Active' : ''
          }`}
        >
          <span />
          <span />
          <span />
        </button>
        <p className="SectionTitle" />
      </div>
      <div className="d-flex h-100 position-relative">
        <div
          className={`SecondarySidebar ${
            secondarySidebarCollaspe ? 'Show' : ''
          }`}
        >
          <ul className="SidebarNav">
            <li className="SidebarNavItem">
              <button
                onClick={(e) => setSelectedOption(1)}
                className={`SidebarNavItemLink ${
                  selectedOption === 1 ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">profile</div>
              </button>
            </li>
            <li className="SidebarNavItem">
              <button
                onClick={(e) => setSelectedOption(2)}
                className={`SidebarNavItemLink ${
                  selectedOption === 2 ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">Password Change</div>
              </button>
            </li>
            <li className="SidebarNavItem">
              <button
                onClick={(e) => setSelectedOption(4)}
                className={`SidebarNavItemLink ${
                  selectedOption === 4 ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">SDK Placeholder</div>
              </button>
            </li>
            <li className="SidebarNavItem">
              <a
                href="#details"
                onClick={(e) => setSelectedOption(5)}
                className={`SidebarNavItemLink ${
                  selectedOption === 5 ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">Preferred Config</div>
              </a>
            </li>
            {/* <li className="SidebarNavItem">
              <a
                href="#details"
                onClick={(e) => setSelectedOption(3)}
                className={`SidebarNavItemLink ${
                  selectedOption === 3 ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">Subscription</div>
              </a>
            </li> */}
          </ul>
        </div>
        <div className="SecondarySidebarContent">
          {props.setingsLoader ? (
            <section className="FormViewSection">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '400px',
                }}
              >
                <Spinner animation="border" variant="dark" />
              </div>
            </section>
          ) : (
            <>
              {selectedOption === 1 && (
                <>
                  <Profile
                    email={email}
                    name={name}
                    userName={userName}
                    handleShow={handleShow}
                    profile_image={profile_image}
                    profile_image_delete={profile_image_delete}
                    handelImageDelete={handelImageDelete}
                  />

                  <Modal
                    show={show}
                    onHide={handleClose}
                    size="xl"
                    centered
                    backdrop="static"
                  >
                    <Modal.Header bsPrefix="ModalHeader">
                      <Modal.Title bsPrefix="modal-title">
                        Update Profile
                      </Modal.Title>
                      <button
                        type="button"
                        className="close ml-auto"
                        data-dismiss="modal"
                        onClick={handleClose}
                        aria-label="Close"
                      >
                        <span aria-hidden="true">
                          <i className="insight wpi-close" />
                        </span>
                      </button>
                    </Modal.Header>
                    <Modal.Body bsPrefix="ModalBody">
                      <ProfileModal
                        setEmail={setEmail}
                        email={email}
                        name={name}
                        setName={setName}
                        userName={userName}
                        setUserName={setUserName}
                        err={profileErr}
                        submitProfileUpdate={submitProfileUpdate}
                        profile_image={profile_image}
                        setProfile_image={setProfile_image}
                        handelImageUpdate={handelImageUpdate}
                        profile_image_delete={profile_image_delete}
                      />
                    </Modal.Body>
                  </Modal>
                </>
              )}
              {selectedOption === 2 && (
                <PasswordChange
                  err={err}
                  hasErr={hasErr}
                  setOldPassword={setOldPassword}
                  setNewPassword={setNewPassword}
                  setConfirmNewPassword={setConfirmNewPassword}
                  oldPassword={oldPassword}
                  newPassword={newPassword}
                  confirmNewPassword={confirmNewPassword}
                  updatePass={updatePass}
                />
              )}
              {selectedOption === 4 && (
                <PlaceholderFileChange
                  err={err}
                  hasErr={hasErr}
                  placeholder_file={placeholder_file}
                  setPlaceholder_file={setPlaceholder_file}
                  submitPlaceholderUpdate={submitPlaceholderUpdate}
                  handelDwnloadPlaceholder={handelDwnloadPlaceholder}
                  handleShow={handleShow}
                  show={show}
                />
              )}
              {selectedOption === 5 && (
                <PreferredConfig
                  setingsLoader={props.setingsLoader}
                  setSiteTableConfig={setSiteTableConfig}
                  siteTableConfig={siteTableConfig}
                  onDragEnd={onDragEnd}
                  orderByColumn={orderByColumn}
                  setOrderByColumn={setOrderByColumn}
                  handelOrderByColumn={handelOrderByColumn}
                  orderByColumnSelectoptions={orderByColumnSelectoptions}
                  setOrderByColumnSelectoptions={setOrderByColumnSelectoptions}
                  handelOrderByColumnSelectoptions={
                    handelOrderByColumnSelectoptions
                  }
                  onSubmitSettings={onSubmitSettings}
                  handelCheckBox={handelCheckBox}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SuperAdminSettings;
