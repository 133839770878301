import React, { useState, useEffect } from 'react';
import Pagination from 'rc-pagination';

import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import CompanyList from './CompanyList';
import CompanyModal from './CompanyModal';
import CompanyMembersModal from './CompanyMembersModal';

const SuperAdminCompanies = ({
  history,
  companyList,
  userCount,
  currentPage,
  totalPage,
  total,
  requestSuccess,
  validationError,
  getAllCompanies,
  updateCompany,
  resetRequestSuccess,
  resetPage,
  addCompany,
  companyMembers,
  companyMembersCount,
  allUser,
  updateCompanyMembers,
  companyListLoader,
  modalLoader,
}) => {
  const [show, setShow] = useState(false);
  const [membersModalShow, setMembersModalShow] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const handleMembersModalClose = () => setMembersModalShow(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [companyTeamMembers, setCompanyTeamMembers] = useState([]);
  const [companyOwner, setCompanyOwner] = useState({
    value: '',
    label: 'select',
  });

  const [companyData, setCompanyData] = useState([]);
  const [allUserList, setAllUserList] = useState([]);
  const [name, setName] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [editCompany, setEditCompany] = useState(false);
  const [companyStatus, setCompanyStatus] = useState({
    value: '',
    label: 'select',
  });

  const [err, setErr] = useState({
    name: [],
    companyStatus: [],
  });
  /* eslint-disable */
  useEffect(() => {
    getAllCompanies({
      page: currentPage,
      per_page: perPage,
    });
  }, []);

  useEffect(() => {
    requestSuccess && setShow(false);
    requestSuccess && setMembersModalShow(false);
    requestSuccess &&
      getAllCompanies({
        page: currentPage,
        per_page: perPage,
      });
  }, [requestSuccess]);

  useEffect(() => {
    !show && setEditCompany(false);
    !show && resetRequestSuccess();
    !show && setName('');
    !show && setCompanyId('');
    !show &&
      setCompanyStatus({
        label: 'Select',
        value: '',
      });
    !show &&
      setErr({
        name: [],
        companyStatus: [],
      });
  }, [show]);

  useEffect(() => {
    if (companyList.length > 0) {
      var dataArray = companyList.map((company) => {
        var newArray = {};
        newArray.name = company.name;
        const memberCount = companyMembersCount.filter(
          (memberCount) => memberCount.company === company.id
        );
        newArray.userCount = memberCount.length > 0 ? memberCount[0].user : 0;
        newArray.companyStatus = company.status;
        newArray.createdBy = company.createdBy;
        newArray.id = company.id;
        return newArray;
      });
      setCompanyData(dataArray);
    } else {
      setCompanyData([]);
    }
  }, [companyList]);

  useEffect(() => {
    if (allUser && allUser.length > 0) {
      var dataArray = allUser.map((user) => {
        var newArray = {};
        newArray.label = `${user.email} ${
          user.name ? '(' + user.name + ')' : ''
        }`;
        newArray.value = user.id;
        return newArray;
      });
      setAllUserList(dataArray);
    } else {
      setAllUserList([]);
    }
  }, [allUser]);

  useEffect(() => {
    if (
      validationError &&
      validationError[0] &&
      validationError[0].length > 0
    ) {
      var nameError = [];
      var companyStatusError = [];

      validationError[0].map((err) => {
        if (err.param === 'name') {
          nameError.push(err.msg);
        }
        if (err.param === 'status') {
          companyStatusError.push(err.msg);
        }
      });

      setErr({
        ...err,
        name: nameError,
        companyStatus: companyStatusError,
      });
    } else {
      setErr({
        name: [],
        companyStatus: [],
      });
    }
  }, [validationError]);
  /* eslint-enable */

  const handleMembersModal = (id) => {
    setMembersModalShow(true);
    companyMembers
      .filter((member) => member.company === id)
      .forEach((m) => {
        setCompanyTeamMembers(m.users);
      });
    companyList
      .filter((data) => data.id === id)
      .forEach((p) => {
        setCompanyOwner({
          value: p.created_by,
          label: p.created_by,
        });
        setName(p.name);
        setCompanyId(id);
      });
  };
  const submitCompanyMembers = () => {
    updateCompanyMembers({
      company_id: companyId,
      owner: companyOwner.value,
      members: companyTeamMembers,
    });
  };

  const handleEditModal = (id) => {
    setEditCompany(true);
    setShow(true);
    companyList
      .filter((data) => data.id === id)
      .forEach((p) => {
        setName(p.name);
        setCompanyId(id);
        setCompanyStatus({
          label: p.status,
          value: p.status,
        });
      });
  };
  const validate = () => {
    const errors = [];
    const nameError = [];
    const companyStatusErr = [];

    if (name.length === 0) {
      nameError.push("Name can't be empty");
      errors.push("Name can't be empty");
    }

    if (!name.replace(/\s/g, '').length) {
      nameError.push("Name can't be empty");
      errors.push("Name can't be empty");
    }

    if (
      companyStatus &&
      (companyStatus.value === '' || companyStatus.value === undefined)
    ) {
      companyStatusErr.push('please select a option');
      errors.push('please select a option');
    }

    if (!companyStatus) {
      companyStatusErr.push('please select a option');
      errors.push('please select a option');
    }

    if (errors.length > 0) {
      setErr({
        name: nameError,
        companyStatus: companyStatusErr,
      });
    }
    return errors;
  };

  const submitCompany = () => {
    const errors = validate();
    if (errors.length > 0) {
    } else if (editCompany) {
      updateCompany({
        name,
        status: companyStatus.value,
        company_id: companyId,
      });
    } else {
      addCompany({
        name,
        status: companyStatus.value,
      });
    }
  };

  const handelPageChange = (e) => {
    getAllCompanies({
      page: e,
      per_page: perPage,
    });
  };

  const handelPageSizeChange = (e) => {
    e.preventDefault();
    setPerPage(e.target.value);
    getAllCompanies({
      per_page: e.target.value,
    });
  };

  const handelSearch = (searchValue) => {
    if (searchValue === '') {
      getAllCompanies({
        per_page: perPage,
      });
    }
  };

  const handelSearchEnter = (e) => {
    if (e.key === 'Enter') {
      getAllCompanies({
        per_page: perPage,
        search: e.target.value,
      });
    }
  };

  //   const handelFindTeamMembers = (userId) => {
  //     history.push('/user/admin_user/team_members?user=' + userId);
  //   };

  return (
    <>
      <section className="DataViewSection SectionGap">
        <div className="DataViewHeading">
          <div className="DataViewHeadingLeftContent">
            <div className="SectionTitle">Companies</div>
          </div>
          <div className="DataViewHeadingRightContent">
            <div className="SearchForm">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={(e) => handelSearch(e.target.value)}
                onKeyPress={(e) => handelSearchEnter(e)}
              />
            </div>
            <button
              className="ml-3 Button PrimaryButton"
              data-toggle="modal"
              data-target="#AddMemberModal"
              onClick={handleShow}
            >
              <span>Add Company</span>
            </button>
          </div>
        </div>
        <div className="TabContent">
          {companyListLoader ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '400px',
              }}
            >
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <div className="DataViewTable">
              <div className="TableWrapper">
                <table className="DataTable">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>User Count</th>
                      <th>Status</th>
                      <th style={{ textAlign: 'center' }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {companyData &&
                      companyData.length > 0 &&
                      companyData.map((p, index) => (
                        <CompanyList
                          handleEditModal={handleEditModal}
                          key={index}
                          handleMembersModal={handleMembersModal}
                          companyData={p}
                        />
                      ))}
                    {companyData && companyData.length === 0 && (
                      <>
                        <tr
                          style={{ textAlign: 'center' }}
                          className="Deactivated"
                        >
                          <td colSpan="4">No data found</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              {total > 10 && (
                <div className="TableFooter">
                  <div className="TableFooterContent">
                    <form action="">
                      <div className="d-flex align-items-center">
                        <label htmlFor="ChooseNumber">Show</label>
                        <div className="CustomSelectNumber">
                          <select
                            className="custom-select"
                            id="ChooseNumber"
                            onChange={(e) => handelPageSizeChange(e)}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                          </select>
                        </div>
                        <label htmlFor="ChooseNumber">Entires</label>
                      </div>
                    </form>
                    <Pagination
                      className="PaginationList ml-auto"
                      total={total}
                      current={currentPage}
                      onChange={(e) => handelPageChange(e)}
                      showTitle={false}
                      pageSize={perPage}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          size="xl"
          centered
          backdrop="static"
        >
          <Modal.Header bsPrefix="ModalHeader">
            <Modal.Title bsPrefix="modal-title">
              {editCompany ? 'Update Comapny' : 'Add Comapny'}
            </Modal.Title>
            <button
              type="button"
              className="close ml-auto"
              data-dismiss="modal"
              onClick={handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="insight wpi-close" />
              </span>
            </button>
          </Modal.Header>
          <Modal.Body bsPrefix="ModalBody">
            <CompanyModal
              name={name}
              setName={setName}
              err={err}
              submitCompany={submitCompany}
              companyStatus={companyStatus}
              setCompanyStatus={setCompanyStatus}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={membersModalShow}
          onHide={handleMembersModalClose}
          size="xl"
          centered
          backdrop="static"
        >
          <Modal.Header bsPrefix="ModalHeader">
            <Modal.Title bsPrefix="modal-title">{name}</Modal.Title>
            <button
              type="button"
              className="close ml-auto"
              data-dismiss="modal"
              onClick={handleMembersModalClose}
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="insight wpi-close" />
              </span>
            </button>
          </Modal.Header>
          <Modal.Body bsPrefix="ModalBody">
            <CompanyMembersModal
              companyTeamMembers={companyTeamMembers}
              companyOwner={companyOwner}
              allUserList={allUserList}
              setCompanyOwner={setCompanyOwner}
              submitCompanyMembers={submitCompanyMembers}
            />
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default SuperAdminCompanies;
