import React from 'react';
import { connect } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';

const SubscriptionModal = ({
  modalLodar,
  err,
  subscriptionPermissions,
  packageSelectoptions,
  subscriptionSlug,
  handelSubscriptionPackageSelect,
  subscriptionPrice,
  setSubscriptionPrice,
  handelPermissionValueChange,
  submitUserSubscription,
  errSubcription,
}) => (
  <>
    <div className="ModalTabContent PackageModalContent">
      <div className="row HasColGap">
        <div className="col-md-6">
          <div className="TabContentRow">
            <label>Package*</label>
            <Select
              className="SelectOption basic-single"
              classNamePrefix="react-select"
              isSearchable={false}
              onChange={(e) => handelSubscriptionPackageSelect(e)}
              options={packageSelectoptions}
              value={packageSelectoptions.find(
                (op) => op.value === subscriptionSlug
              )}
            />
          </div>
          {errSubcription &&
            errSubcription.slug &&
            errSubcription.slug.length > 0 && (
              <div className="TabContentRow">
                <label />
                <p style={{ color: 'red' }} key={subscriptionSlug}>
                  {errSubcription.slug}
                </p>
              </div>
            )}
        </div>
        <div className="col-md-6">
          <div className="TabContentRow">
            <label>Price*</label>
            <input
              type="number"
              className="form-control"
              placeholder="$00.00"
              value={subscriptionPrice}
              onChange={(e) => setSubscriptionPrice(e.target.value)}
            />
          </div>
          {errSubcription &&
            errSubcription.price &&
            errSubcription.price.length > 0 && (
              <div className="TabContentRow">
                <label />
                <p style={{ color: 'red' }} key={subscriptionPrice}>
                  {errSubcription.price}
                </p>
              </div>
            )}
        </div>
      </div>
      {/* <div className="SectionTitleWrap mt-4">
          <h4 className="SectionTitle">Permission</h4>
          <div className="ml-auto">
            <button className="ActionButton PrimaryButton">
              <div className="Icon">
                <i className="insight wpi-plus"></i>
              </div>
            </button>
          </div>
        </div> */}
      <div className="SectionTitleWrap mt-4">
        <h4 className="SectionTitle">Permission</h4>
      </div>
      {errSubcription &&
        errSubcription.permission &&
        errSubcription.permission.length > 0 && (
          <p style={{ color: 'red' }} key={1}>
            {errSubcription.permission}
          </p>
        )}
      <div className="RemovablePermissionGroup">
        {subscriptionPermissions &&
          subscriptionPermissions.length > 0 &&
          subscriptionPermissions.map((ps, index) => (
            <div key={index} className="RemovablePermission">
              <div className="RemovablePermissionInner">
                <div className="row">
                  <div className="col-lg-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={ps.name}
                      // onChange={handelPermissionNameChange}

                      readOnly
                    />
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Value"
                      value={ps.value}
                      onChange={(e) => handelPermissionValueChange(e, index)}
                    />
                  </div>
                </div>
              </div>
              {/* <button className="CloseButton DangerButton ml-3">
                    <div className="Icon">
                      <i className="insight wpi-close"></i>
                    </div>
                  </button> */}
            </div>
          ))}
      </div>
    </div>
    <div className="d-flex">
      <button
        className="Button PrimaryButton ml-auto"
        onClick={(e) => submitUserSubscription(e)}
      >
        <span>
          {modalLodar ? <Spinner animation="border" variant="light" /> : 'Save'}
        </span>
      </button>
    </div>
  </>
);

const mapStateToProps = (state) => ({
  modalLodar: state.superAdminUser.modalLodar,
});

export default connect(mapStateToProps)(SubscriptionModal);
