import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Pagination from 'rc-pagination';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';
import ProductList from './ProductList';

import BasicInfoModal from './BasicInfoModal';
import MetaInfoModal from './MetaInfoModal';
import MarketingInfoModal from './MarketingInfoModal';
import nodataImage from '../../assets/img/no_data_full.svg';

const Product = ({
  history,
  allProducts,
  productlist,
  userCount,
  productObj,
  requestSuccess,
  currentPage,
  total,
  updateProduct,
  deleteProduct,
  resetRequestSuccess,
  validationError,
  loading,
  accountType,
  activeProfile,
  mailChimpList,
  productlistLoader,
  modalLoader,
  findMailChimpList,
  accessRemoved,
  resetAccessRemoved,
  resetCompanyNotFound,
  companyNotFound,
  rolePermissionInfo,
}) => {
  const [userRole, setUserRole] = useState('');
  const [userPermission, setUserPermission] = useState([]);

  const [perPage, setPerPage] = useState(10);
  const [show, setShow] = useState(false);
  const [submitProductBtn, setSubmitProductBtn] = useState(false);
  const [search, setSearch] = useState(null);
  // const [requestDone, setRequestDone] = useState(false);
  const [productUpdate, setProductUpdate] = useState(false);
  const [modalStep, setmodalStep] = useState(1);
  // const [selectedProduct, setSelectedProduct] = useState(null);

  const [firstTimeMailChimpCheck, setFirstTimeMailChimpCheck] = useState(true);

  const [productData, setProductData] = useState([]);

  const [productId, setProductId] = useState('');
  const [productName, setProductName] = useState('');
  const [productBaseName, setProductBaseName] = useState('');
  const [productVersion, setProductVersion] = useState('');
  const [productHomeUrl, setProductHomeUrl] = useState('');
  const [productDemoUrl, setProductDemoUrl] = useState('');
  const [productStatus, setProductStatus] = useState({
    value: '',
    label: 'select',
  });
  const [productDescription, setProductDescription] = useState('');

  const [productPhpRequiredVersion, setProductPhpRequiredVersion] =
    useState('');
  const [productPhpTestedUpto, setProductPhpTestedUpto] = useState('');
  const [productWordpressRequiredVersion, setProductWordpressRequiredVersion] =
    useState('');
  const [productWordpressTestedUpto, setProductWordpressTestedUpto] =
    useState('');

  const [productMailingList, setProductMailingList] = useState({
    value: '',
    label: 'select',
  });
  const [productTrackingOption, setProductTrackingOption] = useState({
    value: '',
    label: 'select',
  });
  const [productAskTrackingPermission, setProductAskTrackingPermission] =
    useState({ value: '', label: 'select' });
  const [productEnableDeactivationForm, setProductEnableDeactivationForm] =
    useState({ value: '', label: 'select' });
  const [
    productChooseNotificationFormate,
    setProductChooseNotificationFormate,
  ] = useState({ value: '', label: 'select' });
  const [hasErr, setHasErr] = useState(false);
  const [basicTabClick, setBasicTabClick] = useState(false);
  const [metaTabClick, setMetaTabClick] = useState(false);
  const [marketingTabClick, setMarketingTabClick] = useState(false);
  const [err, setErr] = useState({
    productName: [],
    productBaseName: [],
    productVersion: [],
    productHomeUrl: [],
    productDemoUrl: [],
    productStatus: [],
    productDescription: [],
    productPhpRequiredVersion: [],
    productPhpTestedUpto: [],
    productWordpressRequiredVersion: [],
    productWordpressTestedUpto: [],
    productMailingList: [],
    productTrackingOption: [],
    productAskTrackingPermission: [],
    productEnableDeactivationForm: [],
    productChooseNotificationFormate: [],
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [confirmBox, setConfirmBox] = useState(false);
  const [confirmBoxTitle, setConfirmBoxTitle] = useState('');
  const handelConfirmBoxClose = () => setConfirmBox(false);

  /* eslint-disable */
  useEffect(() => {
    if (accessRemoved) {
      resetAccessRemoved();
      history.push('/user/dashboard');
    }
  }, [accessRemoved]);

  useEffect(() => {
    if (companyNotFound) {
      resetCompanyNotFound();
      history.push('/auth/create-company-form');
    }
  }, [companyNotFound]);

  const checkCompany = () => {
    return Object.keys(activeProfile).length > 0;
  };

  // useEffect(() => {
  //   const check = checkCompany();
  //   if (!check) {
  //     toast.error('Company not found, Please provide a Company first');
  //     return;
  //   }
  //   allProducts({
  //     page: 1,
  //     per_page: perPage,
  //     account_type: accountType,
  //     company_id: activeProfile.id,
  //   });
  // }, []);

  useEffect(() => {
    if (rolePermissionInfo && rolePermissionInfo.length > 0) {
      setUserRole(rolePermissionInfo[0].name);
      let permissions = rolePermissionInfo[0].permissions;
      if (permissions && permissions.length > 0) {
        let role_permission = rolePermissionInfo[0].permissions[0].permission;
        let newArray = [];
        role_permission.map((p) => {
          if (p[0] === 'Products') {
            p[1].map((r) => {
              newArray.push(r);
            });
          }
        });
        setUserPermission(newArray);
      }
    }
  }, []);

  useEffect(() => {
    requestSuccess && setShow(false);
    requestSuccess && setConfirmBox(false);
    requestSuccess &&
      allProducts({
        page: 1,
        per_page: perPage,
        account_type: accountType,
        company_id: activeProfile.id,
      });
  }, [requestSuccess]);

  useEffect(() => {
    !confirmBox && setProductId('');
  }, [confirmBox]);

  useEffect(() => {
    show &&
      modalStep &&
      modalStep === 3 &&
      firstTimeMailChimpCheck &&
      findMailChimpList({
        account_type: accountType,
        company_id: activeProfile.id,
      });
    show &&
      modalStep &&
      modalStep === 3 &&
      firstTimeMailChimpCheck &&
      setFirstTimeMailChimpCheck(false);
  }, [modalStep]);

  useEffect(() => {
    !show && setProductUpdate(false);
    !show && setmodalStep(1);
    !show &&
      setErr({
        productName: [],
        productBaseName: [],
        productVersion: [],
        productHomeUrl: [],
        productDemoUrl: [],
        productStatus: [],
        productDescription: [],
        productPhpRequiredVersion: [],
        productPhpTestedUpto: [],
        productWordpressRequiredVersion: [],
        productWordpressTestedUpto: [],
        productMailingList: [],
        productTrackingOption: [],
        productAskTrackingPermission: [],
        productEnableDeactivationForm: [],
        productChooseNotificationFormate: [],
      });

    !show && setProductId('');
    !show && setProductName('');
    !show && setProductBaseName('');
    !show && setProductVersion('');
    !show && setProductHomeUrl('');
    !show && setProductDemoUrl('');
    !show && setProductStatus('');
    !show && setProductDescription('');
    !show && setProductPhpRequiredVersion('');
    !show && setProductPhpTestedUpto('');
    !show && setProductWordpressRequiredVersion('');
    !show && setProductWordpressTestedUpto('');
    !show &&
      setProductMailingList({
        value: '',
        label: 'select',
      });
    !show &&
      setProductTrackingOption({
        value: '',
        label: 'select',
      });
    !show &&
      setProductAskTrackingPermission({
        value: '',
        label: 'select',
      });
    !show &&
      setProductEnableDeactivationForm({
        value: '',
        label: 'select',
      });
    !show &&
      setProductChooseNotificationFormate({
        value: '',
        label: 'select',
      });

    !show && setMetaTabClick(false);
    !show && setMarketingTabClick(false);

    !show && resetRequestSuccess();

    !show && setFirstTimeMailChimpCheck(true);
    !show && setSubmitProductBtn(false);
  }, [show]);

  // useEffect(() => {
  //   if (search === '') {
  //     const check = checkCompany();
  //     if (!check) {
  //       return;
  //     }
  //     allProducts({
  //       per_page: perPage,
  //       account_type: accountType,
  //       company_id: activeProfile.id,
  //     });
  //   }
  // }, [search]);

  useEffect(() => {
    const check = checkCompany();
    if (!check) {
      toast.error('Company not found, Please provide a Company first');
      return;
    }

    allProducts({
      per_page: perPage,
      search: search && search.length > 0 ? search : null,
      account_type: accountType,
      company_id: activeProfile.id,
    });
  }, [perPage]);

  useEffect(() => {
    if (productlist.length > 0) {
      var dataArray = productlist.map((Product) => {
        var newArray = {};
        newArray.productName = Product.base_name;
        newArray.productBaseName = Product.base_name_slug;
        newArray.productEnableDeactivationForm =
          Product.marketing_info.deactivate_form;
        newArray.productDemoUrl = Product.demo_url;
        newArray.productDescription = Product.description;
        newArray.productHomeUrl = Product.home_url;
        newArray.id = Product.id;
        newArray.productMailingList = Product.mailchimp_list_id;
        newArray.productChooseNotificationFormate =
          Product.marketing_info.notification_format;
        newArray.productTrackingOption =
          Product.marketing_info.options_to_track;
        newArray.productPhpRequiredVersion = Product.meta_info.php_version;
        newArray.productAskTrackingPermission =
          Product.marketing_info.require_opt_in;
        newArray.productStatus = Product.status;
        newArray.productPhpTestedUpto = Product.meta_info.tested_php_version;
        newArray.productVersion = Product.version_no;
        newArray.productWordpressRequiredVersion = Product.meta_info.wp_version;
        newArray.productWordpressTestedUpto = Product.meta_info.tested_version;
        newArray.ActiveSites = Product.ActiveSites;
        newArray.DeactivatedSites = Product.DeactivatedSites;
        return newArray;
      });
      setProductData(dataArray);
    } else {
      setProductData([]);
    }
  }, [productlist]);

  useEffect(() => {
    if (validationError.length > 0) {
      setmodalStep(1);
      let NameErr = [];
      let baseNameErr = [];
      let AskTrackingPermissionErr = [];
      let enableDeactivationFormErr = [];
      let chooseNotificationFormate = [];
      let versionErr = [];

      validationError[0].map((err) => {
        if (err.param === 'base_name') {
          NameErr.push(err.msg);
        }
        if (err.param === 'base_name_slug') {
          baseNameErr.push(err.msg);
        }
        if (err.param === 'require_opt_in') {
          AskTrackingPermissionErr.push(err.msg);
        }
        if (err.param === 'deactivate_form') {
          enableDeactivationFormErr.push(err.msg);
        }
        if (err.param === 'notification_format') {
          chooseNotificationFormate.push(err.msg);
        }
        if (err.param === 'version_no') {
          versionErr.push(err.msg);
        }
      });

      setErr({
        ...err,
        productName: NameErr,
        productBaseName: baseNameErr,
        productAskTrackingPermission: AskTrackingPermissionErr,
        productEnableDeactivationForm: enableDeactivationFormErr,
        productChooseNotificationFormate: chooseNotificationFormate,
        productVersion: versionErr,
      });
    } else {
      setErr({
        productName: [],
        productBaseName: [],
        productVersion: [],
        productHomeUrl: [],
        productDemoUrl: [],
        productStatus: [],
        productDescription: [],
        productPhpRequiredVersion: [],
        productPhpTestedUpto: [],
        productWordpressRequiredVersion: [],
        productWordpressTestedUpto: [],
        productMailingList: [],
        productTrackingOption: [],
        productAskTrackingPermission: [],
        productEnableDeactivationForm: [],
        productChooseNotificationFormate: [],
      });
    }
  }, [validationError]);
  /* eslint-enable */

  const handleBasicInfo = () => {
    const errors = validateBasicInfo();
    if (errors.length > 0) {
    } else {
      setmodalStep(2);
      setBasicTabClick(true);
      setMetaTabClick(true);
    }
  };
  const handelMetaInfo = () => {
    const errors = validateMetaInfo();
    if (errors.length > 0) {
    } else {
      setmodalStep(3);
      setMetaTabClick(true);
      setMarketingTabClick(true);
    }
  };
  const handleMarketingInfo = () => {
    setmodalStep(1);
  };

  const validateBasicInfo = () => {
    const errors = [];

    const productNameErr = [];
    const productBaseNameErr = [];
    const productVersionErr = [];
    const productHomeUrlErr = [];
    const productDemoUrlErr = [];
    const productStatusErr = [];

    if (productName === '') {
      productNameErr.push("Product name can't be empty");
      errors.push("Product name can't be empty");
    }

    if (productName.length > 255) {
      productNameErr.push("Product name can't be over 255 charecter");
      errors.push("Product name can't be over 255 charecter");
    }

    if (productBaseName === '') {
      productBaseNameErr.push("Product base name can't be empty");
      errors.push("Product base name can't be empty");
    }

    if (productBaseName && productBaseName.length > 255) {
      productBaseNameErr.push("Product base name can't be over 255 charecter");
      errors.push("Product base name can't be over 255 charecter");
    }

    if (productHomeUrl === '') {
      productHomeUrlErr.push("Product home url can't be empty");
      errors.push("Product home url can't be empty");
    }

    if (productHomeUrl.length > 255) {
      productHomeUrlErr.push("Product home url can't be over 255 charecter");
      errors.push("Product home url can't be over 255 charecter");
    }

    if (productDemoUrl && productDemoUrl.length > 255) {
      productDemoUrlErr.push("Product demo url can't be over 255 charecter");
      errors.push("Product demo url can't be over 255 charecter");
    }

    if (productVersion === '') {
      productVersionErr.push("Version number can't be empty");
      errors.push("Version number can't be empty");
    }

    if (productVersion.length > 100) {
      productVersionErr.push("Version number can't be over 100 charecter");
      errors.push("Version number can't be over 100 charecter");
    }

    if (productStatus && productStatus.value === '') {
      productStatusErr.push('please select a option');
      errors.push('please select a option');
    }

    if (!productStatus) {
      productStatusErr.push('please select a option');
      errors.push('please select a option');
    }

    if (errors.length > 0) {
      setErr({
        productName: productNameErr,
        productBaseName: productBaseNameErr,
        productVersion: productVersionErr,
        productHomeUrl: productHomeUrlErr,
        productDemoUrl: productDemoUrlErr,
        productStatus: productStatusErr,
        productDescription: [],
      });
      setHasErr(true);
    }

    return errors;
  };

  const validateMetaInfo = () => {
    const errors = [];

    const productPhpRequiredVersionErr = [];
    const productPhpTestedUptoErr = [];
    const productWordpressRequiredVersionErr = [];
    const productWordpressTestedUptoErr = [];

    if (!productPhpRequiredVersion || productPhpRequiredVersion === '') {
      productPhpRequiredVersionErr.push("PHP required verion can't be empty");
      errors.push("PHP required verion can't be empty");
    }

    if (productPhpRequiredVersion && productPhpRequiredVersion.length > 100) {
      productPhpRequiredVersionErr.push(
        "PHP required verion can't be over 100 charecter"
      );
      errors.push("PHP required verion can't be over 100 charecter");
    }

    if (!productPhpTestedUpto || productPhpTestedUpto === '') {
      productPhpTestedUptoErr.push("PHP tested upto verion can't be empty");
      errors.push("PHP tested upto verion can't be empty");
    }

    if (productPhpTestedUpto && productPhpTestedUpto.length > 100) {
      productPhpTestedUptoErr.push(
        "PHP tested upto verion can't be over 100 charecter"
      );
      errors.push("PHP tested upto verion can't be over 100 charecter");
    }

    if (
      !productWordpressRequiredVersion ||
      productWordpressRequiredVersion === ''
    ) {
      productWordpressRequiredVersionErr.push(
        "WordPress required verion can't be empty"
      );
      errors.push("WordPress required verion can't be empty");
    }

    if (
      productWordpressRequiredVersion &&
      productWordpressRequiredVersion.length > 100
    ) {
      productWordpressRequiredVersionErr.push(
        "WordPress required verion can't be over 100 charecter"
      );
      errors.push("WordPress required verion can't be over 100 charecter");
    }

    if (!productWordpressTestedUpto || productWordpressTestedUpto === '') {
      productWordpressTestedUptoErr.push(
        "WordPress tested upto verion can't be empty"
      );
      errors.push("WordPress tested upto verion can't be empty");
    }

    if (productWordpressTestedUpto && productWordpressTestedUpto.length > 100) {
      productWordpressTestedUptoErr.push(
        "WordPress tested upto verion can't be over 100 charecter"
      );
      errors.push("WordPress tested upto verion can't be over 100 charecter");
    }

    if (errors.length > 0) {
      setErr({
        productPhpRequiredVersion: productPhpRequiredVersionErr,
        productPhpTestedUpto: productPhpTestedUptoErr,
        productWordpressRequiredVersion: productWordpressRequiredVersionErr,
        productWordpressTestedUpto: productWordpressTestedUptoErr,
      });
      setHasErr(true);
    }
    return errors;
  };
  const validateMarketingInfo = () => {
    const errors = [];

    const productTrackingOptionErr = [];
    const productAskTrackingPermissionErr = [];
    const productEnableDeactivationFormErr = [];
    const productChooseNotificationFormateErr = [];

    if (productTrackingOption && productTrackingOption.value === '') {
      productTrackingOptionErr.push('please select a option');
      errors.push('please select a option');
    }

    if (!productTrackingOption) {
      productTrackingOptionErr.push('please select a option');
      errors.push('please select a option');
    }

    if (
      productAskTrackingPermission &&
      productAskTrackingPermission.value === ''
    ) {
      productAskTrackingPermissionErr.push('please select a option');
      errors.push('please select a option');
    }

    if (!productAskTrackingPermission) {
      productAskTrackingPermissionErr.push('please select a option');
      errors.push('please select a option');
    }

    if (
      productEnableDeactivationForm &&
      productEnableDeactivationForm.value === ''
    ) {
      productEnableDeactivationFormErr.push('please select a option');
      errors.push('please select a option');
    }

    if (!productEnableDeactivationForm) {
      productEnableDeactivationFormErr.push('please select a option');
      errors.push('please select a option');
    }

    if (
      productChooseNotificationFormate &&
      productChooseNotificationFormate.value === ''
    ) {
      productChooseNotificationFormateErr.push('please select a option');
      errors.push('please select a option');
    }

    if (!productChooseNotificationFormate) {
      productChooseNotificationFormateErr.push('please select a option');
      errors.push('please select a option');
    }

    if (errors.length > 0) {
      setErr({
        productMailingList: [],
        productTrackingOption: productTrackingOptionErr,
        productAskTrackingPermission: productAskTrackingPermissionErr,
        productEnableDeactivationForm: productEnableDeactivationFormErr,
        productChooseNotificationFormate: productChooseNotificationFormateErr,
      });
      setHasErr(true);
    }
    return errors;
  };

  const submitProduct = () => {
    // role check
    if (userRole === 'Demo_User') {
      toast.warn('This operation not possible in demo account');
      return;
    }
    const errors = validateMarketingInfo();
    if (errors.length > 0) {
    } else {
      setSubmitProductBtn(true);
      if (productUpdate) {
        updateProduct({
          id: productId,
          base_name: productName,
          base_name_slug: productBaseName,
          version_no: productVersion,
          home_url: productHomeUrl,
          demo_url: productDemoUrl,
          status: productStatus.value,
          description: productDescription,
          php_version: productPhpRequiredVersion,
          tested_php_version: productPhpTestedUpto,
          wp_version: productWordpressRequiredVersion,
          tested_version: productWordpressTestedUpto,
          mailchimp_list_id: productMailingList.value,
          options_to_track: productTrackingOption.value,
          require_opt_in: productAskTrackingPermission.value,
          deactivate_form: productEnableDeactivationForm.value,
          notification_format: productChooseNotificationFormate.value,
          account_type: accountType,
          company_id: activeProfile.id,
        });
      } else {
        productObj({
          base_name: productName,
          base_name_slug: productBaseName,
          version_no: productVersion,
          home_url: productHomeUrl,
          demo_url: productDemoUrl,
          status: productStatus.value,
          description: productDescription,
          php_version: productPhpRequiredVersion,
          tested_php_version: productPhpTestedUpto,
          wp_version: productWordpressRequiredVersion,
          tested_version: productWordpressTestedUpto,
          mailchimp_list_id: productMailingList.value,
          options_to_track: productTrackingOption.value,
          require_opt_in: productAskTrackingPermission.value,
          deactivate_form: productEnableDeactivationForm.value,
          notification_format: productChooseNotificationFormate.value,
          account_type: accountType,
          company_id: activeProfile.id,
        });
      }
    }
  };

  const handelDeleteProduct = (id) => {
    setConfirmBox(true);
    setConfirmBoxTitle('Are you sure to remove this product ?');
    setProductId(id);
  };

  const handelProductDelete = (e) => {
    // role check
    if (userRole === 'Demo_User') {
      toast.warn('This operation not possible in demo account');
      return;
    }
    deleteProduct({
      id: productId,
      account_type: accountType,
      company_id: activeProfile.id,
    });
  };

  const handelPageChange = (e) => {
    allProducts({
      page: e,
      search: search && search.length > 0 ? search : null,
      per_page: perPage,
      account_type: accountType,
      company_id: activeProfile.id,
    });
  };

  const handleEditModal = (id) => {
    productData
      .filter((data) => data.id === id)
      .forEach((p) => {
        setProductId(p.id);
        setProductName(p.productName);
        setProductBaseName(p.productBaseName);
        setProductVersion(p.productVersion);
        setProductHomeUrl(p.productHomeUrl);
        setProductDemoUrl(p.productDemoUrl);
        setProductStatus({
          value: p.productStatus,
          label: p.productStatus,
        });
        setProductDescription(p.productDescription);
        setProductPhpRequiredVersion(p.productPhpRequiredVersion);
        setProductPhpTestedUpto(p.productPhpTestedUpto);
        setProductWordpressRequiredVersion(p.productWordpressRequiredVersion);
        setProductWordpressTestedUpto(p.productWordpressTestedUpto);
        setProductMailingList({
          value: p.productMailingList,
          label: p.productMailingList,
        });
        setProductTrackingOption({
          value: p.productTrackingOption,
          label: p.productTrackingOption,
        });
        setProductAskTrackingPermission({
          value: p.productAskTrackingPermission,
          label: p.productAskTrackingPermission,
        });
        setProductEnableDeactivationForm({
          value: p.productEnableDeactivationForm,
          label: p.productEnableDeactivationForm,
        });
        setProductChooseNotificationFormate({
          value: p.productChooseNotificationFormate,
          label: p.productChooseNotificationFormate,
        });
      });
    setShow(true);
    setProductUpdate(true);
    setBasicTabClick(true);
    setMetaTabClick(true);
    setMarketingTabClick(true);
  };

  const handelProductView = (baseNameSlug) => {
    history.push({
      pathname: '/user/product-details',
      search: `?product=${baseNameSlug}`,
    });
  };
  // const goToMailChimpApiKey = (baseNameSlug) => {
  //   history.push({
  //     pathname: '/user/settings',
  //     search: '?subMenu=MailChimpApi',
  //   });
  // };
  /* eslint-disable */

  /* eslint-enable */
  const handelSearch = (e) => {
    if (e.key === 'Enter') {
      const check = checkCompany();
      if (!check) {
        return;
      }
      allProducts({
        per_page: perPage,
        search,
        account_type: accountType,
        company_id: activeProfile.id,
      });
    }
  };
  const handelCreateProductModal = (e) => {
    e.preventDefault();
    if (Object.keys(activeProfile).length <= 0) {
      toast.error('Company not found, Please provide a Company first');
      return;
    }
    handleShow();
  };
  const setSearchChange = (searchValue) => {
    setSearch(searchValue);
    if (searchValue === '') {
      const check = checkCompany();
      if (!check) {
        return;
      }
      allProducts({
        per_page: perPage,
        account_type: accountType,
        company_id: activeProfile.id,
      });
    }
  };
  return (
    <>
      <section className="DataViewSection SectionGap">
        <div className="DataViewHeading">
          <div className="DataViewHeadingLeftContent">
            <div className="SectionTitle">Product List</div>
          </div>
          <div className="DataViewHeadingRightContent">
            <div className="SearchForm">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={(e) => setSearchChange(e.target.value)}
                onKeyPress={handelSearch}
              />
            </div>
            {accountType === 'Partner' ? (
              <button
                className="ml-3 Button PrimaryButton"
                data-toggle="modal"
                data-target="#CreateProductModal"
                onClick={(e) => handelCreateProductModal(e)}
              >
                <span>Create Product</span>
              </button>
            ) : (
              userPermission
                .filter((p) => p.name === 'add-product' && p.checked)
                .map((b) => (
                  <button
                    className="ml-3 Button PrimaryButton"
                    data-toggle="modal"
                    data-target="#CreateProductModal"
                    onClick={(e) => handelCreateProductModal(e)}
                  >
                    <span>Create Product</span>
                  </button>
                ))
            )}
          </div>
        </div>
        {productlistLoader ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '400px',
            }}
          >
            <Spinner animation="border" variant="dark" />
          </div>
        ) : (
          <div className="DataViewTable">
            <div className="TableWrapper">
              <table className="DataTable">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Active User</th>
                    <th>Deactivated User</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!productlistLoader &&
                  productData &&
                  productData.length > 0 ? (
                    productData.map((p, index) => (
                      <ProductList
                        userPermission={userPermission}
                        handleEditModal={handleEditModal}
                        handelDeleteProduct={handelDeleteProduct}
                        key={index}
                        // selectedProduct={selectedProduct}
                        handelProductView={handelProductView}
                        productData={p}
                        userCount={userCount}
                        accountType={accountType}
                      />
                    ))
                  ) : (
                    <tr style={{ textAlign: 'center' }} className="Deactivated">
                      <td colSpan="6">
                        <img
                          src={nodataImage}
                          style={{ height: '600px' }}
                          alt=""
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="TableFooter">
              <div className="TableFooterContent">
                {total > 10 && (
                  <>
                    <form action="">
                      <div className="d-flex align-items-center">
                        <label htmlFor="ChooseNumber">Show</label>
                        <div className="CustomSelectNumber">
                          <select
                            onChange={(e) => setPerPage(e.target.value)}
                            className="custom-select"
                            id="ChooseNumber"
                          >
                            <option
                              selected={perPage && perPage === '10'}
                              value="10"
                            >
                              10
                            </option>
                            <option
                              selected={perPage && perPage === '20'}
                              value="20"
                            >
                              20
                            </option>
                            <option
                              selected={perPage && perPage === '30'}
                              value="30"
                            >
                              30
                            </option>
                            <option
                              selected={perPage && perPage === '40'}
                              value="40"
                            >
                              40
                            </option>
                          </select>
                        </div>
                        <label htmlFor="ChooseNumber">Entires</label>
                      </div>
                    </form>

                    <Pagination
                      className="PaginationList ml-auto"
                      total={total}
                      current={currentPage}
                      onChange={(e) => handelPageChange(e)}
                      showTitle={false}
                      pageSize={perPage}
                      showPrevNextJumpers={false}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        <Modal
          show={show}
          onHide={handleClose}
          size="xl"
          centered
          backdrop="static"
        >
          <Modal.Header bsPrefix="ModalHeader">
            <Modal.Title bsPrefix="modal-title">
              {!productUpdate ? 'Add New Product' : 'Update Product'}
            </Modal.Title>
            <button
              type="button"
              className="close ml-auto"
              data-dismiss="modal"
              onClick={handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="insight wpi-close" />
              </span>
            </button>
          </Modal.Header>
          <Modal.Body bsPrefix="ModalBody">
            {modalStep === 1 && (
              <BasicInfoModal
                handleBasicInfo={handleBasicInfo}
                err={err}
                hasErr={hasErr}
                productUpdate={productUpdate}
                productName={productName}
                productBaseName={productBaseName}
                productVersion={productVersion}
                productHomeUrl={productHomeUrl}
                productDemoUrl={productDemoUrl}
                productStatus={productStatus}
                productDescription={productDescription}
                setProductName={setProductName}
                setProductBaseName={setProductBaseName}
                setProductVersion={setProductVersion}
                setProductHomeUrl={setProductHomeUrl}
                setProductDemoUrl={setProductDemoUrl}
                setProductStatus={setProductStatus}
                setProductDescription={setProductDescription}
                setmodalStep={setmodalStep}
                basicTabClick={basicTabClick}
                setBasicTabClick={setBasicTabClick}
                metaTabClick={metaTabClick}
                setMetaTabClick={setMetaTabClick}
                marketingTabClick={marketingTabClick}
                setMarketingTabClick={setMarketingTabClick}
              />
            )}
            {modalStep === 2 && (
              <MetaInfoModal
                setmodalStep={setmodalStep}
                handelMetaInfo={handelMetaInfo}
                productPhpRequiredVersion={productPhpRequiredVersion}
                productPhpTestedUpto={productPhpTestedUpto}
                productWordpressRequiredVersion={
                  productWordpressRequiredVersion
                }
                productWordpressTestedUpto={productWordpressTestedUpto}
                setProductPhpRequiredVersion={setProductPhpRequiredVersion}
                setProductPhpTestedUpto={setProductPhpTestedUpto}
                setProductWordpressRequiredVersion={
                  setProductWordpressRequiredVersion
                }
                setProductWordpressTestedUpto={setProductWordpressTestedUpto}
                err={err}
                hasErr={hasErr}
                basicTabClick={basicTabClick}
                setBasicTabClick={setBasicTabClick}
                metaTabClick={metaTabClick}
                setMetaTabClick={setMetaTabClick}
                marketingTabClick={marketingTabClick}
                setMarketingTabClick={setMarketingTabClick}
              />
            )}
            {modalStep === 3 && (
              <MarketingInfoModal
                handleMarketingInfo={handleMarketingInfo}
                setProductUpdate={setProductUpdate}
                productUpdate={productUpdate}
                setmodalStep={setmodalStep}
                submitProduct={submitProduct}
                loading={loading}
                productMailingList={productMailingList}
                productTrackingOption={productTrackingOption}
                productAskTrackingPermission={productAskTrackingPermission}
                productEnableDeactivationForm={productEnableDeactivationForm}
                productChooseNotificationFormate={
                  productChooseNotificationFormate
                }
                setProductMailingList={setProductMailingList}
                setProductTrackingOption={setProductTrackingOption}
                setProductAskTrackingPermission={
                  setProductAskTrackingPermission
                }
                setProductEnableDeactivationForm={
                  setProductEnableDeactivationForm
                }
                setProductChooseNotificationFormate={
                  setProductChooseNotificationFormate
                }
                err={err}
                hasErr={hasErr}
                basicTabClick={basicTabClick}
                setBasicTabClick={setBasicTabClick}
                metaTabClick={metaTabClick}
                setMetaTabClick={setMetaTabClick}
                marketingTabClick={marketingTabClick}
                setMarketingTabClick={setMarketingTabClick}
                mailChimpList={mailChimpList}
                modalLoader={modalLoader}
                submitProductBtn={submitProductBtn}
              />
            )}
          </Modal.Body>
        </Modal>
        <Modal
          show={confirmBox}
          onHide={handelConfirmBoxClose}
          size="lg"
          centered
          backdrop="static"
        >
          <Modal.Header bsPrefix="ModalHeader">
            <Modal.Title bsPrefix="modal-title">
              Delete Confirmation
            </Modal.Title>
            <button
              type="button"
              className="close ml-auto"
              data-dismiss="modal"
              onClick={handelConfirmBoxClose}
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="insight wpi-close" />
              </span>
            </button>
          </Modal.Header>
          <Modal.Body bsPrefix="ModalBody">
            <>
              <h4
                style={{
                  textAlign: 'center',
                  marginBottom: '20px',
                }}
              >
                {confirmBoxTitle}
              </h4>

              <p
                className="ActionButtonGroup"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 'auto',
                  alignItems: 'center',
                }}
              >
                <button
                  onClick={(e) => handelProductDelete()}
                  className="Button SuccessButton"
                  title="Yes"
                >
                  <span>
                    {modalLoader ? (
                      <Spinner animation="border" variant="light" />
                    ) : (
                      'Yes'
                    )}
                  </span>
                </button>
                <button
                  className="Button DangerButton"
                  title="No"
                  disabled={modalLoader}
                  onClick={(e) => {
                    !modalLoader && setConfirmBox(false);
                    !modalLoader && setConfirmBoxTitle('');
                    !modalLoader && setProductId('');
                  }}
                >
                  No
                </button>
              </p>
            </>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.util.loading,
  modalLoader: state.productList.modalLoader,
});

export default connect(mapStateToProps)(Product);
