import React, { useState, useEffect } from 'react';
import Pagination from 'rc-pagination';
import queryString from 'query-string';

import Modal from 'react-bootstrap/Modal';
import TeamMemberList from './TeamMemberList';
import UserModal from './UserModal';

/* eslint-disable */
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
/* eslint-enable */

const UserTeamMembers = ({
  history,
  teamMemberList,
  getAllUserTeamMembers,
  requestSuccess,
  total,
  updateUser,
  resetRequestSuccess,
  currentPage,
  validationError,
  mainUserData,
}) => {
  const [show, setShow] = useState(false);
  const perPage = 10;
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const [userData, setUserData] = useState([]);

  const [mainUserId, setMainUserId] = useState('');
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState('');
  const [status, setStatus] = useState({ label: 'select', value: '' });
  const [is_verified_status, setIs_verified_status] = useState({
    label: 'select',
    value: '',
  });
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [userName, setUserName] = useState('');
  const [editUser, setEditUser] = useState(false);

  const [err, setErr] = useState({
    name: [],
    email: [],
    userName: [],
    password: [],
    status: [],
    is_verified_status: [],
  });
  /* eslint-disable */
  useEffect(() => {
    const desiredUser = queryString.parse(location.search);
    setMainUserId(desiredUser.user);
    getAllUserTeamMembers({
      page: currentPage,
      per_page: perPage,
      userId: desiredUser.user,
    });
  }, []);
  useEffect(() => {
    mainUserId &&
      mainUserId !== '' &&
      getAllUserTeamMembers({
        page: currentPage,
        per_page: perPage,
        userId: mainUserId,
      });
  }, [mainUserId]);

  useEffect(() => {
    requestSuccess && setShow(false);
    requestSuccess &&
      getAllUserTeamMembers({
        page: currentPage,
        per_page: perPage,
        userId: mainUserId,
      });
  }, [requestSuccess]);

  useEffect(() => {
    !show && setEditUser(false);
    !show && resetRequestSuccess();
    !show && setEmail('');
    !show && setUserId('');
    !show && setStatus({ label: 'select', value: '' });
    !show && setIs_verified_status({ label: 'select', value: '' });
    !show && setPassword('');
    !show && setUserName('');
    !show && setName('');
    !show &&
      setErr({
        name: [],
        email: [],
        userName: [],
        password: [],
        status: [],
        is_verified_status: [],
      });
  }, [show]);

  useEffect(() => {
    if (teamMemberList.length > 0) {
      var dataArray = teamMemberList.map((Member) => {
        var newArray = {};
        newArray.name = Member.name;
        newArray.email = Member.email;
        newArray.username = Member.username;
        newArray.id = Member.id;
        newArray.status = Member.status;
        newArray.is_verified = Member.is_verified;
        newArray.image = '';
        return newArray;
      });
      setUserData(dataArray);
    } else {
      setUserData([]);
    }
  }, [teamMemberList]);
  useEffect(() => {
    if (validationError && validationError.length > 0) {
      var nameError = [];
      var emailError = [];
      var usernameError = [];
      var passwordError = [];
      var statusError = [];
      var is_verified_statusError = [];

      if (validationError[0].name) {
        nameError = validationError[0].name;
      }
      if (validationError[0].email) {
        emailError = validationError[0].email;
      }
      if (validationError[0].username) {
        usernameError = validationError[0].username;
      }
      if (validationError[0].password) {
        passwordError = validationError[0].password;
      }
      if (validationError[0].status_id) {
        statusError = validationError[0].status_id;
      }
      if (validationError[0].is_verified) {
        is_verified_statusError = validationError[0].is_verified;
      }

      setErr({
        ...err,
        name: nameError,
        email: emailError,
        userName: usernameError,
        password: passwordError,
        status: statusError,
        is_verified_status: is_verified_statusError,
      });
    } else {
      setErr({
        name: [],
        email: [],
        userName: [],
        password: [],
        status: [],
        is_verified_status: [],
      });
    }
  }, [validationError]);
  /* eslint-enable */

  const handleEditModal = (id) => {
    setEditUser(true);
    setShow(true);
    teamMemberList
      .filter((data) => data.id === id)
      .forEach((p) => {
        setEmail(p.email);
        setName(p.name);
        setUserName(p.username);
        setStatus({ label: 'select', value: p.status });
        setIs_verified_status({ label: 'select', value: p.is_verified });
        setPassword('');
        setUserId(id);
      });
  };
  const validate = () => {
    const errors = [];
    const nameError = [];
    const emailError = [];
    const usernameError = [];
    const passwordError = [];
    const statusError = [];
    const is_verified_statusError = [];

    if (email.length === 0) {
      emailError.push("Email can't be empty");
      errors.push("Email can't be empty");
    }

    // if (name.length === 0) {
    //   nameError.push("Name can't be empty");
    //   errors.push("Name can't be empty");
    // }

    // if (userName.length === 0) {
    //   usernameError.push("Username can't be empty");
    //   errors.push("Username can't be empty");
    // }

    if (!editUser && password.length === 0) {
      passwordError.push("Password can't be empty");
      errors.push("Password can't be empty");
    }

    if (email.length > 0 && !validEmailRegex.test(email)) {
      errors.push('Email is not valid');
      emailError.push('Email is not valid');
    }
    if (status && status.value === '') {
      errors.push('Please select a Status');
      statusError.push('Please select a Status');
    }

    if (!status) {
      errors.push('Please select a Status');
      statusError.push('Please select a Status');
    }

    if (!is_verified_status) {
      errors.push('Please select a verification Status');
      is_verified_statusError.push('Please select a verification Status');
    }

    if (is_verified_status && is_verified_status.value === '') {
      errors.push('Please select a verification Status');
      is_verified_statusError.push('Please select verification a Status');
    }

    if (errors.length > 0) {
      setErr({
        name: nameError,
        email: emailError,
        userName: usernameError,
        password: passwordError,
        status: statusError,
        is_verified_status: is_verified_statusError,
      });
    }
    return errors;
  };

  const submitUser = () => {
    const errors = validate();
    if (errors.length > 0) {
    } else if (editUser) {
      updateUser({
        name,
        email,
        username: userName,
        password,
        status_id: status.value,
        is_verified: is_verified_status.value,
        userId,
      });
    } else {
      // addUser({
      //   name: name,
      //   email: email,
      //   username: userName,
      //   password: password,
      //   status_id: status.value,
      //   is_verified: is_verified_status.value,
      // });
    }
  };

  const handelPageChange = (e) => {
    getAllUserTeamMembers({
      page: e,
      per_page: perPage,
      userId: mainUserId,
    });
  };

  const handelSearch = (searchValue) => {
    if (searchValue === '') {
      getAllUserTeamMembers({
        per_page: perPage,
        userId: mainUserId,
      });
    }
  };

  const handelSearchEnter = (e) => {
    if (e.key === 'Enter') {
      getAllUserTeamMembers({
        search: e.target.value,
        per_page: perPage,
        userId: mainUserId,
      });
    }
  };

  const handelFindTeamMembers = (userId) => {
    setMainUserId(userId);
    history.push(`/user/admin_user/team_members?user=${userId}`);
  };

  return (
    <>
      <section className="DataViewSection SectionGap">
        <div className="DataViewHeading">
          <div className="DataViewHeadingLeftContent">
            <div className="SectionTitle">
              {mainUserData.name ? `${mainUserData.name} ` : ' '}
              Team Members
            </div>
          </div>
          <div className="DataViewHeadingRightContent">
            <div className="SearchForm">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={(e) => handelSearch(e.target.value)}
                onKeyPress={(e) => handelSearchEnter(e)}
              />
            </div>
            {/* <button
              className="ml-3 Button PrimaryButton"
              data-toggle="modal"
              data-target="#AddMemberModal"
              onClick={handleShow}
            >
              <span>Add User</span>
            </button> */}
          </div>
        </div>
        <div className="TabContent">
          <div className="DataViewTable">
            <div className="TableWrapper">
              <table className="DataTable">
                <thead>
                  <tr>
                    <th />
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Username</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userData &&
                    userData.length > 0 &&
                    userData.map((p, index) => (
                      <TeamMemberList
                        handleEditModal={handleEditModal}
                        key={index}
                        userData={p}
                        handelFindTeamMembers={handelFindTeamMembers}
                      />
                    ))}
                  {userData && userData.length === 0 && (
                    <>
                      <tr
                        style={{ textAlign: 'center' }}
                        className="Deactivated"
                      >
                        <td colSpan="6">No data found</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {total > 10 && (
              <div className="TableFooter">
                <div className="TableFooterContent">
                  <form action="">
                    <div className="d-flex align-items-center">
                      <label htmlFor="ChooseNumber">Show</label>
                      <div className="CustomSelectNumber">
                        <select className="custom-select" id="ChooseNumber">
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                        </select>
                      </div>
                      <label htmlFor="ChooseNumber">Entires</label>
                    </div>
                  </form>
                  <Pagination
                    className="PaginationList ml-auto"
                    total={total}
                    current={currentPage}
                    onChange={(e) => handelPageChange(e)}
                    showTitle={false}
                    pageSize={perPage}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          size="xl"
          centered
          backdrop="static"
        >
          <Modal.Header bsPrefix="ModalHeader">
            <Modal.Title bsPrefix="modal-title">
              {editUser ? 'Update user' : 'Add new user'}
            </Modal.Title>
            <button
              type="button"
              className="close ml-auto"
              data-dismiss="modal"
              onClick={handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="insight wpi-close" />
              </span>
            </button>
          </Modal.Header>
          <Modal.Body bsPrefix="ModalBody">
            <UserModal
              email={email}
              setEmail={setEmail}
              status={status}
              setStatus={setStatus}
              setIs_verified_status={setIs_verified_status}
              is_verified_status={is_verified_status}
              password={password}
              setPassword={setPassword}
              submitUser={submitUser}
              name={name}
              setName={setName}
              userName={userName}
              setUserName={setUserName}
              err={err}
            />
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default UserTeamMembers;
