import { toast } from 'react-toastify';
import {
  RENDER_USER_LIST,
  LOAD_USER_FAILED,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  USER_VALIDATION_FAILED,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  RESET_USER_REQUEST_SUCCESS,
  RESET_USER_PAGE,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  LOAD_USER_LIST,
  SUPER_ADMIN_USER_SUBSCRIPTION_UPDATE,
  SUPER_ADMIN_USER_SUBSCRIPTION_UPDATE_SUCCESS,
  SUPER_ADMIN_USER_SUBSCRIPTION_UPDATE_FAILED,
} from '../actions/SuperAdminUsersActions';
import { SIGNED_OUT } from '../actions/index';

const initialState = {
  userList: [],
  currentPage: 1,
  totalPage: 1,
  total: 0,
  requestSuccess: false,
  loading: false,
  validationError: [],
  teamMemberList: [],
  mainUserData: {},
  userListLoader: false,
  modalLodar: false,
  allPackages: [],
  allPermissions: [],
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case RENDER_USER_LIST:
      return {
        ...state,
        userList: action.userList,
        currentPage: action.currentPage,
        totalPage: action.totalPage,
        total: action.total,
        allPackages: action.allPackages,
        allPermissions: action.allPermissions,
        loading: false,
        userListLoader: false,
      };
    case CREATE_USER:
      return {
        ...state,
        modalLodar: true,
      };
    case SUPER_ADMIN_USER_SUBSCRIPTION_UPDATE:
      return {
        ...state,
        modalLodar: true,
      };
    case SUPER_ADMIN_USER_SUBSCRIPTION_UPDATE_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        modalLodar: false,
        requestSuccess: true,
      };
    case SUPER_ADMIN_USER_SUBSCRIPTION_UPDATE_FAILED:
      toast.error(action.message);
      return {
        ...state,
        modalLodar: false,
        requestSuccess: false,
      };
    case UPDATE_USER:
      return {
        ...state,
        modalLodar: true,
      };
    case DELETE_USER:
      return {
        ...state,
        modalLodar: true,
      };
    case LOAD_USER_LIST:
      return {
        ...state,
        userListLoader: true,
      };
    case LOAD_USER_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        userListLoader: false,
      };

    case CREATE_USER_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        loading: false,
        modalLodar: false,
      };
    case CREATE_USER_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
      };
    case USER_VALIDATION_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        validationError: action.errors,
      };

    case UPDATE_USER_SUCCESS:
      toast.success(action.message);
      return {
        ...state,
        requestSuccess: true,
        loading: false,
        modalLodar: false,
      };
    case UPDATE_USER_FAILED:
      toast.error(action.message);
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        modalLodar: false,
      };

    case RESET_USER_REQUEST_SUCCESS:
      return {
        ...state,
        requestSuccess: false,
        loading: false,
        currentPage: 1,
      };

    case RESET_USER_PAGE:
      return {
        ...state,
        currentPage: 1,
        loading: false,
        modalLodar: false,
        userListLoader: false,
      };

    case SIGNED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
