import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import Spinner from 'react-bootstrap/Spinner';
import SitesBasicInfo from './SitesBasicInfo';
import SitesAdditionalInfo from './SitesAdditionalInfo';

import SitesActivePluginInfo from './SitesActivePluginInfo';
import SitesInActivePluginInfo from './SitesInActivePluginInfo';
import SitesDeactivationInfo from './SitesDeactivationInfo';
import SitesLogInfo from './SitesLogInfo';
import { isEmpty } from 'lodash';
import SitesOptionalData from './SitesOptionalData';

const SitesDetails = (props) => {
  const [selectedOption, setSelectedOption] = useState(1);
  const [siteUrl, setSiteUrl] = useState('');
  const [details, setDetails] = useState([]);

  const [logPaginationCurrentPage, setLogPaginationCurrentPage] = useState(1);
  const [logData, setLogData] = useState([]);
  const [lastLogTime, setLastLogTime] = useState([]);

  const [inactivePluginCurrentPage, setInactivePluginCurrentPage] = useState(1);
  const [inactivePlugin, setInactivePlugin] = useState([]);
  const [inactivePluginTotal, setInactivePluginTotal] = useState(0);

  const [activePluginCurrentPage, setActivePluginCurrentPage] = useState(1);
  const [activePlugin, setActivePlugin] = useState([]);
  const [activePluginTotal, setActivePluginTotal] = useState(0);
  const [secondarySidebarCollaspe, setSecondarySidebarCollaspe] =
    useState(false);
  // const [activePluginSearch, setActivePluginSearch] = useState('');

  /* eslint-disable */
  useEffect(() => {
    const desiredReport = queryString.parse(props.history.location.search);
    setSiteUrl(desiredReport.url);
    let role = '';
    if (props.rolePermissionInfo && props.rolePermissionInfo.length > 0) {
      role = props.rolePermissionInfo[0].name;
    }
    if (role === 'Demo_User') {
      props.siteDetailsForDemo({
        url: desiredReport.url,
        base_name: desiredReport.product,
        account_type: props.accountType,
        company_id: props.activeProfile.id,
      });
    } else {
      props.siteDetails({
        url: desiredReport.url,
        base_name: desiredReport.product,
        account_type: props.accountType,
        company_id: props.activeProfile.id,
      });
    }
  }, []);

  useEffect(() => {
    if (props.accessRemoved) {
      props.resetAccessRemoved();
    }
  }, [props.accessRemoved]);

  useEffect(() => {
    if (props.companyNotFound) {
      props.resetCompanyNotFound();
      props.history.push('/auth/create-company-form');
    }
  }, [props.companyNotFound]);

  useEffect(() => {
    if (props.allDetails && props.allDetails.length > 0) {
      var dataArray = props.allDetails[0];

      const { globalRank, CountryRank } = findAlexaValue(dataArray);
      dataArray.globalRank = globalRank;
      dataArray.CountryRank = CountryRank;

      setDetails(dataArray);

      var activePluginData = dataArray.active_plugins
        ? JSON.parse(dataArray.active_plugins)
        : [];

      activePluginData && activePluginData.length > 0
        ? setActivePluginTotal(activePluginData.length)
        : 0;
      activePluginData && activePluginData.length > 0
        ? setActivePlugin(activePluginData.slice((1 - 1) * 18, 1 * 18))
        : [];

      var inactivePluginData = dataArray.inactive_plugins
        ? JSON.parse(dataArray.inactive_plugins)
        : [];

      if (!Array.isArray(inactivePluginData)) {
        inactivePluginData = Object.values(inactivePluginData);
      }

      inactivePluginData && setInactivePluginTotal(inactivePluginData.length);
      inactivePluginData &&
        setInactivePlugin(inactivePluginData.slice((1 - 1) * 18, 1 * 18));
    } else {
      setDetails([]);
      setActivePlugin([]);
      setInactivePlugin([]);
    }
  }, [props.allDetails]);

  useEffect(() => {
    if (props.logUpdate && props.logUpdate.length > 0) {
      var lastLogTime =
        props.logUpdate && props.logUpdate.length > 0
          ? props.logUpdate[0]
          : undefined;
      setLastLogTime(lastLogTime);
      var log = props.logUpdate.slice((1 - 1) * 18, 1 * 18);
      setLogData(log);
    } else {
      setLastLogTime(undefined);
      setLogData([]);
    }
  }, [props.logUpdate]);

  /* eslint-enable */

  const findAlexaValue = (data) => {
    let globalRank = null;
    let countryRank = null;
    if (data.alexa_rank_data) {
      let alexa = JSON.parse(data.alexa_rank_data);
      alexa = alexa.filter((alexa) => {
        return alexa !== null && alexa !== undefined;
      });
      if (alexa && alexa.length > 0) {
        globalRank = alexa[alexa.length - 1].global_rank;
        countryRank = alexa[alexa.length - 1].country_rank;
      }
    }
    return {
      globalRank,
      countryRank,
    };
  };

  const handelLogPageChange = (e) => {
    const log = props.logUpdate.slice((e - 1) * 18, e * 18);
    setLogData(log);
    setLogPaginationCurrentPage(e);
  };

  const handelActivePluginPageChange = (e) => {
    const activePluginData =
      props.allDetails && props.allDetails[0].active_plugins
        ? JSON.parse(props.allDetails[0].active_plugins)
        : [];
    setActivePluginTotal(activePluginData.length);
    setActivePlugin(activePluginData.slice((e - 1) * 18, e * 18));
    setActivePluginCurrentPage(e);
  };

  const handelInactivePluginPageChange = (e) => {
    const inactivePluginData =
      props.allDetails &&
      props.allDetails[0] &&
      props.allDetails[0].inactive_plugins
        ? JSON.parse(props.allDetails[0].inactive_plugins)
        : [];
    setInactivePluginTotal(inactivePluginData.length);
    setInactivePlugin(inactivePluginData.slice((e - 1) * 18, e * 18));
    setInactivePluginCurrentPage(e);
  };
  const togolSecondarySideBar = (toggoleSecondarySidebar) => {
    toggoleSecondarySidebar && setSecondarySidebarCollaspe(false);
    !toggoleSecondarySidebar && setSecondarySidebarCollaspe(true);
  };

  // const handelActivePluginSearch = (e) => {
  //   if (e.key === 'Enter') {

  //     if(details && details.activePlugin.length > 0) {

  //     }
  //   }
  // };

  return (
    <>
      <div className="SectionTitleWrap SectionGap">
        <button
          onClick={(e) => togolSecondarySideBar(secondarySidebarCollaspe)}
          className={`HamburgerToggler ${
            secondarySidebarCollaspe ? 'Active' : ''
          }`}
        >
          <span />
          <span />
          <span />
        </button>
        <a href="#site-title" className="SectionTitle">
          {siteUrl}
        </a>
      </div>
      <div className="d-flex h-100 position-relative">
        <div
          className={`SecondarySidebar ${
            secondarySidebarCollaspe ? 'Show' : ''
          }`}
        >
          <ul className="SidebarNav">
            <li className="SidebarNavItem">
              <a
                href="#details"
                onClick={(e) => setSelectedOption(1)}
                className={`SidebarNavItemLink ${
                  selectedOption === 1 ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">Basic Info</div>
              </a>
            </li>
            <li className="SidebarNavItem">
              <a
                href="#details"
                onClick={(e) => setSelectedOption(2)}
                className={`SidebarNavItemLink ${
                  selectedOption === 2 ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">Additional Info</div>
              </a>
            </li>
            <li className="SidebarNavItem">
              <a
                href="#details"
                onClick={(e) => setSelectedOption(3)}
                className={`SidebarNavItemLink ${
                  selectedOption === 3 ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">Active Plugin</div>
              </a>
            </li>
            <li className="SidebarNavItem">
              <a
                href="#details"
                onClick={(e) => setSelectedOption(4)}
                className={`SidebarNavItemLink ${
                  selectedOption === 4 ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">Deactive Plugin</div>
              </a>
            </li>
            {details.deactivated_date && (
              <div className="SidebarNavItem">
                <a
                  href="#details"
                  onClick={(e) => setSelectedOption(5)}
                  className={`SidebarNavItemLink ${
                    selectedOption === 5 ? 'Active' : ''
                  }`}
                >
                  <div className="SidebarNavItemLinkText">
                    Deactivation Info
                  </div>
                </a>
              </div>
            )}
            {!isEmpty(details.optional_data) && (
              <div className="SidebarNavItem">
                <a
                  href="#details"
                  onClick={(e) => setSelectedOption(7)}
                  className={`SidebarNavItemLink ${
                    selectedOption === 7 ? 'Active' : ''
                  }`}
                >
                  <div className="SidebarNavItemLinkText">Optional Data</div>
                </a>
              </div>
            )}
            <div className="SidebarNavItem">
              <a
                href="#details"
                onClick={(e) => setSelectedOption(6)}
                className={`SidebarNavItemLink ${
                  selectedOption === 6 ? 'Active' : ''
                }`}
              >
                <div className="SidebarNavItemLinkText">Log Info</div>
              </a>
            </div>
          </ul>
        </div>
        <div className="SecondarySidebarContent">
          {props.detailsLoader ? (
            <section className="FormViewSection">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '400px',
                }}
              >
                <Spinner animation="border" variant="dark" />
              </div>
            </section>
          ) : (
            <>
              {selectedOption === 1 && <SitesBasicInfo data={details} />}
              {selectedOption === 2 && <SitesAdditionalInfo data={details} />}
              {selectedOption === 3 && (
                <SitesActivePluginInfo
                  handelActivePluginPageChange={handelActivePluginPageChange}
                  currentPage={activePluginCurrentPage}
                  total={activePluginTotal}
                  data={activePlugin}
                />
              )}
              {selectedOption === 4 && (
                <SitesInActivePluginInfo
                  handelInactivePluginPageChange={
                    handelInactivePluginPageChange
                  }
                  currentPage={inactivePluginCurrentPage}
                  total={inactivePluginTotal}
                  data={inactivePlugin}
                />
              )}
              {selectedOption === 5 && details.deactivated_date && (
                <SitesDeactivationInfo data={details} />
              )}
              {selectedOption === 6 && (
                <SitesLogInfo
                  handelLogPageChange={handelLogPageChange}
                  data={logData}
                  lastLogTime={lastLogTime}
                  currentPage={logPaginationCurrentPage}
                  total={props.logUpdate.length}
                />
              )}
              {selectedOption === 7 && (
                <SitesOptionalData
                  data={
                    !isEmpty(details?.optional_data)
                      ? details?.optional_data
                      : []
                  }
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SitesDetails;
