import React from 'react';

const options = [
  { value: 'Active', label: 'Active' },
  { value: 'Deactivated', label: 'Inactive' },
];
const CompanyList = ({ handleEditModal, companyData, handleMembersModal }) => (
  <>
    <tr>
      <td>
        <p>{companyData.name}</p>
      </td>
      <td>
        <p>{companyData.userCount}</p>
      </td>
      <td>
        <div className="d-flex align-items-center">
          {companyData.companyStatus && (
            <p
              className={`SmallButton ml-3 ${
                companyData.companyStatus === options[0].value
                  ? 'SuccessButton'
                  : 'DangerButton'
              }`}
            >
              {options
                .filter((f) => f.value === companyData.companyStatus)
                .map((option) => option.label.toUpperCase())}
            </p>
          )}
        </div>
      </td>
      <td>
        <p>{companyData.createdBy}</p>
      </td>
      <td className="ActionButtonColumn">
        <p className="ActionButtonGroup">
          <button
            onClick={(e) => handleEditModal(companyData.id)}
            className="ActionButton PrimaryAlert"
            title="Edit"
          >
            <span className="Icon">
              <i className="insight wpi-edit" />
            </span>
          </button>
          <button
            onClick={(e) => handleMembersModal(companyData.id)}
            className="ActionButton PrimaryAlert"
            title="members"
          >
            <span className="Icon">
              <i className="insight wpi-users" />
            </span>
          </button>
        </p>
      </td>
    </tr>
  </>
);

export default CompanyList;
