import { isNull } from 'lodash';
import React from 'react';

import { Link } from 'react-router-dom';

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({
  siteData,
  handelCheckBox,
  siteListShowColumn,
  showOptionalData = undefined,
}) {
  return (
    <tr
      className={
        siteData.status &&
        siteData.status === 'Deactivated' &&
        siteData.deactivated &&
        siteData.deactivated
          ? 'Deactivated'
          : ''
      }
    >
      <td className="selector">
        <label className="Checkbox">
          <input
            type="checkbox"
            key={siteData.id}
            name={siteData.id}
            id={siteData.baseNameSlug}
            checked={siteData.isChecked}
            onChange={(e) => handelCheckBox(e)}
          />
          <span />
        </label>
      </td>
      {siteListShowColumn &&
        siteListShowColumn
          .filter((item) => item.isChecked)
          .map((column, index) => {
            if (column.name === 'url') {
              return (
                <td key={index} className="DetailsButtonWrapper">
                  <p>
                    <a
                      href={siteData[column.name]}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      {siteData[column.name]}
                    </a>
                  </p>
                  <Link
                    to={`/user/sites-details?product=${
                      siteData.baseNameSlug
                    }&url=${siteData[column.name]}`}
                  >
                    <button className="LabelButton PrimaryButton">
                      Details
                    </button>
                  </Link>
                  <button
                    className={`ml-3 LabelButton PrimaryButton ${
                      isNull(siteData['optional_data']) && 'Disabled'
                    }`}
                    disabled={isNull(siteData['optional_data'])}
                    onClick={(e) => {
                      showOptionalData &&
                        showOptionalData(e, siteData['optional_data']);
                    }}
                  >
                    View
                  </button>
                </td>
              );
            } else if (column.name === 'optional_data') {
              return (
                <td key={index}>
                  <button
                    className="ml-3 LabelButton PrimaryButton Disabled"
                    disabled={isNull(siteData[column.name])}
                    onClick={(e) => {
                      showOptionalData(e, siteData[column.name]);
                    }}
                  >
                    View
                  </button>
                </td>
              );
            } else {
              return (
                <td key={index}>
                  <p>{siteData[column.name]}</p>
                </td>
              );
            }
          })}
      <td />
    </tr>
  );
}
