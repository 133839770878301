import DashboardContainer from './containers/DashboardContainer';
import ReportContainer from './containers/ReportContainer';
import ProductContainer from './containers/ProductContainer';
import ProductDetailsContainer from './containers/ProductDetailsContainer';
import ForgetPasswordContainer from './containers/ForgetPasswordContainer.js';
import Signin from './containers/SignIn';
import DemoApply from './containers/DemoApply';
import ResentVerificationContainer from './containers/ResentVerificationContainer';
import ResetPasswordContainer from './containers/ResetPasswordContainer';
import SitesDetailsContainer from './containers/SitesDetailsContainer';
import TeamMembersContainer from './containers/TeamMembersContainer';
import CompanyContainer from './containers/CompanyContainer';
import CompanyCreateFormContainer from './containers/CompanyCreateFormContainer';
import RegistrationContainer from './containers/RegistrationContainer';
import OnboardContainer from './containers/OnboardContainer';
import VerifyContainer from './containers/VerifyContainer';
import './assets/css/WP-insight-icon/style.css';
import UserSettingContainer from './containers/UserSettingContainer';
import ResendVerificationFromUserPanel from './containers/ResendVerificationFromUserPanel';

import SuperAdminDashboardContainer from './containers/SuperAdmin/DashboardContainer';
import SuperAdminUsersContainer from './containers/SuperAdmin/UsersContainer';

import SuperAdminCompaniesContainer from './containers/SuperAdmin/CompaniesContainer';

import SuperAdminUserTeamMemberContainer from './containers/SuperAdmin/UserTeamMemberContainer';
import SuperAdminProductsContainer from './containers/SuperAdmin/ProductsContainer';
import SuperAdminProductDetailsContainer from './containers/SuperAdmin/ProductsDetailsContainer';
import SuperAdminReportContainer from './containers/SuperAdmin/ReportsContainer';
import SuperAdminSitesDetailsContainer from './containers/SuperAdmin/SitesDetailsContainer';
import SuperAdminSettingsContainer from './containers/SuperAdmin/SettingsContainer';
import SuperAdminPackagesContainer from './containers/SuperAdmin/PackageContainer';

const routes = [
  {
    collapse: false,
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardContainer,
    layout: '/user',
    sidebar: true,
    icon: 'insight wpi-dashboard',
    is_admin_route: false,
    views: [],
  },
  {
    collapse: false,
    path: '/resend-verify-user',
    name: 'Resend Verification',
    component: ResendVerificationFromUserPanel,
    layout: '/user',
    sidebar: true,
    icon: 'insight wpi-dashboard',
    views: [],
    verified: true,
    is_admin_route: false,
  },
  {
    collapse: false,
    path: '/company',
    name: 'Companies',
    component: CompanyContainer,
    layout: '/user',
    sidebar: true,
    icon: 'insight wpi-building',
    views: [],
    is_admin_route: false,
  },
  {
    collapse: false,
    path: '/team-members',
    name: 'Team Members',
    component: TeamMembersContainer,
    layout: '/user',
    sidebar: true,
    icon: 'insight wpi-users',
    views: [],
    is_admin_route: false,
  },
  {
    collapse: false,
    path: '/products',
    name: 'Product',
    component: ProductContainer,
    layout: '/user',
    sidebar: true,
    icon: 'insight wpi-bookmark',
    views: [],
    is_admin_route: false,
  },
  {
    collapse: false,
    path: '/product-details',
    name: 'Product Details',
    component: ProductDetailsContainer,
    layout: '/user',
    sidebar: false,
    icon: 'insight wpi-bookmark',
    views: [],
    is_admin_route: false,
  },
  {
    collapse: false,
    path: '/Reports',
    name: 'Reports',
    component: ReportContainer,
    layout: '/user',
    sidebar: true,
    icon: 'insight wpi-chart',
    is_admin_route: false,
    views: [
      {
        path: '/Reports',
        name: 'Sites Report',
        component: ReportContainer,
        layout: '/user',
        sidebar: true,
        icon: 'insight wpi-report',
        views: [],
        queryFilter: '?report=sites_reports',
        is_admin_route: false,
      },
      {
        path: '/Reports',
        name: 'Product Report',
        component: ReportContainer,
        layout: '/user',
        sidebar: true,
        icon: 'insight wpi-pie-chart',
        views: [],
        queryFilter: '?report=other_reports',
        is_admin_route: false,
      },
    ],
  },
  {
    collapse: false,
    path: '/sites-details',
    name: 'Sites Details',
    component: SitesDetailsContainer,
    layout: '/user',
    sidebar: false,
    icon: '',
    views: [],
    is_admin_route: false,
  },
  {
    collapse: false,
    path: '/settings',
    name: 'Settings',
    component: UserSettingContainer,
    layout: '/user',
    sidebar: true,
    icon: 'insight wpi-gear',
    views: [],
    is_admin_route: false,
  },
  {
    collapse: false,
    path: '/admin_user/dashboard',
    name: 'Dashboard',
    component: SuperAdminDashboardContainer,
    layout: '/user',
    sidebar: true,
    icon: 'insight wpi-dashboard',
    is_admin_route: true,
    views: [],
  },
  {
    collapse: false,
    path: '/admin_user/users',
    name: 'Users',
    component: SuperAdminUsersContainer,
    layout: '/user',
    sidebar: true,
    icon: 'insight wpi-users',
    is_admin_route: true,
    views: [],
  },
  {
    collapse: false,
    path: '/admin_user/company',
    name: 'Companies',
    component: SuperAdminCompaniesContainer,
    layout: '/user',
    sidebar: true,
    icon: 'insight wpi-building',
    is_admin_route: true,
    views: [],
  },
  {
    collapse: false,
    path: '/admin_user/team_members',
    name: 'Team Members',
    component: SuperAdminUserTeamMemberContainer,
    layout: '/user',
    sidebar: false,
    icon: 'insight wpi-users',
    is_admin_route: true,
    views: [],
  },
  {
    collapse: false,
    path: '/admin_user/products',
    name: 'Products',
    component: SuperAdminProductsContainer,
    layout: '/user',
    sidebar: true,
    icon: 'insight wpi-bookmark',
    is_admin_route: true,
    views: [],
  },
  {
    collapse: false,
    path: '/admin_user/product-details',
    name: 'Product Details',
    component: SuperAdminProductDetailsContainer,
    layout: '/user',
    sidebar: false,
    icon: 'insight wpi-bookmark',
    is_admin_route: true,
    views: [],
  },
  {
    collapse: false,
    path: '/admin_user/Reports',
    name: 'Reports',
    component: SuperAdminReportContainer,
    layout: '/user',
    sidebar: true,
    icon: 'insight wpi-chart',
    is_admin_route: true,
    views: [
      {
        path: '/admin_user/Reports',
        name: 'Sites Report',
        component: SuperAdminReportContainer,
        layout: '/user',
        sidebar: true,
        icon: '',
        views: [],
        queryFilter: '?report=sites_reports',
        is_admin_route: true,
      },
      {
        path: '/admin_user/Reports',
        name: 'Product Report',
        component: SuperAdminReportContainer,
        layout: '/user',
        sidebar: true,
        icon: '',
        views: [],
        queryFilter: '?report=other_reports',
        is_admin_route: true,
      },
      {
        path: '/admin_user/Reports',
        name: 'Stress Report',
        component: SuperAdminReportContainer,
        layout: '/user',
        sidebar: true,
        icon: '',
        views: [],
        queryFilter: '?report=stress_reports',
        is_admin_route: true,
      },
    ],
  },
  {
    collapse: false,
    path: '/admin_user/sites-details',
    name: 'Sites Details',
    component: SuperAdminSitesDetailsContainer,
    layout: '/user',
    sidebar: false,
    icon: '',
    views: [],
    is_admin_route: true,
  },
  {
    collapse: false,
    path: '/admin_user/packages',
    name: 'packages',
    component: SuperAdminPackagesContainer,
    layout: '/user',
    sidebar: true,
    icon: 'insight wpi-subscription',
    is_admin_route: true,
    views: [],
  },
  {
    collapse: false,
    path: '/admin_user/settings',
    name: 'Settings',
    component: SuperAdminSettingsContainer,
    layout: '/user',
    sidebar: true,
    icon: 'insight wpi-gear',
    views: [],
    is_admin_route: true,
  },
  {
    collapse: false,
    path: '/sign-in',
    name: 'Sign In',
    component: Signin,
    layout: '/auth',
    sidebar: false,
    icon: '',
    views: [],
  },
  {
    collapse: false,
    path: '/apply-demo',
    name: 'Apply for Demo',
    component: DemoApply,
    layout: '/auth',
    sidebar: false,
    icon: '',
    views: [],
  },
  {
    collapse: false,
    path: '/registration',
    name: 'Registration',
    component: RegistrationContainer,
    layout: '/auth',
    sidebar: false,
    icon: '',
    views: [],
  },
  {
    collapse: false,
    path: '/user-verify/:userKey',
    name: 'User Verify',
    component: VerifyContainer,
    layout: '/auth',
    sidebar: false,
    icon: '',
    views: [],
  },
  {
    collapse: false,
    path: '/onboard-form/',
    name: 'User Onboard From',
    component: OnboardContainer,
    layout: '/auth',
    sidebar: false,
    icon: '',
    views: [],
  },
  {
    collapse: false,
    path: '/create-company-form',
    name: 'Company Create From',
    component: CompanyCreateFormContainer,
    layout: '/auth',
    sidebar: false,
    icon: '',
    views: [],
  },
  {
    collapse: false,
    path: '/forget-pass',
    name: 'Send Forget Password Link',
    component: ForgetPasswordContainer,
    layout: '/auth',
    sidebar: false,
    icon: '',
    views: [],
  },
  {
    collapse: false,
    path: '/resent-verification',
    name: 'Resent Verification Link',
    component: ResentVerificationContainer,
    layout: '/auth',
    sidebar: false,
    icon: '',
    views: [],
  },
  {
    collapse: false,
    path: '/reset-pass-form/:key',
    name: 'Reset Password',
    component: ResetPasswordContainer,
    layout: '/auth',
    sidebar: false,
    icon: '',
    views: [],
  },
];

export default routes;
