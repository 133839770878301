import { isArray } from 'lodash';
import React from 'react';

const SitesOptionalData = (props) => {
  const { data: optionalData } = props;
  const finalData = JSON.parse(optionalData);
  return (
    <section className="DetailsViewSection">
      <div className="DetailsView">
        <div className="DetailsViewContent">
          {Object.keys(finalData).map((key, index) => {
            if (isArray(finalData[key])) {
              return (
                <div key={index}>
                  {`${key} : ${JSON.stringify(finalData[key])}`}{' '}
                </div>
              );
            } else {
              return <div key={index}>{`${key} : ${finalData[key]}`} </div>;
            }
          })}
        </div>
      </div>
    </section>
  );
};

export default SitesOptionalData;
