import React, { useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import WpiLogo from '../../assets/SVG/logo-white.svg';

import wpiLogin from '../../assets/img/login.svg';

/* eslint-disable */
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

/* eslint-enable */

const DemoSiteApplyForm = ({
  demoSiteApplied,
  loader,
  validationError,
  applyForDemo,
  history,
}) => {
  let email;
  let name;

  const [err, setErr] = useState({
    email: [],
    name: [],
  });

  /* eslint-disable */
  useEffect(() => {
    if (demoSiteApplied) {
      email.value = '';
      name.value = '';

      history.push('/auth/sign-in');
    }
  }, [demoSiteApplied]);

  useEffect(() => {
    if (validationError.length > 0) {
      let emailErr = [];
      let nameErr = [];

      validationError[0].map((err) => {
        if (err.param === 'email') {
          emailErr.push(err.msg);
        }
        if (err.param === 'name') {
          nameErr.push(err.msg);
        }
      });

      setErr({
        ...err,
        email: emailErr,
        name: nameErr,
      });
    } else {
      setErr({
        email: [],
        name: [],
      });
    }
  }, [validationError]);

  /* eslint-enable */

  const validateForm = () => {
    const errors = [];

    const emailErr = [];
    const nameErr = [];

    if (email.value === '') {
      emailErr.push("Email can't be empty");
      errors.push("Email can't be empty");
    }

    if (email.length > 0 && !validEmailRegex.test(email.value)) {
      errors.push('Email is not valid');
      emailErr.push('Email is not valid');
    }

    if (name.value === '') {
      nameErr.push("Name can't be empty");
      errors.push("Name can't be empty");
    }

    if (name.length > 255) {
      nameErr.push("Name can't be over 255 charecter");
      errors.push("Name can't be over 255 charecter");
    }

    if (errors.length > 0) {
      setErr({
        email: emailErr,
        name: nameErr,
      });
    }
    return errors;
  };

  const submitDemoApplyForm = (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (errors.length > 0) {
      return;
    }
    applyForDemo({
      email: email.value,
      name: name.value,
    });
  };

  const forgotPass = () => {
    history.push('/auth/forget-pass');
  };

  const signIn = () => {
    history.push('/auth/sign-in');
  };
  const resendVerification = () => {
    history.push('/auth/resent-verification');
  };

  return (
    <div className="WpPageBody LoginBody">
      <div className="LoginHeader">
        <Link to="/auth/sign-in" className="SiteLogo">
          <img src={WpiLogo} alt="" />
        </Link>
        {/* <a href="sign-in" className="SiteLogo">
          <img src={WpiLogo} alt="" />
        </a> */}
      </div>
      <div className="LoginWrapper">
        <div className="LoginContent">
          <div className="LoginForm">
            <Form onSubmit={submitDemoApplyForm}>
              <h2>Apply for Demo</h2>
              <div className="FormGroup mb-4">
                <label>Email Address</label>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  placeholder="Example@example.com"
                  autoFocus
                  ref={(node) => {
                    email = node;
                  }}
                />
                {err &&
                  err.email &&
                  err.email.map((err, index) => (
                    <p key={index} style={{ color: 'red' }}>
                      {err}
                    </p>
                  ))}
              </div>

              <div className="FormGroup mb-4">
                <label>Name</label>
                <input
                  type="text"
                  id="userName"
                  className="form-control"
                  placeholder="Name"
                  ref={(node) => {
                    name = node;
                  }}
                />
                {err &&
                  err.name &&
                  err.name.map((err, index) => (
                    <p key={index} style={{ color: 'red' }}>
                      {err}
                    </p>
                  ))}
              </div>

              <button type="submit" className="Button PrimaryButton w-100 mb-4">
                <span>
                  {loader ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    'Apply'
                  )}
                </span>
              </button>
              <div className="d-flex">
                <button onClick={forgotPass}>Forgot Password?</button>
                <button
                  onClick={resendVerification}
                  className="ml-auto ColorSecondary"
                >
                  Resend Verification Link
                </button>
              </div>
              <p className="mt-5 ColorSecondary">
                Already Have an Account?{' '}
                <button className="ColorPrimary" onClick={signIn}>
                  Sign In
                </button>
              </p>
            </Form>
          </div>
        </div>
        <div className="LoginImage">
          <img src={wpiLogin} alt="" className="w-100 img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default DemoSiteApplyForm;
