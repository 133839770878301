import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const MailChimpApiKey = (props) =>
  props.setingsLoader ? (
    <section className="FormViewSection">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '400px',
        }}
      >
        <Spinner animation="border" variant="dark" />
      </div>
    </section>
  ) : (
    <section className="FormViewSection">
      {props.mailChimpApiKey ? (
        <>
          {!props.handelUpdateApiKey ? (
            <>
              {props.accountType === 'Partner' ? (
                <div className="d-flex mb-5">
                  <button
                    onClick={props.updateApiKey}
                    className="Button PrimaryButton ml-auto"
                  >
                    <div className="Icon">
                      <i className="insight wpi-edit" />
                    </div>
                    <span>Update API KEY</span>
                  </button>
                </div>
              ) : (
                props.userPermission
                  .filter((p) => p.name === 'edit-mailchimp' && p.checked)
                  .map((b) => (
                    <div className="d-flex mb-5">
                      <button
                        onClick={props.updateApiKey}
                        className="Button PrimaryButton ml-auto"
                      >
                        <div className="Icon">
                          <i className="insight wpi-edit" />
                        </div>
                        <span>Update API KEY</span>
                      </button>
                    </div>
                  ))
              )}
              {/* {props.userPermission
                .filter((p) => p.name === 'edit-mailchimp' && p.checked)
                .map((b) => {
                  return (
                    <div className="d-flex mb-5">
                      <button
                        onClick={props.updateApiKey}
                        className="Button PrimaryButton ml-auto"
                      >
                        <div className="Icon">
                          <i className="insight wpi-edit"></i>
                        </div>
                        <span>Update API KEY</span>
                      </button>
                    </div>
                  );
                })} */}

              <div className="FormViewDataWrapper">
                <div className="FormViewDataGroupLeft">
                  <div className="FormViewGroup">
                    <p className="FormViewTitle">API KEY :</p>
                    <p className="FormViewData">{props.mailChimpApiKey}</p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="FormViewGroup">
                <p className="FormViewTitle">API KEY :</p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your API Key..."
                  onChange={(e) =>
                    props.setHandelMailChimpApiKey(e.target.value)
                  }
                  value={props.handelMailChimpApiKey}
                />
              </div>
              {props.mailChimpAPIKeyErr &&
                props.mailChimpAPIKeyErr.apiKey &&
                props.mailChimpAPIKeyErr.apiKey.length > 0 && (
                  <div className="FormViewGroup">
                    <p className="FormViewTitle" />
                    <p style={{ color: 'red' }}>
                      {props.mailChimpAPIKeyErr.apiKey}
                    </p>
                  </div>
                )}
              <div className="d-flex">
                <button
                  onClick={props.cancelUpdateApiKey}
                  className="Button SemiButtonDanger ml-auto"
                >
                  <span>Cancel</span>
                </button>

                <button
                  onClick={props.hadelStoreMailChimpApiKey}
                  className="Button PrimaryButton ml-3"
                >
                  <span>Submit</span>
                </button>
              </div>
            </>
          )}
        </>
      ) : props.accountType === 'Partner' ? (
        <>
          <div className="FormViewGroup">
            <p className="FormViewTitle">API KEY :</p>
            <input
              type="text"
              className="form-control"
              placeholder="Your API Key..."
              onChange={(e) => props.setHandelMailChimpApiKey(e.target.value)}
            />
          </div>
          {props.mailChimpAPIKeyErr &&
            props.mailChimpAPIKeyErr.apiKey &&
            props.mailChimpAPIKeyErr.apiKey.length > 0 && (
              <div className="FormViewGroup">
                <p className="FormViewTitle" />
                <p style={{ color: 'red' }}>
                  {props.mailChimpAPIKeyErr.apiKey}
                </p>
              </div>
            )}
          <div className="d-flex">
            <button
              onClick={props.hadelStoreMailChimpApiKey}
              className="Button PrimaryButton ml-auto"
            >
              <span>Submit</span>
            </button>
          </div>
        </>
      ) : (
        props.userPermission
          .filter((p) => p.name === 'edit-mailchimp' && p.checked)
          .map((b, index) => (
            <>
              <div key={index} className="FormViewGroup">
                <p className="FormViewTitle">API KEY :</p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your API Key..."
                  onChange={(e) =>
                    props.setHandelMailChimpApiKey(e.target.value)
                  }
                />
              </div>
              {props.mailChimpAPIKeyErr &&
                props.mailChimpAPIKeyErr.apiKey &&
                props.mailChimpAPIKeyErr.apiKey.length > 0 && (
                  <div className="FormViewGroup">
                    <p className="FormViewTitle" />
                    <p style={{ color: 'red' }}>
                      {props.mailChimpAPIKeyErr.apiKey}
                    </p>
                  </div>
                )}
              <div className="d-flex">
                <button
                  onClick={props.hadelStoreMailChimpApiKey}
                  className="Button PrimaryButton ml-auto"
                >
                  <span>Submit</span>
                </button>
              </div>
            </>
          ))
      )}
    </section>
  );

export default MailChimpApiKey;
