import React from 'react';

import { Route, Redirect, Switch } from 'react-router-dom';
import UserLayout from './layouts/UserLayout';
import AuthLayout from './layouts/AuthLayout';

function App() {
  return (
    <div>
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Route path="/user" render={(props) => <UserLayout {...props} />} />
        <Redirect from="/" to="/auth/sign-in" />
      </Switch>
    </div>
  );
}

export default App;
