import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';
import Pagination from 'rc-pagination';
import ProductList from './ProductList';

const SuperAdminAllProducts = ({
  history,
  allProducts,
  productlist,
  currentPage,
  totalPage,
  total,
  userCount,
  allProductList,
  allCompanyList,
  productListLodaer,
}) => {
  const [perPage, setPerPage] = useState(10);
  //   const [search, setSearch] = useState(null);
  const [productData, setProductData] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState([
    {
      value: 'all',
      label: 'All',
    },
  ]);

  const [selectedCompany, setSelectedCompany] = useState([
    {
      value: 'all',
      label: 'All',
    },
  ]);

  const [oldFilterData, setOldFilterData] = useState({
    companies: selectedCompany.value,
    Products: selectedProduct.value,
    per_page: perPage,
  });

  /* eslint-disable */
  useEffect(() => {
    allProducts({
      page: currentPage,
      per_page: perPage,
    });
  }, []);

  useEffect(() => {
    if (productlist.length > 0) {
      var dataArray = productlist.map((Product) => {
        var newArray = {};
        newArray.productName = Product.base_name;
        newArray.productBaseName = Product.base_name_slug;
        newArray.id = Product.id;
        newArray.productStatus = Product.status;
        newArray.ActiveSites = Product.ActiveSites;
        newArray.DeactivatedSites = Product.DeactivatedSites;
        return newArray;
      });
      setProductData(dataArray);
    } else {
      setProductData([]);
    }
  }, [productlist]);

  const productSelectoptions = [
    { value: 'all', label: 'All' },
    ...allProductList.map((Product) => {
      const newArray = {};
      newArray.value = Product.base_name_slug;
      newArray.label = Product.base_name;
      return newArray;
    }),
  ];

  const companySelectoptions = [
    { value: 'all', label: 'All' },
    ...allCompanyList
      .map((company) => {
        const newArray = {};
        newArray.value = company.id;
        newArray.label = company.name;
        return newArray;
      })
      .filter(
        (company) =>
          company.value !== '' &&
          company.value !== null &&
          company.value !== undefined
      ),
  ];

  /* eslint-enable */

  const handelPageChange = (e) => {
    allProducts({
      ...oldFilterData,
      page: e,
      per_page: perPage,
    });
  };

  const handelPageSizeChange = (e) => {
    e.preventDefault();
    setPerPage(e.target.value);
    allProducts({
      ...oldFilterData,
      per_page: e.target.value,
    });
  };

  /* eslint-disable */

  /* eslint-enable */
  //   const handelSearch = (e) => {
  //     if (e.key === 'Enter') {
  //       allProducts({
  //         search: search,
  //         per_page: perPage,
  //       });
  //     }
  //   };

  const handleSearchProductOrCompany = (e) => {
    e.preventDefault();
    setOldFilterData({
      companies: selectedCompany.map((u) => u.value),
      Products: selectedProduct.map((p) => p.value),
      per_page: perPage,
    });

    allProducts({
      companies: selectedCompany.map((u) => u.value),
      Products: selectedProduct.map((p) => p.value),
      per_page: perPage,
    });
  };

  const handelProductsSelect = (e) => {
    let new_products;
    if (e && e.length > 0) {
      const is_all_added = e.some((item) => item.value === 'all');
      const is_all_previous = selectedProduct.some(
        (item) => item.value === 'all'
      );

      if (is_all_previous) {
        new_products = !is_all_added
          ? e
          : e.filter((item) => item.value !== 'all');
      } else {
        new_products = !is_all_added
          ? e
          : e.filter((item) => item.value === 'all');
      }
    } else {
      new_products = [];
    }
    setSelectedProduct(new_products);
  };

  const handelCompanySelect = (e) => {
    let new_users;
    if (e && e.length > 0) {
      const is_all_added = e.some((item) => item.value === 'all');
      const is_all_previous = selectedCompany.some(
        (item) => item.value === 'all'
      );

      if (is_all_previous) {
        new_users = !is_all_added
          ? e
          : e.filter((item) => item.value !== 'all');
      } else {
        new_users = !is_all_added
          ? e
          : e.filter((item) => item.value === 'all');
      }
    } else {
      new_users = [];
    }
    setSelectedCompany(new_users);
  };

  return (
    <>
      <section className="DataViewSection SectionGap">
        <div className="DataViewFilter">
          <div className="row HasColGap">
            <div className="Col-30">
              <div className="FormGroup">
                <label htmlFor="">Product</label>
                <Select
                  name=""
                  id="search_product"
                  // onChange={(e) => setSelectedProduct(e || [])}
                  onChange={handelProductsSelect}
                  options={productSelectoptions}
                  defaultValue={productSelectoptions[0]}
                  value={selectedProduct}
                  className="SelectOption"
                  isMulti
                  classNamePrefix="react-select"
                />
              </div>
            </div>
            <div className="Col-30">
              <div className="FormGroup">
                <label>Company</label>
                <Select
                  name=""
                  id="search_product"
                  // onChange={(e) => setSelectedCompany(e || [])}
                  onChange={handelCompanySelect}
                  options={companySelectoptions}
                  defaultValue={companySelectoptions[0]}
                  value={selectedCompany}
                  className="SelectOption"
                  isMulti
                  classNamePrefix="react-select"
                />
              </div>
            </div>

            <div className="Col-15 ml-auto">
              <div className="FormGroup">
                <button
                  onClick={handleSearchProductOrCompany}
                  className="Button PrimaryButton ml-auto"
                >
                  <span>Filter</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {productListLodaer ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '400px',
            }}
          >
            <Spinner animation="border" variant="dark" />
          </div>
        ) : (
          <div className="DataViewTable">
            <div className="TableWrapper">
              <table className="DataTable">
                <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Active User</th>
                    <th>Deactivated User</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {productData.map((p, index) => (
                    <ProductList key={index} productData={p} />
                  ))}
                  {productData && productData.length === 0 && (
                    <>
                      <tr
                        style={{ textAlign: 'center' }}
                        className="Deactivated"
                      >
                        <td colSpan="6">No data found</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {total > 10 && (
              <div className="TableFooter">
                <div className="TableFooterContent">
                  <form action="">
                    <div className="d-flex align-items-center">
                      <label htmlFor="ChooseNumber">Show</label>
                      <div className="CustomSelectNumber">
                        <select
                          className="custom-select"
                          id="ChooseNumber"
                          onChange={(e) => handelPageSizeChange(e)}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                        </select>
                      </div>
                      <label htmlFor="ChooseNumber">Entires</label>
                    </div>
                  </form>

                  <Pagination
                    className="PaginationList ml-auto"
                    total={total}
                    current={currentPage}
                    onChange={(e) => handelPageChange(e)}
                    showTitle={false}
                    pageSize={perPage}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </section>
    </>
  );
};

export default SuperAdminAllProducts;
