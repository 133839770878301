export const CREATE_SUPER_ADMIN_COMPANY = 'CREATE_SUPER_ADMIN_COMPANY';
export const UPDATE_SUPER_ADMIN_COMPANY = 'UPDATE_SUPER_ADMIN_COMPANY';
export const DELETE_SUPER_ADMIN_COMPANY = 'DELETE_SUPER_ADMIN_COMPANY';
export const LOAD_SUPER_ADMIN_COMPANY_LIST = 'LOAD_SUPER_ADMIN_COMPANY_LIST';

export const RENDER_SUPER_ADMIN_COMPANY_LIST =
  'RENDER_SUPER_ADMIN_COMPANY_LIST';
export const CREATE_SUPER_ADMIN_COMPANY_SUCCESS =
  'CREATE_SUPER_ADMIN_COMPANY_SUCCESS';
export const UPDATE_SUPER_ADMIN_COMPANY_SUCCESS =
  'CREATE_SUPER_ADMIN_COMPANY_SUCCESS';
export const DELETE_SUPER_ADMIN_COMPANY_SUCCESS =
  'DELETE_SUPER_ADMIN_COMPANY_SUCCESS';

export const RESET_SUPER_ADMIN_COMPANY_REQUEST_SUCCESS =
  'RESET_SUPER_ADMIN_COMPANY_REQUEST_SUCCESS';
export const RESET_SUPER_ADMIN_COMPANY_PAGE = 'RESET_SUPER_ADMIN_COMPANY_PAGE';

export const LOAD_SUPER_ADMIN_COMPANY_FAILED =
  'LOAD_SUPER_ADMIN_COMPANY_FAILED';
export const CREATE_SUPER_ADMIN_COMPANY_FAILED =
  'CREATE_SUPER_ADMIN_COMPANY_FAILED';
export const UPDATE_SUPER_ADMIN_COMPANY_FAILED =
  'UPDATE_SUPER_ADMIN_COMPANY_FAILED';
export const DELETE_SUPER_ADMIN_COMPANY_FAILED =
  'DELETE_SUPER_ADMIN_COMPANY_FAILED';

export const SUPER_ADMIN_COMPANY_VALIDATION_FAILED =
  'SUPER_ADMIN_COMPANY_VALIDATION_FAILED';

export const UPDATE_SUPER_ADMIN_COMPANY_MEMBERS =
  'UPDATE_SUPER_ADMIN_COMPANY_MEMBERS';
export const UPDATE_SUPER_ADMIN_COMPANY_MEMBERS_SUCCESS =
  'UPDATE_SUPER_ADMIN_COMPANY_MEMBERS_SUCCESS';
export const UPDATE_SUPER_ADMIN_COMPANY_MEMBERS_FAILED =
  'UPDATE_SUPER_ADMIN_COMPANY_MEMBERS_FAILED';

export function getAllCompanies(data) {
  return {
    type: LOAD_SUPER_ADMIN_COMPANY_LIST,
    credentials: data,
  };
}

export function addCompany(data) {
  return {
    type: CREATE_SUPER_ADMIN_COMPANY,
    credentials: data,
  };
}

export function updateCompanyMembers(data) {
  return {
    type: UPDATE_SUPER_ADMIN_COMPANY_MEMBERS,
    credentials: data,
  };
}

export function updateCompany(data) {
  return {
    type: UPDATE_SUPER_ADMIN_COMPANY,
    credentials: data,
  };
}

export function teamMemberDelete(data) {
  return {
    type: DELETE_SUPER_ADMIN_COMPANY,
    credentials: {
      ...data,
      token: localStorage.getItem('access_token'),
    },
  };
}

export function resetRequestSuccess() {
  return {
    type: RESET_SUPER_ADMIN_COMPANY_REQUEST_SUCCESS,
  };
}

export function resetPage() {
  return {
    type: RESET_SUPER_ADMIN_COMPANY_PAGE,
  };
}
