import React, { useState, useEffect } from 'react';
import Pagination from 'rc-pagination';
import Spinner from 'react-bootstrap/Spinner';

import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import CompanyList from './CompanyList';
import CompanyModal from './CompanyModal';
import nodataImage from '../../assets/img/no_data_full.svg';

const Companies = ({
  history,
  companyList,
  currentPage,
  total,
  roles,
  requestSuccess,
  accountType,
  activeProfile,
  companyListLoader,
  modalLoader,
  allCompany,
  resetRequestSuccess,
  addCompanay,
  updateCompany,
  deleteCompany,
  resetAccessRemoved,
  accessRemoved,
  companyNotFound,
  resetCompanyNotFound,
  rolePermissionInfo,
}) => {
  const [show, setShow] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [companyData, setCompanyData] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [userPermission, setUserPermission] = useState([]);

  const [companyStatus, setCompanyStatus] = useState({
    value: '',
    label: 'select',
  });

  const [name, setName] = useState('');
  const [companyId, setCompanyId] = useState('');

  const [editCompany, setEditCompany] = useState(false);
  const [search, setSearch] = useState(null);

  const [confirmBox, setConfirmBox] = useState(false);
  const [confirmBoxTitle, setConfirmBoxTitle] = useState('');
  const handelConfirmBoxClose = () => setConfirmBox(false);

  const [err, setErr] = useState({
    name: '',
    companyStatus: [],
  });
  /* eslint-disable */

  useEffect(() => {
    requestSuccess && setShow(false);
    requestSuccess && setConfirmBox(false);
    requestSuccess &&
      allCompany({
        account_type: accountType,
        company_id: activeProfile.id,
      });
    requestSuccess && resetRequestSuccess();
  }, [requestSuccess]);

  useEffect(() => {
    !show && setEditCompany(false);
    !show && resetRequestSuccess();
    !show && setName('');
    !show && setCompanyId('');
    !show &&
      setCompanyStatus({
        label: 'Select',
        value: '',
      });
    !show &&
      setErr({
        name: '',
        companyStatus: [],
      });
  }, [show]);

  useEffect(() => {
    !confirmBox && setCompanyId('');
  }, [confirmBox]);

  useEffect(() => {
    if (companyList.length > 0) {
      var dataArray = companyList.map((Company) => {
        var newArray = {};
        newArray.name = Company.name;
        newArray.companyStatus = Company.status;
        newArray.id = Company.id;
        newArray.companyStatus = Company.status;
        return newArray;
      });
      setCompanyData(dataArray);
    } else {
      setCompanyData([]);
    }
  }, [companyList]);

  useEffect(() => {
    allCompany({
      account_type: accountType,
      company_id: activeProfile.id,
    });
  }, [perPage]);

  useEffect(() => {
    if (rolePermissionInfo && rolePermissionInfo.length > 0) {
      setUserRole(rolePermissionInfo[0].name);
      let permissions = rolePermissionInfo[0].permissions;
      if (permissions && permissions.length > 0) {
        let role_permission = rolePermissionInfo[0].permissions[0].permission;
        let newArray = [];
        role_permission.map((p) => {
          if (p[0] === 'Company') {
            p[1].map((r) => {
              newArray.push(r);
            });
          }
        });
        setUserPermission(newArray);
      }
    }
  }, []);

  useEffect(() => {
    if (accessRemoved) {
      resetAccessRemoved();
      history.push('/user/dashboard');
    }
  }, [accessRemoved]);

  useEffect(() => {
    if (companyNotFound) {
      resetCompanyNotFound();
      history.push('/auth/create-company-form');
    }
  }, [companyNotFound]);

  /* eslint-enable */

  const handleEditModal = (id) => {
    setEditCompany(true);
    setShow(true);
    setErr({
      name: '',
      companyStatus: [],
    });
    companyList
      .filter((data) => data.id === id)
      .forEach((p) => {
        setName(p.name);
        setCompanyStatus({
          label: p.status,
          value: p.status,
        });
        setCompanyId(id);
      });
  };
  const validate = () => {
    const errors = [];

    // role check
    if (userRole === 'Demo_User') {
      toast.warn('This operation not possible in demo account');
      errors.push('Demo user');
    }

    let nameError = '';
    const companyStatusErr = [];

    if (!name || name.length === 0) {
      nameError = "Company name can't be empty";
      errors.push("Company name can't be empty");
    }

    if (!name.replace(/\s/g, '').length) {
      nameError = "Company name can't be empty";
      errors.push("Company name can't be empty");
    }

    if (
      companyStatus &&
      (companyStatus.value === '' || companyStatus.value === undefined)
    ) {
      companyStatusErr.push('please select a option');
      errors.push('please select a option');
    }

    if (!companyStatus) {
      companyStatusErr.push('please select a option');
      errors.push('please select a option');
    }

    if (errors.length > 0) {
      setErr({
        name: nameError,
        companyStatus: companyStatusErr,
      });
    }
    return errors;
  };
  const submitCompany = () => {
    const errors = validate();
    if (errors.length > 0) {
    } else if (editCompany) {
      updateCompany({
        name,
        id: companyId,
        status: companyStatus.value,
        account_type: accountType,
        company_id: activeProfile.id,
      });
    } else {
      addCompanay({
        name,
        status: companyStatus.value,
        account_type: accountType,
        company_id: activeProfile.id,
      });
    }
  };

  const handelPageChange = (e) => {
    allCompany({
      account_type: accountType,
      company_id: activeProfile.id,
      search: search && search.length > 0 ? search : null,
    });
  };

  const handelSearch = (searchValue) => {
    setSearch(searchValue);
    if (searchValue === '') {
      allCompany({
        per_page: perPage,
        account_type: accountType,
        company_id: activeProfile.id,
      });
    }
  };

  const handelSearchEnter = (e) => {
    if (e.key === 'Enter') {
      allCompany({
        per_page: perPage,
        account_type: accountType,
        company_id: activeProfile.id,
        search: e.target.value,
      });
    }
  };

  const handelDeleteCompany = (id) => {
    setConfirmBox(true);
    setConfirmBoxTitle('Are you sure to remove this company ?');
    setCompanyId(id);
  };

  const handelSubmitCompanyDelete = (e) => {
    // role check
    if (userRole === 'Demo_User') {
      toast.warn('This operation not possible in demo account');
      return;
    }

    deleteCompany({
      id: companyId,
      account_type: accountType,
      company_id: activeProfile.id,
    });
  };

  return (
    <>
      <section className="DataViewSection SectionGap">
        <div className="DataViewHeading">
          <div className="DataViewHeadingLeftContent">
            <div className="SectionTitle">Companies</div>
          </div>
          <div className="DataViewHeadingRightContent">
            <div className="SearchForm">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={(e) => handelSearch(e.target.value)}
                onKeyPress={(e) => handelSearchEnter(e)}
              />
            </div>
            {/* {userPermission
              .filter((p) => p.name === 'add-company' && p.checked)
              .map((b) => {
                return (
                  <button
                    className="ml-3 Button PrimaryButton"
                    data-toggle="modal"
                    data-target="#AddMemberModal"
                    onClick={handleShow}
                  >
                    <span>Add Company</span>
                  </button>
                );
              })} */}
            <button
              className="ml-3 Button PrimaryButton"
              data-toggle="modal"
              data-target="#AddMemberModal"
              onClick={handleShow}
            >
              <span>Add Company</span>
            </button>
          </div>
        </div>
        <div className="TabContent">
          {companyListLoader ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '400px',
              }}
            >
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <div className="DataViewTable">
              <div className="TableWrapper">
                <table className="DataTable">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!companyListLoader &&
                    companyData &&
                    companyData.length > 0 ? (
                      companyData.map((p, index) => (
                        <CompanyList
                          userPermission={userPermission}
                          handleEditModal={handleEditModal}
                          key={index}
                          companyData={p}
                          roles={roles}
                          handelDeleteCompany={handelDeleteCompany}
                          activeProfile={activeProfile}
                          accountType={accountType}
                        />
                      ))
                    ) : (
                      <tr
                        style={{ textAlign: 'center' }}
                        className="Deactivated"
                      >
                        <td colSpan="6">
                          <img
                            src={nodataImage}
                            style={{ height: '600px' }}
                            alt=""
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="TableFooter">
                <div className="TableFooterContent">
                  {total > 10 && (
                    <>
                      <form action="">
                        <div className="d-flex align-items-center">
                          <label htmlFor="ChooseNumber">Show</label>
                          <div className="CustomSelectNumber">
                            <select
                              onChange={(e) => setPerPage(e.target.value)}
                              className="custom-select"
                              id="ChooseNumber"
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </div>
                          <label htmlFor="ChooseNumber">Entires</label>
                        </div>
                      </form>
                      <Pagination
                        className="PaginationList ml-auto"
                        total={total}
                        current={currentPage}
                        onChange={(e) => handelPageChange(e)}
                        showTitle={false}
                        pageSize={perPage}
                        showPrevNextJumpers={false}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          size="xl"
          centered
          backdrop="static"
        >
          <Modal.Header bsPrefix="ModalHeader">
            <Modal.Title bsPrefix="modal-title">
              {editCompany ? 'Update Company' : 'Add new company'}
            </Modal.Title>
            <button
              type="button"
              className="close ml-auto"
              data-dismiss="modal"
              onClick={handleClose}
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="insight wpi-close" />
              </span>
            </button>
          </Modal.Header>
          <Modal.Body bsPrefix="ModalBody">
            <CompanyModal
              name={name}
              setName={setName}
              err={err}
              editCompany={editCompany}
              submitCompany={submitCompany}
              companyStatus={companyStatus}
              setCompanyStatus={setCompanyStatus}
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={confirmBox}
          onHide={handelConfirmBoxClose}
          size="lg"
          centered
          backdrop="static"
        >
          <Modal.Header bsPrefix="ModalHeader">
            <Modal.Title bsPrefix="modal-title">
              Delete Confirmation
            </Modal.Title>
            <button
              type="button"
              className="close ml-auto"
              data-dismiss="modal"
              onClick={handelConfirmBoxClose}
              aria-label="Close"
            >
              <span aria-hidden="true">
                <i className="insight wpi-close" />
              </span>
            </button>
          </Modal.Header>
          <Modal.Body bsPrefix="ModalBody">
            <>
              <h4
                style={{
                  textAlign: 'center',
                  marginBottom: '20px',
                }}
              >
                {confirmBoxTitle}
              </h4>

              <p
                className="ActionButtonGroup"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: 'auto',
                  alignItems: 'center',
                }}
              >
                <button
                  onClick={(e) => handelSubmitCompanyDelete()}
                  className="Button SuccessButton"
                  title="Yes"
                >
                  <span>
                    {modalLoader ? (
                      <Spinner animation="border" variant="light" />
                    ) : (
                      'Yes'
                    )}
                  </span>
                </button>
                <button
                  className="Button DangerButton"
                  title="No"
                  disabled={modalLoader}
                  onClick={(e) => {
                    !modalLoader && setConfirmBox(false);
                    !modalLoader && setConfirmBoxTitle('');
                    !modalLoader && setCompanyId('');
                  }}
                >
                  No
                </button>
              </p>
            </>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
};

export default Companies;
