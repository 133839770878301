import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

const Profile = (props) =>
  props.setingsLoader ? (
    <section className="FormViewSection">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '400px',
        }}
      >
        <Spinner animation="border" variant="dark" />
      </div>
    </section>
  ) : (
    <>
      {!props.show ? (
        <section className="FormViewSection">
          <div className="d-flex mb-5">
            <button
              onClick={props.handleShow}
              className="Button PrimaryButton ml-auto"
            >
              <div className="Icon">
                <i className="insight wpi-edit" />
              </div>
              <span>Update Information</span>
            </button>
          </div>
          <div className="FormViewDataWrapper">
            <div className="FormViewDataGroupLeft">
              <div className="FormViewGroup">
                <p className="FormViewTitle">Full Name :</p>
                <p className="FormViewData">{props.name}</p>
              </div>
              <div className="FormViewGroup">
                <p className="FormViewTitle">User Name :</p>
                <p className="FormViewData">{props.userName}</p>
              </div>
              <div className="FormViewGroup">
                <p className="FormViewTitle">Email Address :</p>
                <p className="FormViewData">{props.email}</p>
              </div>
            </div>
            <div className="UpdateOption">
              <div className="ImageSelector">
                <div className="ImgBox">
                  <img
                    src={props.profile_image}
                    alt="profile"
                    style={{ height: '100%' }}
                    className="w-100"
                  />
                  {props.profile_image_delete && (
                    <button
                      className="RemoveButton"
                      onClick={(e) => props.handelImageDelete(e)}
                    >
                      <i className="insight wpi-close" />
                    </button>
                  )}
                </div>
                {/* <label className="FileBrowseButton mt-2">
                <span className="text-break">Change Profile Picture</span>
                <input
                  type="file"
                  onChange={(e) =>
                    props.setProfile_image(
                      URL.createObjectURL(e.target.files[0])
                    )
                  }
                  accept=".png, .jpg, .jpeg"
                />
              </label> */}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="FormViewSection">
          <div className="FormViewDataWrapper" style={{ height: 'unset' }}>
            <div className="FormViewDataGroupLeft">
              <div className="FormViewGroup">
                <p className="FormViewTitle">Full Name :</p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Name..."
                  value={props.name}
                  onChange={(e) => props.setName(e.target.value)}
                />
              </div>
              {props.err && props.err.name && props.err.name.length > 0 && (
                <div className="FormViewGroup">
                  <p className="FormViewTitle" />
                  <p style={{ color: 'red' }} key={props.name}>
                    {props.err.name}
                  </p>
                </div>
              )}
              <div className="FormViewGroup">
                <p className="FormViewTitle">User Name :</p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="UserName..."
                  value={props.userName}
                  onChange={(e) => props.setUserName(e.target.value)}
                />
              </div>
              {props.err &&
                props.err.username &&
                props.err.username.length > 0 && (
                  <div className="FormViewGroup">
                    <p className="FormViewTitle" />
                    <p style={{ color: 'red' }} key={props.userName}>
                      {props.err.username}
                    </p>
                  </div>
                )}
              <div className="FormViewGroup">
                <p className="FormViewTitle">Email Address :</p>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Email Address..."
                  value={props.email}
                  onChange={(e) => props.setEmail(e.target.value)}
                />
              </div>
              {props.err && props.err.email && props.err.email.length > 0 && (
                <div className="FormViewGroup">
                  <p className="FormViewTitle" />
                  <p style={{ color: 'red' }} key={props.email}>
                    {props.err.email}
                  </p>
                </div>
              )}
            </div>
            <div className="UpdateOption">
              <div className="ImageSelector">
                <div className="ImgBox">
                  <img
                    src={props.profile_image}
                    alt="profile"
                    style={{ height: '100%' }}
                    className="w-100"
                  />
                </div>
                <label className="FileBrowseButton mt-2">
                  <span className="text-break">Change Profile Picture</span>
                  <input
                    type="file"
                    onChange={(e) => props.handelImageUpdate(e.target.files[0])}
                    accept=".png, .jpg, .jpeg"
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="d-flex mt-4">
            <button
              onClick={props.handleClose}
              className="Button SemiButtonDanger ml-auto"
            >
              <span>Cancel</span>
            </button>
            <button
              onClick={(e) => props.submitProfileUpdate(e)}
              className="Button PrimaryButton ml-3"
            >
              <span>Submit</span>
            </button>
          </div>
        </section>
      )}
    </>
  );

export default Profile;
