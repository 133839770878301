import React from 'react';
import Pagination from 'rc-pagination';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import nodataImage from '../../../assets/img/no_data_full.svg';

import TogglerIconSvg from '../../../assets/img/toggler-square.svg';

const sortColumn = [
  'updatedTime',
  'pluginVersion',
  'phpVersion',
  'wpInsVersion',
  'siteVersion',
  'alexaRank',
  'since',
];

const SitesListTableBody = ({
  siteData,
  handelCheckBox,
  siteListShowColumn,
}) => (
  <>
    <tr className={!siteData.deactivated ? '' : 'Deactivated'}>
      <td className="selector">
        <label className="Checkbox">
          <input
            type="checkbox"
            key={siteData.id}
            name={siteData.id}
            id={siteData.baseNameSlug}
            checked={siteData.isChecked}
            onChange={(e) => handelCheckBox(e)}
          />
          <span />
        </label>
      </td>
      {siteListShowColumn &&
        siteListShowColumn
          .filter((item) => item.isChecked)
          .map((column, index) =>
            column.name === 'url' ? (
              <td key={index} className="DetailsButtonWrapper">
                <p>
                  <a
                    href={siteData[column.name]}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {siteData[column.name]}
                  </a>
                </p>
                <Link
                  to={`/user/sites-details?product=${
                    siteData.baseNameSlug
                  }&url=${siteData[column.name]}`}
                >
                  <button className="LabelButton PrimaryButton">Details</button>
                </Link>
              </td>
            ) : (
              <td key={index}>
                <p>{siteData[column.name]}</p>
              </td>
            )
          )}
      <td />
    </tr>
  </>
);

const SitesReports = (props) => {
  const sitesData = props.data;
  return props.reportLoader ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '400px',
      }}
    >
      <Spinner animation="border" variant="dark" />
    </div>
  ) : (
    <div className="DataViewTable">
      {sitesData && sitesData.length === 0 ? (
        <div>
          <img height="600px" width="100%" src={nodataImage} alt="" />
        </div>
      ) : (
        <>
          <form action="" className="TableWrapper">
            <table className="DataTable TableWithDetails">
              <thead>
                <tr>
                  <th className="selector">
                    <label className="Checkbox">
                      <input
                        type="checkbox"
                        name="checkAll"
                        checked={props.selectAll}
                        onChange={(e) => props.handelCheckBox(e)}
                      />
                      <span />
                    </label>
                  </th>
                  {props.siteListShowColumn &&
                    props.siteListShowColumn
                      .filter((item) => item.isChecked)
                      .map((column, index) => (
                        <th key={index}>
                          <span className="d-flex align-items-center">
                            {column.lable}
                            {sortColumn.includes(column.name) && (
                              <span className="sort">
                                <span
                                  onClick={() =>
                                    props.changeSiteSortBy(column.name, 'ASC')
                                  }
                                  className={`sort-up ${
                                    props.siteSortBy &&
                                    props.siteSortBy.original === column.name &&
                                    props.siteSortBy.type === 'ASC'
                                      ? 'active'
                                      : ''
                                  }`}
                                >
                                  <i className="insight wpi-sort-up" />
                                </span>
                                <span
                                  onClick={() =>
                                    props.changeSiteSortBy(column.name, 'DESC')
                                  }
                                  className={`sort-down ${
                                    props.siteSortBy &&
                                    props.siteSortBy.original === column.name &&
                                    props.siteSortBy.type === 'DESC'
                                      ? 'active'
                                      : ''
                                  }`}
                                >
                                  <i className="insight wpi-sort-down" />
                                </span>
                              </span>
                            )}
                          </span>
                        </th>
                      ))}

                  <th ref={props.toggolerRef} className="TableToggler">
                    <div
                      className={`dropdown dropdown-menu-right ${
                        props.tableColumnToggoler ? 'show' : ''
                      }`}
                    >
                      <button
                        className="TogglerIcon"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        onClick={props.handelTableColumnToggoler}
                      >
                        <img src={TogglerIconSvg} alt="" />
                      </button>
                      <ul
                        className={`dropdown-menu ${
                          props.tableColumnToggoler ? 'show' : ''
                        }`}
                      >
                        {props.siteListShowColumn &&
                          props.siteListShowColumn.map((item, index) => (
                            <li key={index} className="DropdownItem">
                              <label className="Checkbox">
                                <input
                                  type="checkbox"
                                  id={index}
                                  checked={item.isChecked ? 'checked' : ''}
                                  onChange={() =>
                                    item.isChecked
                                      ? props.handleColumnShowCheckbox(
                                          item.name,
                                          false
                                        )
                                      : props.handleColumnShowCheckbox(
                                          item.name,
                                          true
                                        )
                                  }
                                />
                                <span>{item.lable}</span>
                              </label>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sitesData.map((listData, i) => (
                  <SitesListTableBody
                    key={i}
                    siteData={listData}
                    handelCheckBox={props.handelCheckBox}
                    siteListShowColumn={props.siteListShowColumn}
                  />
                ))}
                {sitesData && sitesData.length === 0 && (
                  <>
                    <tr style={{ textAlign: 'center' }} className="Deactivated">
                      <td colSpan="6">No data found</td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </form>

          <div className="TableFooter">
            <div className="TableFooterContent">
              {props.total > 10 && (
                <>
                  <form action="">
                    <div className="d-flex align-items-center">
                      <label htmlFor="ChooseNumber">Show</label>
                      <div className="CustomSelectNumber">
                        <select
                          onChange={(e) => props.changePageSize(e)}
                          className="custom-select"
                          id="ChooseNumber"
                          value={props.perPage}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="40">40</option>
                        </select>
                      </div>
                      <label htmlFor="ChooseNumber">Entires</label>
                    </div>
                  </form>
                  <Pagination
                    className="PaginationList ml-auto"
                    total={props.total}
                    current={props.currentPage}
                    onChange={(e) => props.handelPageChange(e)}
                    showTitle={false}
                    pageSize={props.perPage}
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SitesReports;
