import React from 'react';
import Select from 'react-select';

const options = [
  { value: 'Active', label: 'Active' },
  { value: 'Deactivated', label: 'Inactive' },
];

const BasicInfoModal = ({
  handleBasicInfo,
  err,
  hasErr,
  productName,
  productBaseName,
  productVersion,
  productHomeUrl,
  productDemoUrl,
  productStatus,
  productDescription,
  setProductName,
  setProductBaseName,
  setProductVersion,
  setProductHomeUrl,
  setProductDemoUrl,
  setProductStatus,
  setProductDescription,
  setmodalStep,
  basicTabClick,
  productUpdate,
  metaTabClick,
  marketingTabClick,
}) => (
  <>
    <div className="ModalControlGroup">
      <div className="ModalTabControllers">
        <button
          onClick={() => (basicTabClick ? setmodalStep(1) : 'return false;')}
          className="ModalTabControl Active"
        >
          Basic Info
        </button>
        <button
          // onClick={() => (metaTabClick ? setmodalStep(2) : 'return false;')}
          onClick={() => handleBasicInfo()}
          className="ModalTabControl"
        >
          Meta Info
        </button>
        <button
          onClick={() =>
            marketingTabClick ? setmodalStep(3) : 'return false;'
          }
          className="ModalTabControl"
        >
          Marketing Info
        </button>
      </div>
      <div className="progress">
        <div className="progress-bar" style={{ width: '33%' }} />
      </div>
    </div>
    <div className="ModalTabContent">
      <div className="TabContentRow">
        <label>Product Name*</label>
        <input
          type="text"
          className="form-control"
          placeholder="Your Product Name..."
          value={productName}
          id="productName"
          onChange={(e) => setProductName(e.target.value)}
        />
      </div>
      {err && err.productName && err.productName.length > 0 && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={productName}>
            {err.productName}
          </p>
        </div>
      )}
      <div className="TabContentRow">
        <label>Base Name*</label>
        <input
          type="text"
          className="form-control"
          placeholder="Product Base Name..."
          value={productBaseName}
          id="productBaseName"
          onChange={(e) => setProductBaseName(e.target.value)}
          readOnly={productUpdate}
        />
      </div>
      {err && err.productBaseName && err.productBaseName.length > 0 && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={productBaseName}>
            {err.productBaseName}
          </p>
        </div>
      )}
      <div className="TabContentRow">
        <label>Version*</label>
        <input
          type="text"
          className="form-control"
          placeholder="1.2.3.4"
          value={productVersion}
          id="productVersion"
          onChange={(e) => setProductVersion(e.target.value)}
        />
      </div>
      {err && err.productVersion && err.productVersion.length > 0 && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={productVersion}>
            {err.productVersion}
          </p>
        </div>
      )}
      <div className="TabContentRow">
        <label>Home URL*</label>
        <input
          type="text"
          className="form-control"
          placeholder="https://yourdomain.com"
          value={productHomeUrl}
          id="productHomeUrl"
          onChange={(e) => setProductHomeUrl(e.target.value)}
        />
      </div>
      {err && err.productHomeUrl && err.productHomeUrl.length > 0 && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={productHomeUrl}>
            {err.productHomeUrl}
          </p>
        </div>
      )}
      <div className="TabContentRow">
        <label>Demo URL</label>
        <input
          type="text"
          className="form-control"
          placeholder="https://yourdemodomain.com"
          value={productDemoUrl}
          id="productDemoUrl"
          onChange={(e) => setProductDemoUrl(e.target.value)}
        />
      </div>

      <div className="TabContentRow">
        <label>Status*</label>
        <div style={{ width: '100%' }}>
          <Select
            className="SelectOption basic-single"
            classNamePrefix="react-select"
            isSearchable={false}
            id="productStatus"
            onChange={(e) => setProductStatus(e)}
            options={options}
            value={options.find(
              (option) => option.value === productStatus.value
            )}
          />
        </div>
      </div>
      {err && err.productStatus && err.productStatus.length > 0 && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={productStatus}>
            {err.productStatus}
          </p>
        </div>
      )}
      <div className="TabContentRow">
        <label>Description</label>
        <textarea
          rows="5"
          className="form-control"
          placeholder="Enter Details Here..."
          value={productDescription}
          id="productDescription"
          onChange={(e) => setProductDescription(e.target.value)}
        />
      </div>
      {err && err.productDescription && err.productDescription.length > 0 && (
        <div className="TabContentRow">
          <label />
          <p style={{ color: 'red' }} key={productDescription}>
            {err.productDescription}
          </p>
        </div>
      )}
      <div className="d-flex mt-5">
        <button
          onClick={() => handleBasicInfo()}
          className="Button PrimaryButton ml-auto"
        >
          <span>Next</span>
        </button>
      </div>
    </div>
  </>
);

export default BasicInfoModal;
